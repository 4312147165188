import moment from 'moment';
import i18next from '../i18n';
import { ANALYSIS_FORMAT } from '../constants';

/**
 * Removes any special characters and accents from a
 * given string.
 *
 * @param {*} str
 * @returns {string}
 */
export const normalize = (str) =>
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

/**
 * Returns a string with the user of made the last update to
 * the pathology and the date when that update occurred.
 *
 * @param {*} pathology
 * @returns
 */
export const getPathologyLastUpdatedAndUser = (t, pathology) =>
  pathology &&
  `${i18next.t('pathology-common-last-update')}: ${moment(
    pathology.updatedAt,
  ).format('DD/MM/YYYY')} ${i18next.t('pathology-common-updated-by')}: ${
    pathology.lastUpdateBy
  }`;

/**
 * Returns the id for the analysis format of
 * a given analysis parameter object.
 *
 * @param {*} analysis
 * @returns
 */
export const getAnalysisFormatFromAnalysis = (analysis) =>
  analysis?.refValue ? ANALYSIS_FORMAT.single.id : ANALYSIS_FORMAT.interval.id;
