import {
  POST_SUPPORT_START,
  POST_SUPPORT_SUCCESS,
  POST_SUPPORT_ERROR,
} from '../actions/types';

const initialState = {
  isSubmitting: false,
  success: null,
  error: null,
};

function supportReducer(state = initialState, action) {
  switch (action.type) {
    case POST_SUPPORT_START:
      return {
        ...state,
        isSubmitting: true,
        error: null,
        success: null,
      };
    case POST_SUPPORT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        success: action.success,
      };
    case POST_SUPPORT_ERROR:
      return {
        ...state,
        isSubmitting: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default supportReducer;
