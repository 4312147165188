import React from 'react';
import PropTypes from 'prop-types';
import classes from './CustomTooltip.module.css';

const CustomTooltip = ({ children, text }) => (
  <div className={classes.Tooltip}>
    {children}
    <span className={classes.TooltipText}>{text}</span>
  </div>
);

CustomTooltip.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CustomTooltip;
