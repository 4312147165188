import i18next from '../../i18n';
import api, { endpoints } from '../../services/api';
import {
  POST_SUPPORT_START,
  POST_SUPPORT_SUCCESS,
  POST_SUPPORT_ERROR,
} from './types';
import { getAuthToken } from '../../services/auth/getToken';

export const postSupport = (data) => async (dispatch) => {
  const { issueType, subject, message } = data;
  dispatch({ type: POST_SUPPORT_START });

  try {
    const tokens = await getAuthToken();

    const config = {
      headers: {
        LABAI_AUTH: tokens.bearerToken,
        Azure_Access_Token: tokens.accessToken,
      },
    };

    const response = await api.post(
      endpoints.SUPPORT_POST,
      {
        issueType,
        subject,
        message,
      },
      config,
    );

    dispatch({
      type: POST_SUPPORT_SUCCESS,
      message: response?.data?.message,
      success: i18next.t('support-success-message'),
    });
  } catch (error) {
    dispatch({
      type: POST_SUPPORT_ERROR,
      error: i18next.t('support-error-message'),
      errorObject: error,
    });
  }
};
