import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from './TitleHeader.module.css';

const TitleHeader = ({ title }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={classes.TitleHeader}>
      <div className={classes['TitleHeader-container']}>
        <button
          type="button"
          onClick={() => navigate(-1, { replace: true })}
          className={classes.Back}
        >
          &#60; {t('button-nav-back')}
        </button>
        <span className={classes.Title}>{title}</span>
      </div>
    </div>
  );
};

TitleHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default TitleHeader;
