import React from 'react';
import classes from './ConfirmationModal.module.css';
import Modal from '../../../../../../molecules/Modal/Modal';
import Button from '../../../../../../atoms/Button/Button';

const ConfirmationModal = ({
  toggle,
  onClose,
  onConfirm,
  header,
  message,
  confirmText,
  cancelText,
}) => (
  <Modal title={header} toggle={toggle} onClose={onClose}>
    <span>{message}</span>
    <div className={classes.ModalFooter}>
      <Button type="button" blank onClick={onClose}>
        {cancelText}
      </Button>
      <Button type="button" onClick={onConfirm}>
        {confirmText}
      </Button>
    </div>
  </Modal>
);

export default ConfirmationModal;
