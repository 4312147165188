import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/atoms/Button/Button';
import NotFound from '../../assets/images/404.svg';
import classes from './PageNotFoundScreen.module.css';

const PageNotFoundScreen = ({ routes }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={classes.PageNotFound}>
      <img
        className={classes.NotFoundImage}
        src={NotFound}
        alt="not-found-404"
      />
      <p>{`${t('page-not-found-text')}.`}</p>
      <Button type="button" onClick={() => navigate(routes.DASHBOARD.path)}>
        {t('page-not-found-button-back')}
      </Button>
    </div>
  );
};

export default PageNotFoundScreen;
