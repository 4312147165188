import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AdornedInput from '../../molecules/AdornedInput/AdornedInput';
import AdornedDatePicker from '../../molecules/AdornedDatePicker/AdornedDatePicker';
import Tabs from '../../atoms/Tabs/Tabs';
import TabItem from '../../atoms/Tabs/TabItem/TabItem';
import PatientHistory from './PatientHistory/PatientHistory';
import PatientClinicalEpisodes from './PatientClinicalEpisodes/PatientClinicalEpisodes';
import PatientPersonalData from './PatientPersonalData/PatientPersonalData';
import AdornedUserIcon from '../../../assets/images/adorned-user-icon.svg';
import AdornedDateIcon from '../../../assets/images/adorned-date-icon.svg';
import PatientEpisodesIcon from '../../../assets/images/patient-analysis-episodes.svg';
import PatientEpisodesIconInactive from '../../../assets/images/patient-analysis-episodes-inactive.svg';
import PatientDataIcon from '../../../assets/images/patient-analysis-data.svg';
import PatientDataIconInactive from '../../../assets/images/patient-analysis-data-inactive.svg';
import HistoryIcon from '../../../assets/images/patient-analysis-history.svg';
import HistoryIconInactive from '../../../assets/images/patient-analysis-history-inactive.svg';
import classes from './PatientAnalysis.module.css';

const PatientAnalysis = ({ patient, modules, from, to, setFrom, setTo }) => {
  const { t } = useTranslation();

  const [episodes, setEpisodes] = useState([]);

  /**
   * Set the episodes that will be displayed
   * on all of the tabs of this page.
   */

  useEffect(() => {
    setEpisodes(patient?.patientClinicalEpisodes || []);
  }, [patient]);

  /**
   * This section of the markdown is stored in
   * a constant, because it needs to be put in the part
   * of the design where the subtitle of the Tabs component
   * was supposed to be.
   */
  const dateInputsElement = (
    <div className={classes.DateInputs}>
      <div className={classes.DateInputGroup}>
        <span>{t('patient-analysis-from')}:</span>
        <AdornedDatePicker
          leftAdornment={AdornedDateIcon}
          placeholder={t('patient-analysis-from')}
          value={from}
          format="dd/MM/yyyy"
          onChange={(val) => setFrom(val)}
        />
      </div>
      <div className={classes.DateInputGroup}>
        <span>{t('patient-analysis-to')}:</span>
        <AdornedDatePicker
          leftAdornment={AdornedDateIcon}
          placeholder={t('patient-analysis-to')}
          value={to}
          format="dd/MM/yyyy"
          onChange={(val) => setTo(val)}
        />
      </div>
    </div>
  );

  return (
    <div className={classes.PatientAnalysis}>
      {/* Disabled input to display the user's id */}
      <AdornedInput
        leftAdornment={AdornedUserIcon}
        disabled
        value={patient?.patientInfo?.patientCode || ''}
      />

      <Tabs subtitle={dateInputsElement} fill>
        <TabItem
          title={t('patient-analysis-tab-history')}
          icon={HistoryIcon}
          inactiveIcon={HistoryIconInactive}
        >
          <PatientHistory episodes={episodes} modules={modules} />
        </TabItem>
        <TabItem
          title={t('patient-analysis-tab-episodes')}
          icon={PatientEpisodesIcon}
          inactiveIcon={PatientEpisodesIconInactive}
        >
          <PatientClinicalEpisodes episodes={episodes} />
        </TabItem>
        <TabItem
          title={t('patient-analysis-tab-personal-data')}
          icon={PatientDataIcon}
          inactiveIcon={PatientDataIconInactive}
        >
          <PatientPersonalData patient={patient?.patientInfo} />
        </TabItem>
      </Tabs>
    </div>
  );
};

export default PatientAnalysis;
