import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { useDispatch } from 'react-redux';
import Dropdown from '../../../../atoms/Dropdown/Dropdown';
import Input from '../../../../atoms/Input/Input';
import Readonly from '../../../../atoms/Readonly/Readonly';
import ParameterSelectionList from './ParameterSelectionList/ParameterSelectionList';
import { normalize } from '../../../../../helpers';
import classes from './ModuleSelector.module.css';
import { fetchPathologyParameters } from '../../../../../store/actions/pathologies';

const ModuleSelector = (props) => {
  const {
    modules,
    parametersSelected,
    setParametersSelected,
    selectedReferences,
    setSelectedReferences,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [moduleOptions, setModuleOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [isPendingParameterList, setIsPendingParameterList] = useState(false);
  const [parameters, setParameters] = useState([]);
  const [parametersToDisplay, setParametersToDisplay] = useState([]);
  const [search, setSearch] = useState('');

  /**
   * On start, loops all the modules and sets the module options
   * for every module.
   */
  useEffect(() => {
    if (modules && modules.length > 0) {
      const options = modules.map((module) => ({
        label: module.name,
        value: module.pathologyId.toString(),
      }));

      setModuleOptions(options);
    }
  }, [modules]);

  /**
   * Sets the module selected as the one passed as argument
   * and also sets its parameters as the ones to display on
   * the parameter selection list below.
   */
  const setModule = (moduleId) => {
    const selectedModule = modules.find(
      (module) => module.pathologyId.toString() === moduleId,
    );

    setSelected(selectedModule);
  };

  /**
   * Sets, by default, the first option of the modules dropdown
   * as selected.
   */
  useEffect(() => {
    if (moduleOptions && moduleOptions.length > 0) {
      setModule(moduleOptions[0].value);
    }
  }, [moduleOptions]);

  /**
   * Performs an API call to fetch the parameters of a
   * pathology whenever a pathology is selected in the
   * modules dropdown.
   */
  useEffect(() => {
    if (selected) {
      setIsPendingParameterList(true);

      dispatch(fetchPathologyParameters(selected.activeVersionId))
        .then((response) => {
          setParameters(response.data);
          setParametersToDisplay(response.data);
          setParametersSelected(
            response.data.map((parameter) => ({
              id: parameter.id,
              color: `#${parameter.hex}`,
              graph: parameter.graphType,
              codes: parameter.parameterCodes,
            })),
          );
        })
        .catch((error) => {
          toast.error(
            `${t('patient-analysis-fetch-parameters-of-error')} ${
              selected.name
            }: ${error.toString()}`,
          );
        })
        .finally(() => {
          setIsPendingParameterList(false);
        });
    }
  }, [selected]);

  /**
   * Reset the search input and set the selected module.
   * Used when the dropdown option is selected.
   */
  const onSelectModule = (val) => {
    setSearch('');
    setSelectedReferences([]);
    setModule(val);
  };

  /**
   * Filter the parameters to display when searching
   * for a module's parameter.
   */
  useEffect(() => {
    if (!search || search === '') {
      setParametersToDisplay(parameters);
      return;
    }

    const filtered = parameters.filter((param) =>
      normalize(param.parameterName.toLowerCase()).includes(
        search.toLowerCase(),
      ),
    );

    setParametersToDisplay(filtered);
  }, [search, parameters]);

  return (
    <div className={classes.ModuleSelector}>
      {/* Search input */}
      <Input
        type="search"
        value={search}
        placeholder={t('patient-analysis-modules-search')}
        onChange={(e) => setSearch(e.target.value)}
      />

      <p className={classes.Title}>{t('patient-analysis-modules-title')}</p>

      {/* Module selector input (dropdown) */}
      <Dropdown
        value={selected?.pathologyId.toString()}
        options={moduleOptions}
        onChange={(sel) => onSelectModule(sel.value)}
        border
        whiteText={false}
      />

      {/* Parameter Selection List */}
      {isPendingParameterList ? (
        <div className={classes.ParameterListPendingContainer}>
          <ClipLoader
            cssOverride={{ textAlign: 'center' }}
            size={28}
            color="#8A2432"
            loading={isPendingParameterList}
          />
        </div>
      ) : (
        <div className={classes.ParameterListContainer}>
          {parametersToDisplay && parametersToDisplay.length < 1 && (
            <Readonly faded text={t('empty-data')} />
          )}

          {parametersToDisplay && parametersToDisplay.length > 0 && (
            <ParameterSelectionList
              parameters={parametersToDisplay}
              parametersSelected={parametersSelected}
              setParametersSelected={setParametersSelected}
              selectedReferences={selectedReferences}
              setSelectedReferences={setSelectedReferences}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ModuleSelector;
