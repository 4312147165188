import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import NavBar from '../../components/molecules/NavBar/NavBar';
import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import Container from '../../components/atoms/Container/Container';
import Input from '../../components/atoms/Input/Input';
import classes from './SupportScreen.module.css';
import InputControl from '../../components/molecules/InputControl/InputControl';
import Select from '../../components/atoms/Select/Select';
import { ISSUE_TYPES } from '../../constants/index';
import Textarea from '../../components/atoms/Textarea/Textarea';
import Button from '../../components/atoms/Button/Button';
import { postSupport } from '../../store/actions/support';
import Accordion from '../../components/atoms/Accordion/Accordion';
import AccordionItem from '../../components/atoms/Accordion/AccordionItem/AccordionItem';

const SupportScreen = ({ routes }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [active, setActive] = useState(false);

  const success = useSelector((state) => state?.support?.success);
  const error = useSelector((state) => state?.support?.error);

  useEffect(() => {
    if (success) {
      toast.success(success);
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const selectedIssueType = useMemo(
    () =>
      formData?.issueType
        ? {
            value: formData?.issueType,
            label: ISSUE_TYPES?.find(
              (issue) => issue?.value === formData?.issueType,
            )?.label,
          }
        : null,
    [formData?.issueType],
  );

  const faqList = [
    {
      question: t('faqs.question-1'),
      answer: t('faqs.answer-1'),
    },
    {
      question: t('faqs.question-1'),
      answer: t('faqs.answer-1'),
    },
    {
      question: t('faqs.question-1'),
      answer: t('faqs.answer-1'),
    },
  ];

  const isSubmitEnabled = useMemo(
    () => !!(formData?.subject && formData?.issueType && formData?.message),
    [formData],
  );

  const handleSubmit = useCallback(
    () =>
      dispatch(postSupport(formData)).then(() => {
        setFormData({});
      }),
    [postSupport, formData],
  );

  return (
    <>
      <NavBar routes={routes} />
      <TitleHeader routes={routes} title={t('support-title')} />
      <Container fillWithTitleHeader>
        <div className={classes.SupportWrapper}>
          <div className={classes.FormWrapper}>
            <span className={classes.FormSubtitle}>{t('lorem')}</span>
            <InputControl
              label={t('support-subject')}
              extraStyles={classes.FormField}
            >
              <Input
                type="text"
                placeholder={t('support-subject')}
                value={formData?.subject || ''}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    subject: e?.target?.value,
                  })
                }
              />
            </InputControl>
            <InputControl
              label={t('support-issue-type')}
              extraStyles={classes.FormField}
            >
              <Select
                key={`key_${selectedIssueType}`}
                placeholder={t('support-issue-type')}
                options={ISSUE_TYPES}
                value={selectedIssueType}
                onChange={(val) =>
                  setFormData({ ...formData, issueType: val.value })
                }
              />
            </InputControl>
            <InputControl
              label={t('support-message')}
              extraStyles={classes.FormTextArea}
            >
              <Textarea
                placeholder={t('support-message')}
                value={formData?.message || ''}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    message: e.target.value,
                  })
                }
              />
            </InputControl>
            <Button
              type="button"
              disabled={!isSubmitEnabled}
              onClick={() => handleSubmit()}
            >
              {t('support-submit')}
            </Button>
          </div>
          {faqList?.length > 0 && (
            <div className={classes.FaqListWrapper}>
              <span className={classes.FaqListTitle}>{t('faqs.title')}</span>
              <Accordion active={active} setActive={setActive}>
                {faqList?.map((faq, index) => (
                  <AccordionItem title={faq?.question} key={index}>
                    <div className={classes.FaqListAnswer}>
                      <span>{faq?.answer}</span>
                    </div>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          )}
        </div>
        <ToastContainer pauseOnHover />
      </Container>
    </>
  );
};

export default SupportScreen;
