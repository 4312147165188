import React from 'react';
import PropTypes from 'prop-types';

import classes from './Button.module.css';

const Button = (props) => {
  const {
    children,
    blank = false,
    disabled = false,
    stretched = false,
    onClick,
    ...rest
  } = props;

  const buttonClassName = `
    ${classes.Button}
    ${stretched ? classes.Button__stretched : ''}
    ${blank ? classes.Button__blank : ''}
    ${disabled ? classes.Button__disabled : ''}
  `.trim();

  return (
    <button
      type="button"
      disabled={disabled}
      className={buttonClassName}
      {...{ onClick }}
      {...rest}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  blank: PropTypes.bool,
  stretched: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  blank: false,
  stretched: false,
  disabled: false,
  onClick: null,
};

export default Button;
