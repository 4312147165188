import axiosInstance from './axios';
import * as endpoints from './endpoints';

/**
 * This export contains all the endpoints in the "endpoints"
 * property, that can be used on importing this service file.
 */
export { endpoints };

/**
 * Exports the configured axios instance as a default export.
 */
export default axiosInstance;
