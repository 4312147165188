/**
 * Based on https://react-select.com/home
 */

import React from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';

const styles = {
  container: (provided) => ({
    ...provided,
    border: '1px solid var(--color-gray-dark)',
    height: '36px',
    minWidth: '160px',
  }),
  control: (provided, state) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    borderRadius: '0',
    height: '32px',
    minHeight: '32px',
    fontSize: '14px',
    background: !state.isDisabled
      ? 'var(--color-white)'
      : 'var(--color-gray-lighter)',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: !state.isDisabled ? 'var(--color-gray-dark)' : 'var(--color-gray)',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '100%',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '0px',
    border: '1px solid var(--color-gray-dark)',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    fontWeight: state.isSelected ? '700' : '400',
    background: state.isSelected
      ? 'var(--color-primary)'
      : 'var(--color-white)',
    '&:hover': {
      background: 'var(--color-primary)',
      color: 'var(--color-white)',
    },
    width: 'inherit',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--color-gray)',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '100%',
  }),
};

const Select = (props) => {
  const {
    options,
    id,
    name,
    placeholder,
    value,
    disabled = false,
    multiple = false,
    onChange,
    ...args
  } = props;

  return (
    <ReactSelect
      {...args}
      options={options}
      id={id}
      name={id}
      placeholder={placeholder}
      styles={styles}
      isMulti={multiple}
      isDisabled={disabled}
      onChange={onChange}
      defaultValue={value}
    />
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ).isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
      }),
    ),
  ]),
};

Select.defaultProps = {
  id: null,
  name: null,
  placeholder: null,
  multiple: false,
  disabled: false,
  onChange: null,
  value: null,
};

export default Select;
