import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AnalysisSection from '../AnalysisSection/AnalysisSection';
import InputControl from '../../../../../../molecules/InputControl/InputControl';
import Input from '../../../../../../atoms/Input/Input';
import Checkbox from '../../../../../../atoms/Checkbox/Checkbox';
import Readonly from '../../../../../../atoms/Readonly/Readonly';
import classes from './AnalysisVariations.module.css';

const AnalysisVariations = (props) => {
  const { readonly, analysisInfo, setAnalysisInfo } = props;

  const { t } = useTranslation();

  const [isChecked, setIsChecked] = useState(
    analysisInfo?.checkParameterHistory,
  );

  const onCheckParameterHistoryChanged = (value) => {
    setIsChecked(value);
    setAnalysisInfo({
      ...analysisInfo,
      checkParameterHistory: value,
      timeDelta: null,
      refValueDelta: null,
    });
  };

  return (
    <AnalysisSection title={t('pathology-detail-analysis-variations-title')}>
      {/* Check Parameter History? */}
      <InputControl label={t('pathology-detail-analysis-variations-check')} extraStyles={classes.checkbox}>
        {!readonly ? (
          <Checkbox
            checked={analysisInfo?.checkParameterHistory}
            onChange={(e) => onCheckParameterHistoryChanged(e.target.checked)}
          />
        ) : (
          <Readonly
            text={analysisInfo?.checkParameterHistory ? t('yes') : t('no')}
          />
        )}
      </InputControl>

      {/* Variation Time Delta */}
      <InputControl label={t('pathology-detail-analysis-variations-time')}>
        {!readonly ? (
          <Input
            type="number"
            disabled={!isChecked}
            placeholder={t('pathology-detail-analysis-variations-time')}
            value={analysisInfo?.timeDelta || ''}
            onChange={(e) =>
              setAnalysisInfo({
                ...analysisInfo,
                timeDelta: isChecked && +e.target.value,
              })
            }
          />
        ) : (
          <Readonly text={analysisInfo?.timeDelta || '-'} />
        )}
      </InputControl>

      {/* Variation Delta Value */}
      <InputControl label={t('pathology-detail-analysis-variations-value')}>
        {!readonly ? (
          <Input
            type="number"
            step={0.001}
            disabled={!isChecked}
            placeholder={t('pathology-detail-analysis-variations-value')}
            value={analysisInfo?.refValueDelta || ''}
            onChange={(e) =>
              setAnalysisInfo({
                ...analysisInfo,
                refValueDelta: isChecked && e.target.value,
              })
            }
          />
        ) : (
          <Readonly text={analysisInfo?.refValueDelta || '-'} />
        )}
      </InputControl>
    </AnalysisSection>
  );
};

export default AnalysisVariations;
