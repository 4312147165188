/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import ScrollLock from 'react-scrolllock';

import classes from './Backdrop.module.css';

const Backdrop = ({ toggle, forceFill, onClick, children }) =>
  toggle && (
    <ScrollLock>
      <div
        onClick={onClick}
        role="dialog"
        className={`${classes.Backdrop} ${forceFill && classes.ForceFill}`}
      >
        {children}
      </div>
    </ScrollLock>
  );

export default Backdrop;
