import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { ROUTES } from './constants';
import { logout } from '../store/actions/auth';

const PrivateRoute = ({ allowed, children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { instance } = useMsal();

  const [render, setRender] = useState(null);
  const [authorized, setAuthorized] = useState(false);

  const user = useSelector((state) => state.auth.user);

  const logoutHandler = async () => {
    const currentAccount = instance.getAllAccounts()[0];

    await instance.logoutPopup({
      account: currentAccount,
      mainWindowRedirectUri: '/',
      postLogoutRedirectUri: '/',
    });

    dispatch(logout());
  };

  useEffect(() => {
    if (user) {
      if (allowed && !allowed.includes(user.role)) {
        /**
         * In this case, the user does not have
         * permission to access the page.
         * Therefore, the user will be redirected
         * to the dashboard attributed to
         * its role.
         */

        alert(t('unauthorized-access'));
        navigate(ROUTES.DASHBOARD.path);
      }

      setAuthorized(true);

      const tokenInfo = jwtDecode(user.token);

      /**
       * Check if the token expiration timestamp
       * has already been surpassed.
       */
      if (moment().isAfter(moment.unix(tokenInfo.exp))) {
        logoutHandler();
      }
    } else {
      navigate(ROUTES.LOGIN.path);
    }
  }, [user, allowed]);

  useEffect(() => {
    /**
     * The page will only render if the
     * user is authorized and allowed to
     * access it. This is to prevent the app
     * from rendering the page even when it
     * is about to redirect the user when
     * trying to access a page not allowed.
     */

    if (authorized) {
      setRender(children);
    }
  }, [authorized, children]);

  /**
   * If the code reached this point,
   * it means the user is both authenticated
   * in local storage and state, and is also
   * allowed to access the page.
   */
  return render;
};

export default PrivateRoute;
