import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Subtitle from '../../../atoms/Subtitle/Subtitle';
import classes from './PathologyHistory.module.css';
import Accordion from '../../../atoms/Accordion/Accordion';
import AccordionItem from '../../../atoms/Accordion/AccordionItem/AccordionItem';
import PathologyForm from '../PathologyForm/PathologyForm';
import Readonly from '../../../atoms/Readonly/Readonly';

const PathologyHistory = (props) => {
  const { pathology } = props;

  const { t } = useTranslation();

  const [active, setActive] = useState(null);

  return (
    <div className={classes.PathologyHistory}>
      <Subtitle subtitle={`${t('pathology-history-title')}:`} />
      {pathology?.history?.length < 1 && (
        <Readonly faded text={t('empty-data')} />
      )}

      {pathology?.history && pathology?.history?.length > 0 && (
        <Accordion active={active} setActive={setActive}>
          {pathology?.history?.map((version) => (
            <AccordionItem
              key={version.version}
              title={
                <span>{`${t('pathology-history-version')} ${
                  version.version
                }`}</span>
              }
              subtitle={
                <div className={classes.VersionAccordionSubtitle}>
                  <span>
                    {`${t('pathology-history-last-update')}: ${moment(
                      version.updatedAt,
                    ).format('DD/MM/YYYY')}`}
                  </span>
                  <span>{`${t('pathology-history-updated-by')}: ${
                    version.lastUpdateBy
                  }`}</span>
                </div>
              }
            >
              {/* Uses the same component as the regular form,
              but the "readonly" prop as "true" makes it so instead
              of inputs, the information will be put on labels */}
              <PathologyForm
                pathology={{ ...version, version: version.version }}
                readonly
              />
            </AccordionItem>
          ))}
        </Accordion>
      )}
    </div>
  );
};

export default PathologyHistory;
