import { useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref.
 * 
 * Taken from: https://codesandbox.io/s/outside-alerter-hooks-lmr2y
 */
export const useOutsideAlerter = (ref, callback) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};