import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RingLoader } from 'react-spinners';
import Input from '../../../atoms/Input/Input';
import Alert from './Alert/Alert';
import Accordion from '../../../atoms/Accordion/Accordion';
import AccordionItem from '../../../atoms/Accordion/AccordionItem/AccordionItem';
import AnalysisTable from './AnalysisTable/AnalysisTable';
import { CLINICAL_EPISODES_STATUSES } from '../../../../constants';
import classes from './PatientClinicalEpisodes.module.css';
import { fetchEpisodeParameters } from '../../../../store/actions/patients';

/**
 * Sub-component for the accordion's item header toggle, which will
 * be put on the left side. It will either only show the episode's ID
 * or the ID with a red alert on the side, in case the episode's status
 * is Risk Detected.
 */
const AccordionItemTitle = ({ id, appoloRefId, status }) => {
  const riskDetected = CLINICAL_EPISODES_STATUSES['risk-detected'];

  let title = id;

  if (appoloRefId) {
    title = `${id} (Appolo Ref ID: ${appoloRefId})`;
  }

  if (status === riskDetected.id) {
    return (
      <div className={classes.EpisodeTitle}>
        <span>{title}</span>
        <div className={classes.TitleCircle}>
          <img
            src={riskDetected.icon}
            alt={`risk detected ${riskDetected.id}`}
          />
        </div>
      </div>
    );
  }

  return title;
};

/**
 * Sub-component to be used as the "subtitle" (right side of the
 * accordion item's header toggle), which displays the episode's date
 * and status.
 */
const AccordionItemSubtitle = ({ date, status }) => {
  const { t } = useTranslation();

  const statusInformation = CLINICAL_EPISODES_STATUSES[status];

  return (
    <div className={classes.EpisodeSubtitle}>
      {/* Episode's date */}
      <div className={classes.SubtitleDate}>
        <b>{`${t('patient-analysis-list-date')}: `}</b>
        <span>{moment(date).format('DD-MM-YYYY')}</span>
      </div>

      {/* Episode's status */}
      <div className={classes.SubtitleStatus}>
        <b>{`${t('patient-analysis-list-status')}: `}</b>
        <div className={classes.StatusContainer}>
          <div
            style={{ backgroundColor: statusInformation.color }}
            className={classes.StatusCircle}
          />
          <span>{statusInformation.label}</span>
        </div>
      </div>
    </div>
  );
};

const PatientClinicalEpisodes = ({ episodes }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const episodeParameters = useSelector(
    (state) => state?.clinicalEpisodes?.episodeParameters,
  );
  const isFetchingParameters = useSelector(
    (state) => state?.clinicalEpisodes?.isFetchingParameters,
  );
  const isLoading = useSelector((state) => state?.patients?.isFetching);

  const [episodesFiltered, setEpisodesFiltered] = useState([]);
  const [search, setSearch] = useState('');
  const [active, setActive] = useState(null);

  /**
   * Sets the episodes that will be displayed on the list
   * (which can be already filtered by the date pickers).
   */
  useEffect(() => {
    setEpisodesFiltered(episodes);
  }, [episodes]);

  /**
   * Filters the episodes to only show the ones
   * that the ID starts like what is written on the search input.
   */
  useEffect(() => {
    if (!search) {
      setEpisodesFiltered(episodes);
      return;
    }

    const filtered = episodes.filter((episode) =>
      episode.appoloId.toLowerCase().startsWith(search.toLowerCase()),
    );

    setEpisodesFiltered(filtered);
  }, [search]);

  const handleAccordionState = useCallback(
    (index) => {
      setActive(index);
    },
    [setActive, active],
  );

  useEffect(() => {
    if (active !== null) {
      const episodeId = episodesFiltered[active]?.id;
      if (episodeId) dispatch(fetchEpisodeParameters(episodeId));
    }
  }, [active]);

  return (
    <div className={classes.PatientClinicalEpisodes}>
      {/* Header with the search input */}
      <div className={classes.Header}>
        <span className={classes.HeaderTitle}>{`${t(
          'patient-analysis-list-title',
        )}:`}</span>
        <Input
          type="search"
          placeholder={t('patient-analysis-list-search')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      {isLoading ? (
        <div className={classes.LoadingParameters}>
          <RingLoader color="var(--color-primary)" size={64} />
        </div>
      ) : (
        <>
          {/* List of episodes */}
          {episodesFiltered && episodesFiltered.length > 0 ? (
            <Accordion
              active={active}
              setActive={(i) => handleAccordionState(i)}
            >
              {episodesFiltered.map((episode) => (
                <AccordionItem
                  key={episode.appoloId}
                  title={
                    <AccordionItemTitle
                      id={episode.appoloId}
                      appoloRefId={episode.appoloRefId}
                      status={episode.status}
                    />
                  }
                  subtitle={
                    <AccordionItemSubtitle
                      date={episode.date}
                      status={episode.status}
                    />
                  }
                >
                  {/* Episode's content (Alert panel and parameter's table) */}
                  {episode.status !==
                    CLINICAL_EPISODES_STATUSES['automatically-validated']
                      .id && <Alert episode={episode} />}
                  {isFetchingParameters ? (
                    <div className={classes.LoadingParameters}>
                      <RingLoader color="var(--color-primary)" size={64} />
                    </div>
                  ) : (
                    episodeParameters?.length > 0 && (
                      <AnalysisTable analysis={episodeParameters} />
                    )
                  )}
                </AccordionItem>
              ))}
            </Accordion>
          ) : (
            <div className={classes.ChartNoData}>
              <span className={classes.ChartNoDataTitle}>
                {t('no-data-found-title')}
              </span>
              <span>{t('no-data-found-subtitle')}</span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PatientClinicalEpisodes;
