import React from 'react';
import NavBar from '../../components/molecules/NavBar/NavBar';
import Container from '../../components/atoms/Container/Container';
import Logo from '../../assets/images/main-logo.svg';
import ClinicalEpisodeTable from '../../components/organisms/ClinicalEpisodeTable/ClinicalEpisodeTable';
import classes from './DashboardScreen.module.css';

const DashboardScreen = ({ routes }) => (
  <>
    <NavBar routes={routes} />
    <Container>
      <img className={classes.Logo} src={Logo} alt="germano-sousa-logo" />
      <ClinicalEpisodeTable routes={routes} />
    </Container>
  </>
);

export default DashboardScreen;
