import React from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../../../atoms/Input/Input';
import InputControl from '../../../../molecules/InputControl/InputControl';
import Textarea from '../../../../atoms/Textarea/Textarea';
import classes from './PathologyFormInformation.module.css';

const PathologyFormInformation = (props) => {
  const { readonly, pathologyInfo, setPathologyInfo, setIsAltered } = props;

  const { t } = useTranslation();

  return (
    <div className={classes.PathologyInformationContent}>
      {/* Name Property */}
      <div className={classes.InputPathologyName}>
        <InputControl label={t('pathology-detail-form-name')}>
          {!readonly ? (
            <Input
              type="text"
              placeholder={t('pathology-detail-form-name')}
              value={pathologyInfo?.name}
              onChange={(e) => {
                setPathologyInfo({ ...pathologyInfo, name: e.target.value });
                setIsAltered(true);
              }}
            />
          ) : (
            <p className={classes.InputReadOnly}>{pathologyInfo?.name}</p>
          )}
        </InputControl>
      </div>

      {/* Description Property */}
      <div className={classes.InputPathologyDescription}>
        <InputControl label={t('pathology-detail-form-description')}>
          {!readonly ? (
            <Textarea
              placeholder={t('pathology-detail-form-description')}
              value={pathologyInfo?.description || ''}
              onChange={(e) => {
                setPathologyInfo({
                  ...pathologyInfo,
                  description: e.target.value,
                });
                setIsAltered(true);
              }}
            />
          ) : (
            <p className={classes.InputReadOnly}>
              {pathologyInfo?.description}
            </p>
          )}
        </InputControl>
      </div>
    </div>
  );
};

export default PathologyFormInformation;
