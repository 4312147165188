import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ClipLoader, RingLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import ChartPanel from './ChartPanel/ChartPanel';
import ModuleSelector from './ModuleSelector/ModuleSelector';
import classes from './PatientHistory.module.css';
import { fetchAllPathologies } from '../../../../store/actions/pathologies';

const PatientHistory = ({ episodes }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [modules, setModules] = useState([]);
  const [isFetchingModules, setIsFetchingModules] = useState(false);

  const [parametersSelected, setParametersSelected] = useState([]);
  const [selectedReferences, setSelectedReferences] = useState([]);
  const isFetchingAnUpdate = useSelector((state) => state.patients.isFetching);

  /**
   * Fetches all the pathologies from the database.
   */
  useEffect(() => {
    setIsFetchingModules(true);

    dispatch(fetchAllPathologies())
      .then((response) => {
        const pathologies = response.data.data;

        if (pathologies && pathologies.length > 0) {
          const pathologyModules = pathologies.sort((a, b) =>
            a.name > b.name ? 1 : -1,
          );

          setModules(pathologyModules);
        }
      })
      .catch((error) => {
        toast.error(
          `${t('patient-analysis-fetch-error')}: ${error.toString()}`,
        );
      })
      .finally(() => {
        setIsFetchingModules(false);
      });
  }, []);

  return (
    <div className={classes.PatientHistory}>
      {isFetchingModules ? (
        <div className={classes.ModuleSelectorLoading}>
          <ClipLoader size={28} color="#8A2432" />
        </div>
      ) : (
        <ModuleSelector
          modules={modules}
          parametersSelected={parametersSelected}
          setParametersSelected={setParametersSelected}
          selectedReferences={selectedReferences}
          setSelectedReferences={setSelectedReferences}
        />
      )}
      {isFetchingAnUpdate ? (
        <div className={classes.ChartPanelLoading}>
          <RingLoader color="var(--color-primary)" size={64} />
        </div>
      ) : (
        <ChartPanel
          episodes={episodes}
          parametersSelected={parametersSelected}
          selectedReferences={selectedReferences}
          setSelectedReferences={setSelectedReferences}
        />
      )}
    </div>
  );
};

export default PatientHistory;
