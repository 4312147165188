import axios from 'axios';

/**
 * Creates a basic instance of axios with the basis of
 * any request to be made to the API.
 */
export default axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Accept-Language': 'en-GB',
  },
});
