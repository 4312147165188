import React from 'react';
import CaptionList from '../CaptionList/CaptionList';
import classes from './Captions.module.css';

const Captions = (props) => {
  const { captions, captionsTextBreakLine, captionsTitle, captionsInfo } =
    props;

  return (
    <div className={`${classes.Captions} ${classes.CaptionsTop}`}>
      {captionsTitle && (
        <span className={classes.Title}>{`${captionsTitle} :`}</span>
      )}
      <CaptionList items={captions} breakLine={captionsTextBreakLine} />
      {captionsInfo && (
        <span className={classes.Info}>{`${captionsInfo}`}</span>
      )}
    </div>
  );
};

export default Captions;
