import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import Button from '../../../atoms/Button/Button';
import Subtitle from '../../../atoms/Subtitle/Subtitle';
import Toggle from '../../../atoms/Toggle/Toggle';
import PathologyFormAnalysisList from './PathologyFormAnalysisList/PathologyFormAnalysisList';
import PathologyFormInformation from './PathologyFormInformation/PathologyFormInformation';
import EnableVersionModal from '../EnableVersionModal/EnableVersionModal';
import ActionToggleEnabledIcon from '../../../../assets/images/check-icon.svg';
import ActionToggleDisabledIcon from '../../../../assets/images/times-icon.svg';
import {
  activatePathologyVersion,
  savePathology,
  savePathologyVersion,
} from '../../../../store/actions/pathologies';
import {
  POST_PATHOLOGY_DETAIL_START,
  POST_PATHOLOGY_VERSION_DETAIL_START,
} from '../../../../store/actions/types';
import classes from './PathologyForm.module.css';

const PathologyForm = (props) => {
  /**
   * The "readonly" prop transforms this component to replace
   * all inputs for labels.
   * Thus, this component is used for the main form and also to display
   * the information of past versions of the pathology, on the "History" tab.
   */
  const { pathology, readonly, routes } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  /**
   * Flag for when the request is being processed.
   */
  const isFetching = useSelector((state) => state.pathologies.isFetching);

  /**
   * Which request is actually pending.
   */
  const fetchingAction = useSelector(
    (state) => state.pathologies.fetchingAction,
  );

  const [pathologyInfo, setPathologyInfo] = useState({});
  const [analysisList, setAnalysisList] = useState(pathology?.parameters);
  const [versionBeingEnabled, setVersionBeingEnabled] = useState(null);
  const [isToggleEnableVersionModalOpen, setIsToggleEnableVersionModalOpen] =
    useState(false);
  const [activeAnalysis, setActiveAnalysis] = useState(null);
  const [isAltered, setIsAltered] = useState(false);

  /**
   * Fills the pathology data using the pathology object
   * pass as prop.
   */
  useEffect(() => {
    if (pathology) {
      setPathologyInfo({
        pathologyId: pathology?.pathologyId,
        id: pathology?.id,
        name: pathology?.name,
        description: pathology?.description,
        lastUpdateBy: pathology?.lastUpdateBy,
        updatedAt: pathology?.updatedAt,
        version: pathology?.version,
      });

      setAnalysisList(pathology?.parameters);

      /**
       * This block is supposed to trigger after creating a
       * new pathology. Since the URL for the page of creating a pathology
       * does not include the it of the newly created pathology,
       * after a pathology is created and set to the current state,
       * this block will check if the current URL is still the one
       * for creating the pathology, and will replace it for the URL
       * for editing the newly created pathology.
       */
      if (!readonly && location.pathname === routes.PATHOLOGY_DETAIL.path) {
        history.replaceState(
          '',
          '',
          routes.PATHOLOGY_DETAIL_EDIT.path.replace(
            ':id',
            pathology?.pathologyId,
          ),
        );
      }
    }
  }, [pathology]);

  /**
   * Saves the whole pathology information.
   */
  const onSavePathology = () => {
    if (pathologyInfo?.id) {
      dispatch(
        savePathologyVersion({
          ...pathologyInfo,
          parameters: analysisList,
          history: pathology.history,
        }),
      );

      return;
    }

    dispatch(
      savePathology({
        ...pathologyInfo,
        analysisList,
      }),
    );

    history.replaceState('', '', routes.PA);
  };

  /**
   * Adds a new analysis to the list, without effectively
   * saving it to the pathology's analysis list.
   */
  const onAddNewAnalysis = () => {
    const newAnalysisList = [...analysisList];
    newAnalysisList.push({ parameterTypeId: null });
    setAnalysisList(newAnalysisList);
  };

  /**
   * Adds a copy of a certain analysis to the
   * analysis list, right after the one that was copied.
   */
  const onCloneAnalysisOfList = (analysis, index) => {
    const newAnalysisList = [...analysisList];
    const newIndex = index++;

    newAnalysisList.splice(newIndex, 0, analysis);

    setAnalysisList(newAnalysisList);
    setActiveAnalysis(newIndex);
    setIsAltered(true);
  };

  /**
   * Removes a certain analysis from the analysis list.
   *
   * @param {*} analysisId
   */
  const onRemoveAnalysisFromList = (analysisIndex, isExistentAnalysis) => {
    analysisList?.splice(analysisIndex, 1);

    setAnalysisList([...analysisList]);
    setActiveAnalysis(null);
    if (isExistentAnalysis) {
      setIsAltered(true);
    }
  };

  /**
   * Saves the pathology with the new changes
   * to the analysis that was being edited.
   *
   * @param {*} index
   * @param {*} analysis
   */
  const onSaveAnalysis = (index, analysis, setMissingFields) => {
    if (!analysis || !analysis.parameterTypeId || !analysis.analysisTypeId) {
      setMissingFields(true);
      return;
    }

    const newAnalysisList = [...analysisList];
    newAnalysisList[index] = analysis;

    setAnalysisList(newAnalysisList);
    setIsAltered(true);
  };

  const onOpenToggleEnableVersionModal = (version) => {
    setVersionBeingEnabled(version);
    setIsToggleEnableVersionModalOpen(true);
  };

  const onCloseToggleEnableVersionModal = () => {
    setVersionBeingEnabled(null);
    setIsToggleEnableVersionModalOpen(false);
  };

  const onConfirmEnableVersion = async () => {
    await dispatch(activatePathologyVersion({ ...versionBeingEnabled }));
    onCloseToggleEnableVersionModal();
  };

  return (
    <div className={classes.PathologyForm}>
      <div className={classes.PathologyInformation}>
        {/* Form Main Header section */}
        <div className={classes.PathologyInformationHeader}>
          <Subtitle subtitle={`${t('pathology-detail-form-subtitle')}:`} />

          {!readonly && (
            <Button
              type="text"
              onClick={onSavePathology}
              disabled={
                (isFetching &&
                  (fetchingAction === POST_PATHOLOGY_DETAIL_START ||
                    fetchingAction === POST_PATHOLOGY_VERSION_DETAIL_START)) ||
                !isAltered
              }
            >
              <ClipLoader
                cssOverride={{ marginRight: '8px' }}
                loading={
                  isFetching &&
                  (fetchingAction === POST_PATHOLOGY_DETAIL_START ||
                    fetchingAction === POST_PATHOLOGY_VERSION_DETAIL_START)
                }
                size={16}
                color="var(--color-white)"
              />
              {t('button-save')}
            </Button>
          )}

          {readonly && (
            <Toggle
              checked={false}
              readonly
              disabledIcon={ActionToggleDisabledIcon}
              enabledIcon={ActionToggleEnabledIcon}
              onChange={() => onOpenToggleEnableVersionModal(pathologyInfo)}
            />
          )}
        </div>

        {/* Pathology information fields */}
        <PathologyFormInformation
          readonly={readonly}
          pathologyInfo={pathologyInfo}
          setPathologyInfo={setPathologyInfo}
          setIsAltered={setIsAltered}
        />
      </div>
      {/* List of Analysis associated to this pathology */}
      <PathologyFormAnalysisList
        readonly={readonly}
        analysisList={analysisList}
        onAddNewAnalysis={onAddNewAnalysis}
        onCloneAnalysisOfList={onCloneAnalysisOfList}
        onRemoveAnalysisFromList={onRemoveAnalysisFromList}
        onSaveAnalysis={onSaveAnalysis}
        activeAnalysis={activeAnalysis}
        pathology={pathology}
      />

      {/* Modal to enable or disable a version of the pathology */}
      {readonly && (
        <EnableVersionModal
          version={versionBeingEnabled}
          toggle={isToggleEnableVersionModalOpen}
          onClose={onCloseToggleEnableVersionModal}
          onConfirm={() => {
            onConfirmEnableVersion();
          }}
        />
      )}
    </div>
  );
};

export default PathologyForm;
