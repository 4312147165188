import React from 'react';
import { useTranslation } from 'react-i18next';
import AnalysisSection from '../AnalysisSection/AnalysisSection';
import InputControl from '../../../../../../molecules/InputControl/InputControl';
import Select from '../../../../../../atoms/Select/Select';
import Textarea from '../../../../../../atoms/Textarea/Textarea';
import Readonly from '../../../../../../atoms/Readonly/Readonly';
import classes from './AnalysisBasic.module.css';

const AnalysisBasic = (props) => {
  const {
    parameterTypes,
    analysisTypes,
    readonly,
    analysisInfo,
    setAnalysisInfo,
    missingMandatoryFields,
  } = props;

  const { t } = useTranslation();

  return (
    <AnalysisSection title={t('pathology-detail-analysis-basic-info')}>
      {/* Analysis Name / Parameter */}
      <div className={classes.AnalysisInputName}>
        <InputControl label={t('pathology-detail-analysis-name')}>
          {!readonly ? (
            <Select
              placeholder={t('pathology-detail-analysis-name')}
              options={parameterTypes}
              value={
                analysisInfo?.parameterTypeId && {
                  value: analysisInfo?.parameterTypeId,
                  label: parameterTypes?.find(
                    (parameter) =>
                      parameter.value === analysisInfo?.parameterTypeId,
                  )?.label,
                }
              }
              onChange={(val) =>
                setAnalysisInfo({ ...analysisInfo, parameterTypeId: val.value })
              }
            />
          ) : (
            <Readonly
              text={
                parameterTypes.find(
                  (parameter) =>
                    parameter.value === analysisInfo?.parameterTypeId,
                )?.label || '-'
              }
            />
          )}
          <span
            className={`${classes.mandatoryFields} ${
              !!missingMandatoryFields &&
              !analysisInfo?.parameterTypeId &&
              classes.missing
            }`.trim()}
          >
            {t('pathology-detail-analysis-basic-info-mandatory')}
          </span>
        </InputControl>
      </div>

      {/* Sample Type */}
      <div className={classes.AnalysisInputAnalysisType}>
        <InputControl label={t('pathology-detail-analysis-type')}>
          {!readonly ? (
            <Select
              placeholder={t('pathology-detail-analysis-type')}
              options={analysisTypes}
              value={
                analysisInfo?.analysisTypeId && {
                  value: analysisInfo?.analysisTypeId,
                  label: analysisTypes.find(
                    (parameter) =>
                      parameter.value === analysisInfo?.analysisTypeId,
                  )?.label,
                }
              }
              onChange={(val) =>
                setAnalysisInfo({ ...analysisInfo, analysisTypeId: val.value })
              }
            />
          ) : (
            <Readonly
              text={
                analysisTypes.find(
                  (parameter) =>
                    parameter.value === analysisInfo?.analysisTypeId,
                )?.label || '-'
              }
            />
          )}
          <span
            className={`${classes.mandatoryFields} ${
              !!missingMandatoryFields &&
              !analysisInfo?.analysisTypeId &&
              classes.missing
            }`.trim()}
          >
            {t('pathology-detail-analysis-basic-info-mandatory')}
          </span>
        </InputControl>
      </div>

      {/* Analysis Message */}
      <div className={classes.AnalysisInputMessage}>
        <InputControl label={t('pathology-detail-analysis-message')}>
          {!readonly ? (
            <Textarea
              placeholder={t('pathology-detail-analysis-message')}
              value={analysisInfo?.message || ''}
              onChange={(e) =>
                setAnalysisInfo({
                  ...analysisInfo,
                  message: e.target.value,
                })
              }
            />
          ) : (
            <Readonly text={analysisInfo?.message || '-'} />
          )}
        </InputControl>
      </div>
    </AnalysisSection>
  );
};

export default AnalysisBasic;
