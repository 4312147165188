import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Button from '../../../../atoms/Button/Button';
import AlertIcon from '../../../../../assets/images/alert-black-icon.svg';
import CheckIcon from '../../../../../assets/images/check-icon.svg';
import TimesIcon from '../../../../../assets/images/times-icon.svg';
import QuestionMarkIcon from '../../../../../assets/images/question-mark-icon.svg';
import { CLINICAL_EPISODES_STATUSES } from '../../../../../constants';
import api, { endpoints } from '../../../../../services/api';
import classes from './Alert.module.css';
import { updatePatientEpisodeOnEpisodeList } from '../../../../../store/actions/patients';
import { UPDATE_PATIENT_EPISODE_ON_LIST_START } from '../../../../../store/actions/types';
import { getAuthToken } from '../../../../../services/auth/getToken';
import { fetchDiagnosisByEpisode } from '../../../../../store/actions/clinicalEpisodes';

/**
 * Types of action possible in the alert component.
 */
const REPORT_TYPES = {
  validate: 'validate',
  reject: 'reject',
  maybe: 'maybe',
};

/**
 * Gets the class from the CSS file, with the correct color
 * for the Alert component, based on the status passed as
 * a parameter.
 */
const backgroundColorClass = (status) => {
  switch (status) {
    case CLINICAL_EPISODES_STATUSES['risk-detected'].id:
      return classes.RiskDetected;
    case CLINICAL_EPISODES_STATUSES['pediatric-sample'].id:
      return classes.PediatricSample;
    case CLINICAL_EPISODES_STATUSES['manually-validated'].id:
      return classes.ManuallyValidated;
    default:
      return undefined;
  }
};

/**
 * Formulates the title to be displayed on the alert
 * based on the status of the episode.
 */
const getTitleMessage = (t, episode, diagnoses, diagnoseDetected) => {
  const pathologies = diagnoses.map((diagnosis) => diagnosis.pathology);
  const isAccepted = diagnoses[0].isAccepted;

  switch (episode.status) {
    case CLINICAL_EPISODES_STATUSES['risk-detected'].id:
      return (
        <>
          <img src={AlertIcon} alt="alert-icon" className={classes.Icon} />
          <span className={classes.TitleText}>{`${t(
            'patient-analysis-alert-system-report',
          )}:`}</span>
          {pathologies.map((pathology, i) => (
            <span key={i}>
              <span className={classes.DiseaseReport}>{t(pathology)}</span>
              {i !== pathologies.length - 1 && t('and')}
            </span>
          ))}
        </>
      );
    case CLINICAL_EPISODES_STATUSES['manually-validated'].id:
      return (
        <>
          <span className={classes.TitleText}>
            {isAccepted
              ? `${t(
                  diagnoseDetected
                    ? 'patient-analysis-alert-accepted-diagnosis'
                    : 'patient-analysis-alert-accepted-result',
                )}:`
              : `${t(
                  diagnoseDetected
                    ? 'patient-analysis-alert-rejected-diagnosis'
                    : 'patient-analysis-alert-rejected-result',
                )}:`}
          </span>
          {pathologies.map((pathology, i) => (
            <span key={i}>
              <span className={classes.DiseaseReport}>{t(pathology)}</span>
              {i !== pathologies.length - 1 && t('and')}
            </span>
          ))}
        </>
      );
    case CLINICAL_EPISODES_STATUSES['pediatric-sample'].id:
      return (
        <span className={classes.TitleText}>{`${t(
          'patient-analysis-alert-pediatric-sample',
        )}:`}</span>
      );
    default:
      return undefined;
  }
};

/**
 * Formulates the subtitle message for cases which the
 * status of the episode is Risk Detected or Manually Validated.
 */
const getSubtitleMessage = (t, episode, diagnoses) => {
  const parameters = episode?.episodeParameters?.filter(
    (parameter) => parameter.isAtRisk,
  );

  switch (episode.status) {
    case CLINICAL_EPISODES_STATUSES['risk-detected'].id:
      return (
        <span className={classes.Subtitle}>
          {`${t('patient-analysis-alert-subtitle-decision-based-on')}: `}
          {parameters.map((param, i) => (
            <span key={i}>
              <span className={classes.ParametersReport}>
                {param.parameterName}
              </span>
              {i !== parameters.length - 1
                ? i !== parameters.length - 2
                  ? ', '
                  : ' and '
                : ''}
            </span>
          ))}
        </span>
      );
    case CLINICAL_EPISODES_STATUSES['manually-validated'].id:
      return (
        <span className={classes.Subtitle}>
          {`${t('patient-analysis-alert-subtitle-manually-validated')}: `}
          <span className={classes.UserBlamed}>
            {diagnoses[0]?.lastUpdateBy}
          </span>
        </span>
      );
    default:
      return undefined;
  }
};

const Alert = ({ episode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id } = useParams();

  const [diagnoses, setDiagnoses] = useState([]);
  const [isFetchingDiagnoses, setIsFetchingDiagnoses] = useState(false);
  const [reportingType, setReportingType] = useState(null);
  const [isPendingReport, setIsPendingReport] = useState(false);

  const isFetchingAnUpdate = useSelector((state) => state.patients.isFetching);
  const fetchingAction = useSelector((state) => state.patients.fetchingAction);

  const diagnoseDetected = useMemo(() => {
    const pathologies = diagnoses.map((diagnosis) => diagnosis.pathology);

    if (pathologies.length > 0) {
      return pathologies[0] !== 'patient-analysis-no-diagnose';
    }

    return false;
  }, [diagnoses]);

  /**
   * Fetch the diagnoses from this specific episode.
   */
  useEffect(() => {
    if (episode) {
      setIsFetchingDiagnoses(true);

      dispatch(fetchDiagnosisByEpisode(episode.id))
        .then((response) => {
          setDiagnoses(response.data);
        })
        .catch((error) => {
          toast.error(
            `${t(
              'patient-analysis-alert-fetch-diagnoses-error',
            )}: ${error.toString()}`,
          );
        })
        .finally(() => {
          setIsFetchingDiagnoses(false);
        });
    }
  }, []);

  /**
   * Accepts the diagnoses on this episode.
   */
  const validateDiagnoses = async (isAccepted) => {
    if (episode && diagnoses && diagnoses.length > 0) {
      setIsPendingReport(true);
      setReportingType(
        isAccepted ? REPORT_TYPES.validate : REPORT_TYPES.reject,
      );

      const ids = diagnoses.map((diagnosis) => diagnosis.id);

      const tokens = await getAuthToken();

      const config = {
        headers: {
          LABAI_AUTH: tokens.bearerToken,
          Azure_Access_Token: tokens.accessToken,
        },
      };

      api
        .patch(
          endpoints.CLINICAL_EPISODES_DIAGNOSES_VALIDATE_PATCH.replace(
            ':id',
            episode.id,
          ),
          {
            diagnosesIds: ids,
            isAccepted,
          },
          config,
        )
        .then((response) => {
          const updatedDiagnoses = response.data.updatedDiagnoses;
          const newDiagnoses = diagnoses.map((diagnosis) => {
            const updatedData = updatedDiagnoses.find(
              (updatedDiagnosis) => updatedDiagnosis.id === diagnosis.id,
            );

            return updatedData
              ? {
                  ...diagnosis,
                  isValidatedByDoctor: updatedData.isValidatedByDoctor,
                  isAccepted: updatedData.isAccepted,
                  lastUpdateBy: updatedData.lastUpdateBy,
                }
              : diagnosis;
          });

          setDiagnoses(newDiagnoses);

          dispatch(updatePatientEpisodeOnEpisodeList(id, episode.appoloId));

          setIsPendingReport(false);
          setReportingType(null);

          toast.success(
            isAccepted
              ? t(
                  diagnoseDetected
                    ? 'patient-analysis-alert-validate-diagnoses-accept-success'
                    : 'patient-analysis-alert-validate-results-accept-success',
                )
              : t(
                  diagnoseDetected
                    ? 'patient-analysis-alert-validate-diagnoses-reject-success'
                    : 'patient-analysis-alert-validate-results-reject-success',
                ),
          );
        })
        .catch((error) => {
          toast.error(
            `${t(
              'patient-analysis-alert-validate-diagnoses-error',
            )}: ${error.toString()}`,
          );

          setIsPendingReport(false);
          setReportingType(null);
        });
    }
  };

  const riskDetectedInfo = CLINICAL_EPISODES_STATUSES['risk-detected'];

  return isFetchingDiagnoses ||
    (isFetchingAnUpdate &&
      fetchingAction === UPDATE_PATIENT_EPISODE_ON_LIST_START) ? (
    <div className={`${classes.Alert} ${classes.Fetching}`}>
      <ClipLoader color="#8A2432" size={28} />
    </div>
  ) : !diagnoses || diagnoses.length < 1 ? null : (
    <div
      className={`${classes.Alert} ${backgroundColorClass(
        episode.status,
      )}`.trim()}
    >
      {/* Episode's title Message */}
      <div className={classes.Title}>
        {getTitleMessage(t, episode, diagnoses, diagnoseDetected)}
      </div>

      {/* If the episode's status is Risk Detected or Manually Validated
        then display a subtitle message with additional data
        regarding the analysis of the episode */}
      {[
        CLINICAL_EPISODES_STATUSES['risk-detected'].id,
        CLINICAL_EPISODES_STATUSES['manually-validated'].id,
      ].includes(episode.status) && (
        <div className={classes.Subtitle}>
          {getSubtitleMessage(t, episode, diagnoses)}
        </div>
      )}

      {/* Alert's actions in case the status is Risk Detected */}
      {episode.status === riskDetectedInfo.id && (
        <>
          {!diagnoseDetected ? (
            <div className={classes.NoDiagnosisActionsQuestion}>
              <span className={classes.Subtitle}>
                {t('patient-analysis-result-correct')}
              </span>
            </div>
          ) : null}
          <div className={classes.Actions}>
            <Button
              onClick={() => validateDiagnoses(true)}
              disabled={isPendingReport}
            >
              {isPendingReport && reportingType === REPORT_TYPES.validate ? (
                <ClipLoader
                  size={20}
                  color="#fff"
                  style={{ marginRight: '8px' }}
                />
              ) : (
                <img
                  src={CheckIcon}
                  alt="check-icon"
                  className={classes.ButtonIcon}
                />
              )}
              <span>
                {diagnoseDetected
                  ? t('patient-analysis-alert-action-accept')
                  : t('yes')}
              </span>
            </Button>
            <Button
              onClick={() => validateDiagnoses(false)}
              disabled={isPendingReport}
            >
              {isPendingReport && reportingType === REPORT_TYPES.reject ? (
                <ClipLoader
                  size={20}
                  color="#fff"
                  style={{ marginRight: '8px' }}
                />
              ) : (
                <img
                  src={TimesIcon}
                  alt="check-icon"
                  className={classes.ButtonIcon}
                />
              )}
              <span>
                {diagnoseDetected
                  ? t('patient-analysis-alert-action-reject')
                  : t('no')}
              </span>
            </Button>
            {diagnoseDetected ? (
              <Button disabled={isPendingReport}>
                <img
                  src={QuestionMarkIcon}
                  alt="check-icon"
                  className={classes.ButtonIcon}
                />
                <span>{t('patient-analysis-alert-action-maybe')}</span>
              </Button>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default Alert;
