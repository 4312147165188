import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { RingLoader } from 'react-spinners';
import { GENDERS } from '../../../../constants';
import classes from './PatientPersonalData.module.css';

const PatientPersonalData = ({ patient }) => {
  const { t } = useTranslation();

  return patient ? (
    <div className={classes.PatientPersonalData}>
      {/* Patient's Code */}
      <div className={classes.DataGridItem}>
        <span className={classes.ItemTitle}>{patient?.patientCode}</span>
      </div>

      {/* Patient's name */}
      <div className={classes.DataGridItem}>
        <span className={classes.ItemTitle}>{`${t(
          'patient-analysis-info-name',
        )}:`}</span>
        <span className={classes.ItemValue}>{patient.name || '-'}</span>
      </div>

      {/* Patient's gender */}
      <div className={classes.DataGridItem}>
        <span className={classes.ItemTitle}>{`${t(
          'patient-analysis-info-gender',
        )}:`}</span>
        <span className={classes.ItemValue}>
          {patient.gender.toLowerCase() in GENDERS
            ? GENDERS[patient.gender.toLowerCase()].label
            : 'Undefined'}
        </span>
      </div>

      {/* Patient's age */}
      <div className={classes.DataGridItem}>
        <span className={classes.ItemTitle}>{`${t(
          'patient-analysis-info-age',
        )}:`}</span>
        <span className={classes.ItemValue}>{`${moment().diff(
          patient?.birthDate,
          'years',
        )} ${t('patient-analysis-info-years')}`}</span>
      </div>
    </div>
  ) : (
    <div className={classes.LoadingParameters}>
      <RingLoader color="var(--color-primary)" size={64} />
    </div>
  );
};

export default PatientPersonalData;
