import React from 'react';
import { useTranslation } from 'react-i18next';
import AnalysisSection from '../../AnalysisSection/AnalysisSection';
import Dropdown from '../../../../../../../atoms/Dropdown/Dropdown';
import Readonly from '../../../../../../../atoms/Readonly/Readonly';
import { SINGLE_REFERENCE_VALUES } from '../../../../../../../../constants';

const SingleReference = (props) => {
  const { readonly, analysisInfo, setAnalysisInfo } = props;

  const { t } = useTranslation();

  return (
    <AnalysisSection title={t('pathology-detail-analysis-ref-value-single')}>
      {/* Reference Value */}
      {!readonly ? (
        <Dropdown
          border
          whiteText={false}
          placeholder={`${t('pathology-detail-analysis-ref-value-single')}...`}
          options={[
            {
              label: SINGLE_REFERENCE_VALUES.positive.label,
              value: SINGLE_REFERENCE_VALUES.positive.id,
            },
            {
              label: SINGLE_REFERENCE_VALUES.negative.label,
              value: SINGLE_REFERENCE_VALUES.negative.id,
            },
          ]}
          value={analysisInfo?.refValue}
          onChange={(val) =>
            setAnalysisInfo({
              ...analysisInfo,
              refValue: val.value,
            })
          }
        />
      ) : (
        <Readonly
          text={
            Object.values(SINGLE_REFERENCE_VALUES).find(
              (values) => values.id === analysisInfo?.refValue,
            ).label
          }
        />
      )}
    </AnalysisSection>
  );
};

export default SingleReference;
