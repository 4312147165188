/**
 * Clinical Episodes endpoints.
 */
export const CLINICAL_EPISODES_GET = '/clinical_episodes/get';
export const CLINICAL_EPISODES_GET_PATIENT =
  '/clinical_episodes/get/patient/:id';
export const CLINICAL_EPISODES_DIAGNOSES_GET =
  '/clinical_episodes/:id/diagnoses';
export const CLINICAL_EPISODES_DIAGNOSES_VALIDATE_PATCH =
  '/clinical_episodes/:id/diagnoses/validate';
export const CLINICAL_EPISODES_PARAMETERS = '/clinical_episodes/:id/parameters';

/**
 * Pathology endpoints.
 */
export const PATHOLOGY_GET_ALL = '/pathology/getAll';
export const PATHOLOGY_SWITCH_PATHOLOGY_STATUS =
  '/pathology/:id/switchPathologyStatus';
export const PATHOLOGY_CREATE_PATHOLOGY = '/pathology/createPathology';
export const PATHOLOGY_NEW_PATHOLOGY_VERSION = '/pathology/newPathologyVersion';
export const PATHOLOGY_PARAMETER_TYPES_LIST = '/pathology/parameterTypesList';
export const PATHOLOGY_ANALYSIS_TYPES_LIST = '/pathology/analysisTypesList';
export const PATHOLOGY_GET = '/pathology/get/:id';
export const PATHOLOGY_ACTIVATE_VERSION = '/pathology/:id/activateVersion';
export const PATHOLOGY_PARAMETERS = '/pathology/:id/parameters';

/**
 * Patient endpoints.
 */
export const PATIENT_GET = '/patient/get/:id';

/**
 * Support endpoints.
 */
export const SUPPORT_POST = '/support/save-support-information';
