import jwtDecode from 'jwt-decode';
import { AUTH_SUCCESS, LOGOUT_SUCCESS } from './types';

/**
 * Logs the user out, clearing the
 * state.
 */
export const logout = () => async (dispatch) => {
  dispatch({ type: LOGOUT_SUCCESS });
};

/**
 * Alerts the user that no role
 * was assign to the account,
 * logging out said user in the process.
 */
const throwNoRoleError = () => async (dispatch) => {
  alert('No role assigned for this user. Please, contact the System Admin.');

  dispatch(logout());
};

/**
 * Logs the user in, setting both
 * the state and the local storage
 * with information about the user.
 */
export const login = (data) => async (dispatch) => {
  try {
    const decoded = jwtDecode(data.idToken);

    const role = decoded.roles && decoded.roles[0] ? decoded.roles[0] : null;

    if (role) {
      dispatch({
        type: AUTH_SUCCESS,
        user: {
          name: decoded.name,
          role,
          email: decoded.preferred_username,
          token: data.idToken,
        },
      });
    } else {
      dispatch(throwNoRoleError());
    }
  } catch (error) {
    dispatch(throwNoRoleError());
  }
};
