import React from 'react';
import { useTranslation } from 'react-i18next';
import { CLINICAL_EPISODES_STATUSES } from '../../../../constants';
import classes from './ClinicalEpisodeStatus.module.css';

const ClinicalEpisodeStatus = ({ statusId }) => {
  const { t } = useTranslation();

  const statusInfo = CLINICAL_EPISODES_STATUSES[statusId];
  if (!statusInfo) {
    return null;
  }

  return (
    <div className={classes.ClinicalEpisodeStatus}>
      <div
        className={`${classes.Circle} ${statusInfo.class}`}
        style={{ backgroundColor: statusInfo.color }}
      >
        <img
          className={classes.Icon}
          src={statusInfo.icon}
          alt={statusInfo.id}
        />
      </div>
      <p>{t(`status-${statusInfo.id}`)}</p>
    </div>
  );
};

export default ClinicalEpisodeStatus;