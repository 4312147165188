import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { DebounceInput } from 'react-debounce-input';
import { useDispatch } from 'react-redux';
import Subtitle from '../../../../atoms/Subtitle/Subtitle';
import Input from '../../../../atoms/Input/Input';
import Button from '../../../../atoms/Button/Button';
import Accordion from '../../../../atoms/Accordion/Accordion';
import AccordionItem from '../../../../atoms/Accordion/AccordionItem/AccordionItem';
import PathologyAnalysis from './PathologyAnalysis/PathologyAnalysis';
import classes from './PathologyFormAnalysisList.module.css';
import Readonly from '../../../../atoms/Readonly/Readonly';
import {
  fetchPathologyAnalysisTypesList,
  fetchPathologyParameterTypesList,
} from '../../../../../store/actions/pathologies';

const PathologyFormAnalysisList = (props) => {
  const {
    readonly,
    analysisList,
    onAddNewAnalysis,
    onCloneAnalysisOfList,
    onRemoveAnalysisFromList,
    onSaveAnalysis,
    activeAnalysis,
    pathology,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [parameterTypes, setParameterTypes] = useState([]);
  const [analysisTypes, setAnalysisTypes] = useState([]);
  const [joinedListAnalysisParameters, setAnalysisParameter] = useState([]);
  const [search, setSearch] = useState(null);
  const [active, setActive] = useState(null);

  const joinedAnalysisParameter = (analysisList, parameterTypes) =>
    analysisList?.map((analysisItem) => {
      const matchingParameter = parameterTypes.find(
        (parameterItem) => parameterItem.value === analysisItem.parameterTypeId,
      );
      return matchingParameter
        ? { ...analysisItem, ...matchingParameter }
        : analysisItem;
    });

  /**
   * Fetch the Parameters List.
   */
  useEffect(() => {
    if (parameterTypes.length > 0) {
      return;
    }

    dispatch(fetchPathologyParameterTypesList())
      .then((result) => {
        setParameterTypes(
          result.data.map((parameterType) => ({
            label: parameterType.name,
            value: parameterType.id,
          })),
        );
      })
      .catch((err) =>
        toast.error(
          `${t('pathology-detail-fetch-parameters-error')}: ${err.toString()}`,
        ),
      );
  }, []);

  /**
   * Fetch the Sample Types List.
   */
  useEffect(() => {
    if (analysisTypes.length > 0) {
      return;
    }

    dispatch(fetchPathologyAnalysisTypesList())
      .then((result) => {
        setAnalysisTypes(
          result.data.map((analysisType) => ({
            label: analysisType.name,
            value: analysisType.id,
          })),
        );
      })
      .catch((err) =>
        toast.error(
          `${t('pathology-detail-fetch-analysis-error')}: ${err.toString()}`,
        ),
      );
  }, []);

  /**
   * Put the 2 lists together
   * BE should've done this
   */
  useEffect(() => {
    if (parameterTypes.length > 0 && analysisList?.length > 0) {
      setAnalysisParameter(
        joinedAnalysisParameter(analysisList, parameterTypes),
      );
    }
  }, [parameterTypes, analysisList]); // Use analysisList instead of analysisTypes

  return (
    <div>
      {analysisList && pathology && (
        <div className={classes.AnalysisList}>
          {/* Header Actions */}
          <div className={classes.AnalysisListHeader}>
            <Subtitle
              subtitle={`${t('pathology-detail-analysis-subtitle')}:`}
            />
            <DebounceInput
              type="search"
              value={search}
              placeholder={t('pathology-detail-analysis-search')}
              element={Input}
              debounceTimeout={300}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          {analysisList?.length < 1 && (
            <Readonly faded text={t('empty-data')} />
          )}

          {/* List of Analysis */}
          {analysisList?.length > 0 && (
            <div className={classes.AnalysisListWrapper}>
              <Accordion
                active={active}
                setActive={setActive}
                forcedActive={activeAnalysis}
                placeholderTitle={t(
                  'pathology-detail-analysis-new-placeholder',
                )}
              >
                {joinedListAnalysisParameters
                  ?.filter((analysis) =>
                    search
                      ? analysis.label
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      : true,
                  )
                  .map((analysis, i) => (
                    <AccordionItem
                      title={analysis.label}
                      key={analysis?.parameterTypeId}
                    >
                      <PathologyAnalysis
                        parameterTypes={parameterTypes}
                        analysisTypes={analysisTypes}
                        readonly={readonly}
                        analysis={analysis}
                        onCloneAnalysisOfList={onCloneAnalysisOfList}
                        onRemoveAnalysisFromList={onRemoveAnalysisFromList}
                        onSaveAnalysis={onSaveAnalysis}
                        index={i}
                      />
                    </AccordionItem>
                  ))}
              </Accordion>
            </div>
          )}

          {/* Add new Analysis */}
          {!readonly && (
            <Button
              type="button"
              style={{ marginTop: '20px' }}
              onClick={() => onAddNewAnalysis()}
            >
              {`+ ${t('pathology-detail-analysis-new')}`}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default PathologyFormAnalysisList;
