import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DebounceInput } from 'react-debounce-input';
import { ToastContainer, toast } from 'react-toastify';
import Select from '../../atoms/Select/Select';
import Button from '../../atoms/Button/Button';
import Input from '../../atoms/Input/Input';
import IconButton from '../../atoms/IconButton/IconButton';
import Toggle from '../../atoms/Toggle/Toggle';
import Table from '../../molecules/Table/Table';
import TogglePathologyModal from './TogglePathologyModal/TogglePathologyModal';
import ActionEditIcon from '../../../assets/images/icon_edit_red.svg';
import ActionToggleEnabledIcon from '../../../assets/images/check-icon.svg';
import ActionToggleDisabledIcon from '../../../assets/images/times-icon.svg';
import {
  togglePathologyStatus,
  getPathologies,
  resetPathologiesSuccess,
} from '../../../store/actions/pathologies';
import classes from './PathologyTable.module.css';
import 'react-toastify/dist/ReactToastify.css';

const PathologyTable = ({ routes }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [showOnlyStatus, setShowOnlyStatus] = useState(null);
  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState({
    direction: 'DESC',
    property: 'lastAcess',
  });

  /**
   * Pathology that is currently being displayed on the "toggle status" modal
   */
  const [pathologyBeingToggled, setPathologyBeingToggled] = useState(null);
  const [isTogglePathologyModalOpen, setIsTogglePathologyModalOpen] =
    useState(false);

  /**
   * Pathologies coming from the Redux store.
   */
  const pathologies = useSelector((state) => state.pathologies.pathologies);

  /**
   * Current "page" loaded from the database.
   */
  const page = useSelector((state) => state.pathologies.page);

  /**
   * Flag for when the request is being processed.
   */
  const isFetching = useSelector((state) => state.pathologies.isFetching);

  /**
   * Flag that is true if the last batch of data is already loaded.
   */
  const isLastPageLoaded = useSelector(
    (state) => state.pathologies.isLastPageLoaded,
  );

  /**
   * Any success messages from the API.
   */
  const success = useSelector((state) => state.pathologies.success);

  /**
   * Any errors regarding the API.
   */
  const error = useSelector((state) => state.pathologies.error);

  useEffect(() => {
    dispatch(resetPathologiesSuccess());
  });

  /**
   * Fetches the pathologies.
   */
  useEffect(() => {
    dispatch(
      getPathologies(process.env.REACT_APP_TABLE_PER_PAGE, 1, sort, filter),
    );
  }, [sort, filter]);

  /**
   * Opens the toggle pathology modal while setting the chosen pathology
   * which the status will be toggled.
   * @param {*} pathology
   */
  const onOpenTogglePathologyModal = (pathology) => {
    setPathologyBeingToggled(pathology);
    setIsTogglePathologyModalOpen(true);
  };

  /**
   * Closes the toggle pathology modal while unsetting the
   * pathology that was chosen to be toggled.
   */
  const onCloseTogglePathologyModal = () => {
    setPathologyBeingToggled(null);
    setIsTogglePathologyModalOpen(false);
  };

  /**
   * Changes the pathology status to the inverse of what
   * it currently is.
   */
  const onTogglePathologyStatus = () => {
    dispatch(togglePathologyStatus(pathologyBeingToggled));
    onCloseTogglePathologyModal();
  };

  /**
   *
   * @param {String} property
   * @param {*} value
   */
  const updateFilterByProperty = (property, value) => {
    if (filter.length < 1) {
      setFilter([{ property, value }]);
      return;
    }

    const index = filter.findIndex((item) => item.property === property);

    /**
     * Filter is not yet present in a filter array with
     * other filters already there.
     */
    if (index === -1) {
      setFilter([...filter, { property, value }]);
      return;
    }

    setFilter(
      filter.map((filterItem) =>
        filterItem.property === property ? { property, value } : filterItem,
      ),
    );
  };

  /**
   * Options to filter which pathologies should be showed.
   */
  const showOptions = [
    { label: t('pathology-list-option-all'), value: '*' },
    { label: t('pathology-list-option-enabled'), value: 'enabled' },
    { label: t('pathology-list-fetch-disabled'), value: 'disabled' },
  ];

  /**
   * Handler for when the dropdown to filter enabled/disabled
   * pathologies has changed.
   *
   * @param {*} val
   */
  const onShowOnlyStatusChanged = (val) => {
    setShowOnlyStatus(val);
  };

  /**
   * Reacts to changes in the Show Only dropdown and sets the filters
   * accordingly.
   */
  useEffect(() => {
    if (
      showOnlyStatus === '*' ||
      showOnlyStatus === null ||
      showOnlyStatus === undefined
    ) {
      setFilter(
        filter.filter((filterItem) => filterItem.property !== 'activeStatus'),
      );
      return;
    }

    updateFilterByProperty('activeStatus', showOnlyStatus === 'enabled');
  }, [showOnlyStatus]);

  /**
   * Handler to set the filter when something is typed
   * on the search bar.
   *
   * @param {*} event
   */
  const onSearchChanged = (event) => {
    setSearch(event.target.value);
  };

  /**
   * Reacts to changes in the Search input and sets the filters
   * accordingly.
   */
  useEffect(() => {
    if (search === '' || search === null || search === undefined) {
      setFilter(filter.filter((filterItem) => filterItem.property !== 'name'));
      return;
    }

    updateFilterByProperty('name', search);
  }, [search]);

  /**
   * Sends a notification if a success message
   * is sent from the API.
   */
  useEffect(() => {
    if (success) {
      toast.success(success);
    }
  }, [success]);

  /**
   * Sends a notification if an error related
   * to the API occurs.
   */
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  /**
   * Table column definition.
   */
  const columns = [
    { label: t('pathology-list-column-id'), property: 'pathologyId' },
    { label: t('pathology-list-column-name'), property: 'name' },
    {
      label: t('pathology-list-column-last-access'),
      property: 'lastAcess',
      render: (entry) => moment(entry).format('DD/MM/YYYY'),
    },
    {
      label: t('pathology-list-column-date'),
      property: 'createdAt',
      render: (entry) => moment(entry).format('DD/MM/YYYY'),
    },
    {
      label: t('pathology-list-column-actions'),
      render: (_, row) => (
        <div className={classes.Actions}>
          <IconButton
            icon={ActionEditIcon}
            iconHeight={18}
            blank
            alt={`action-edit_${row.pathologyId}`}
            onClick={() =>
              navigate(`${routes.PATHOLOGY_DETAIL.path}/${row.pathologyId}`)
            }
          />
          <Toggle
            id={`action-toggle_${row.pathologyId}`}
            checked={row.isActive}
            readonly
            onChange={() => onOpenTogglePathologyModal(row)}
            disabledIcon={ActionToggleDisabledIcon}
            enabledIcon={ActionToggleEnabledIcon}
          />
        </div>
      ),
    },
  ];

  return (
    <div className={classes.PathologyTable}>
      <div className={classes.Header}>
        <DebounceInput
          type="search"
          value={search}
          placeholder={t('pathology-list-search-placeholder')}
          element={Input}
          debounceTimeout={300}
          onChange={onSearchChanged}
        />
        <div className={classes.Actions}>
          <Select
            options={showOptions}
            placeholder={t('pathology-list-table-show')}
            onChange={(val) => onShowOnlyStatusChanged(val.value)}
          />
          <Button
            style={{ marginLeft: '16px' }}
            onClick={() => navigate(routes.PATHOLOGY_DETAIL.path)}
          >
            <span className={classes.ButtonNewIcon}>&#43;</span>
            <span>{t('pathology-list-button-new')}</span>
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        content={pathologies}
        sortable
        sort={sort}
        setSort={setSort}
        loading={isFetching}
      />
      <div className={classes.Footer}>
        <Button
          blank
          disabled={isFetching || isLastPageLoaded}
          onClick={() =>
            dispatch(
              getPathologies(
                process.env.REACT_APP_TABLE_PER_PAGE,
                page + 1,
                sort,
                filter,
              ),
            )
          }
        >
          {t('button-load-more')}
        </Button>
      </div>

      {/* Toggle Pathology's status modal */}
      <TogglePathologyModal
        pathology={pathologyBeingToggled}
        toggle={isTogglePathologyModalOpen}
        onClose={onCloseTogglePathologyModal}
        onConfirm={onTogglePathologyStatus}
      />

      <ToastContainer pauseOnHover />
    </div>
  );
};

export default PathologyTable;
