/**
 * Based on https://github.com/fraserxu/react-dropdown
 */

import React from 'react';
import ReactDropdown from 'react-dropdown';
import PropTypes from 'prop-types';

import 'react-dropdown/style.css';
import classes from './Dropdown.module.css';

const Dropdown = (props) => {
  const {
    blank = true,
    whiteText = true,
    border = false,
    small = false,
    placeholder,
    options,
    value,
    ...args
  } = props;

  const dropdownClassName = `
    ${classes.Dropdown}
    ${small && classes.Small}
  `.trim();

  const controlClassName = `
    ${classes.Dropdown__control}
    ${
      border ? classes['Dropdown__control-bordered'] : classes.Dropdown__control
    }${' '}
    ${
      blank ? classes['Dropdown__control-blank'] : classes.Dropdown__control
    }${' '}
    ${whiteText && classes['Dropdown__control-white-text']}
  `.trim();

  const arrowClassName = `
    ${classes.Dropdown__arrow} 
    ${
      whiteText
        ? classes['Dropdown__arrow-white']
        : classes['Dropdown__arrow-black']
    }
  `.trim();

  return (
    <ReactDropdown
      className={dropdownClassName}
      controlClassName={controlClassName}
      menuClassName={classes.Dropdown__menu}
      arrowClassName={arrowClassName}
      options={options}
      placeholder={placeholder}
      value={value}
      {...args}
    />
  );
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ).isRequired,
  placeholder: PropTypes.string,
  blank: PropTypes.bool,
  border: PropTypes.bool,
  small: PropTypes.bool,
  whiteText: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

Dropdown.defaultProps = {
  blank: true,
  whiteText: true,
  border: false,
  small: false,
  placeholder: null,
  onChange: null,
  value: null,
};

export default Dropdown;
