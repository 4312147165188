import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { ROUTES } from './constants';

/**
 * Router component that loops all the defined routes, and
 * adds to the route list all the private and public route related
 * components (or "elements"). 
 * It provides to every element the list of routes as a prop.
 */
const Router = () => (
  <BrowserRouter>
    <Routes>
      {Object.values(ROUTES).map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            route.private ? (
              <PrivateRoute allowed={route.allowed}>
                <route.element routes={ROUTES} />
              </PrivateRoute>
            ) : (
              <route.element routes={ROUTES} />
            )
          }
        />
      ))}
    </Routes>
  </BrowserRouter>
);

export default Router;
