import {
  GET_PATHOLOGIES_START,
  GET_PATHOLOGIES_SUCCESS,
  GET_PATHOLOGIES_ERROR,
  GET_PATHOLOGY_DETAIL_START,
  GET_PATHOLOGY_DETAIL_SUCCESS,
  GET_PATHOLOGY_DETAIL_ERROR,
  PATCH_PATHOLOGY_STATUS_START,
  PATCH_PATHOLOGY_STATUS_SUCCESS,
  PATCH_PATHOLOGY_STATUS_ERROR,
  POST_PATHOLOGY_DETAIL_START,
  POST_PATHOLOGY_DETAIL_SUCCESS,
  POST_PATHOLOGY_DETAIL_ERROR,
  POST_PATHOLOGY_VERSION_DETAIL_START,
  POST_PATHOLOGY_VERSION_DETAIL_SUCCESS,
  POST_PATHOLOGY_VERSION_DETAIL_ERROR,
  PATCH_PATHOLOGY_VERSION_START,
  PATCH_PATHOLOGY_VERSION_SUCCESS,
  PATCH_PATHOLOGY_VERSION_ERROR,
  RESET_PATHOLOGY_SUCCESS,
} from '../actions/types';

/**
 * @property pathologies   List of currently loaded pathologies.
 * @property pathologySelected   Current pathology that is being set to be
 * displayed in the pathology detail screen.
 * @property loading   Flag for when the request is being processed.
 * @property error   Will be set to any error that may be incoming.
 */
const initialState = {
  pathologies: [],
  pathologySelected: null,
  page: 1,
  isLastPageLoaded: false,
  isFetching: false,
  fetchingAction: null,
  error: null,
  success: null,
};

function pathologiesReducer(state = initialState, action) {
  switch (action.type) {
    /**
     * Case for when the process of getting the list of
     * pathologies from the API starts.
     */
    case GET_PATHOLOGIES_START:
      return {
        ...state,
        error: null,
        fetchingAction: action.type,
        isFetching: true,
      };

    /**
     * Case for when the process of getting the list of
     * pathologies from the API is completed successfully.
     */
    case GET_PATHOLOGIES_SUCCESS:
      return {
        ...state,
        pathologies:
          action.page === 1
            ? action.pathologies
            : state.pathologies.concat(action.pathologies),
        page: action.page === 1 ? 1 : action.page,
        isLastPageLoaded: action.isLastPageLoaded,
        isFetching: false,
        fetchingAction: null,
        pathologySelected: null,
      };

    /**
     * Case for when the process of getting the list of
     * pathologies from the API fails.
     */
    case GET_PATHOLOGIES_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        fetchingAction: null,
      };

    /**
     * Case for when the process of getting the details of a given
     * pathology from the API starts.
     */
    case GET_PATHOLOGY_DETAIL_START:
      return {
        ...state,
        isFetching: true,
        fetchingAction: action.type,
        error: null,
      };

    /**
     * Case for when the process of getting the details of a given
     * pathology from the API is completed successfully.
     */
    case GET_PATHOLOGY_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fetchingAction: null,
        pathologySelected: action.pathologySelected,
      };

    /**
     * Case for when the process of getting the details of a given
     * pathology from the API fails.
     */
    case GET_PATHOLOGY_DETAIL_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchingAction: null,
        error: action.error,
      };

    /**
     * Case for when the process of toggling the current status of
     * a certain pathology starts.
     */
    case PATCH_PATHOLOGY_STATUS_START:
      return {
        ...state,
        isFetching: true,
        fetchingAction: action.type,
        error: null,
        success: null,
      };

    /**
     * Case for when the process of toggling the current status of
     * a certain pathology is completed successfully.
     *
     * It loops through all the loaded pathologies, and resets the
     * pathology which status was changed, to reflect that said change
     * in the UI.
     */
    case PATCH_PATHOLOGY_STATUS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fetchingAction: null,
        pathologies:
          state.pathologies.length > 0
            ? state.pathologies.map((pathology) =>
                pathology.pathologyId === action.pathology.pathologyId
                  ? action.pathology
                  : pathology,
              )
            : state.pathologies,
        success: action.success,
      };

    /**
     * Case for when the process of toggling the current status of
     * a certain pathology fails.
     */
    case PATCH_PATHOLOGY_STATUS_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchingAction: null,
        error: action.error,
      };

    /**
     * Case for when the process of saving
     * the first version of a pathology begins.
     */
    case POST_PATHOLOGY_DETAIL_START:
      return {
        ...state,
        isFetching: true,
        fetchingAction: action.type,
        error: null,
        success: null,
      };

    /**
     * Case for when the process of saving
     * the first version of a pathology is successful.
     */
    case POST_PATHOLOGY_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fetchingAction: null,
        pathologySelected: action.pathology,
        success: action.success,
      };

    /**
     * Case for when the process of saving
     * the first version of a pathology has failed.
     */
    case POST_PATHOLOGY_DETAIL_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchingAction: null,
        error: action.error,
      };

    /**
     * Case for when the process of updating and creating a
     * new version of a pathology begins.
     */
    case POST_PATHOLOGY_VERSION_DETAIL_START:
      return {
        ...state,
        isFetching: true,
        fetchingAction: action.type,
        success: null,
        error: null,
      };

    /**
     * Case for when the process of updating and creating a
     * new version of a pathology was successful.
     */
    case POST_PATHOLOGY_VERSION_DETAIL_SUCCESS:
      return {
        ...state,
        pathologySelected: action.pathology,
        isFetching: false,
        fetchingAction: null,
        success: action.success,
      };

    /**
     * Case for when the process of updating and creating a
     * new version of a pathology has failed.
     */
    case POST_PATHOLOGY_VERSION_DETAIL_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchingAction: null,
        error: action.error,
      };

    /**
     * Case for when the process of activating an previous
     * version of a pathology begins.
     */
    case PATCH_PATHOLOGY_VERSION_START:
      return {
        ...state,
        isFetching: true,
        fetchingAction: action.type,
        error: null,
        success: null,
      };

    /**
     * Case for when the process of activating an previous
     * version of a pathology was successful.
     */
    case PATCH_PATHOLOGY_VERSION_SUCCESS:
      return {
        ...state,
        pathologySelected: {
          ...action.pathologySelected,
        },
        isFetching: false,
        fetchingAction: null,
        success: action.success,
      };

    /**
     * Case for when the process of activating an previous
     * version of a pathology has failed.
     */
    case PATCH_PATHOLOGY_VERSION_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchingAction: null,
        error: action.error,
      };

    case RESET_PATHOLOGY_SUCCESS:
      return {
        ...state,
        success: null,
      };

    default:
      return state;
  }
}

export default pathologiesReducer;
