import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classes from './Toggle.module.css';

const Toggle = (props) => {
  const {
    id,
    enabledIcon,
    disabledIcon,
    checked = false,
    name,
    disabled,
    onChange,
    readonly = false,
  } = props;

  const [toggleChecked, setToggleChecked] = useState(checked);

  const toggleChanged = (event) => {
    if (!readonly) {
      setToggleChecked(event.target.checked);
    }

    onChange(event.target.checked);
  };

  useEffect(() => {
    setToggleChecked(checked);
  }, [checked]);

  return (
    <label
      htmlFor={id}
      className={`${classes.Toggle} ${disabled && classes.Toggle__disabled} ${
        toggleChecked && classes.Checked
      }`.trim()}
    >
      <input
        disabled={disabled}
        name={name}
        type="checkbox"
        id={id}
        checked={toggleChecked}
        onChange={(event) => toggleChanged(event)}
      />
      <div className={`${classes.Circle}`}>
        <img
          src={toggleChecked ? enabledIcon : disabledIcon}
          alt="toggle-icon"
        />
      </div>
    </label>
  );
};

Toggle.propTypes = {
  id: PropTypes.string,
  enabledIcon: PropTypes.string,
  disabledIcon: PropTypes.string,
  checked: PropTypes.bool,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  readonly: PropTypes.bool,
};

Toggle.defaultProps = {
  id: null,
  disabledIcon: null,
  enabledIcon: null,
  checked: false,
  name: null,
  disabled: false,
  onChange: null,
  readonly: false,
};

export default Toggle;
