import PropTypes from 'prop-types';

const TabItem = ({ children }) => children;

TabItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  inactiveIcon: PropTypes.string,
};

TabItem.defaultProps = {
  title: null,
  icon: null,
  inactiveIcon: null,
};

export default TabItem;