import React from 'react';
import PropTypes from 'prop-types';

import classes from './Checkbox.module.css';

const Checkbox = (props) => {
  const { checked, ...args } = props;

  return (
    <div className={classes.Checkbox}>
      <input type="checkbox" checked={checked} {...args} />
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  id: null,
  name: null,
  checked: false,
  disabled: false,
  onChange: null,
};

export default Checkbox;
