import React from 'react';
import { useTranslation } from 'react-i18next';
import AnalysisSection from '../AnalysisSection/AnalysisSection';
import InputControl from '../../../../../../molecules/InputControl/InputControl';
import RadioGroup from '../../../../../../atoms/RadioGroup/RadioGroup';
import { ANALYSIS_FORMAT } from '../../../../../../../constants';
import Readonly from '../../../../../../atoms/Readonly/Readonly';

const AnalysisFormat = (props) => {
  const { readonly, analysisFormat, setAnalysisFormat } = props;

  const { t } = useTranslation();

  return (
    <AnalysisSection title={t('pathology-detail-analysis-format')}>
      {/* Analysis Format Selection Radio Input */}
      {!readonly ? (
        <InputControl label={t('pathology-detail-analysis-format-choose')}>
          <RadioGroup
            name="analysis-format"
            options={[
              {
                label: ANALYSIS_FORMAT.single.label,
                value: ANALYSIS_FORMAT.single.id,
              },
              {
                label: ANALYSIS_FORMAT.interval.label,
                value: ANALYSIS_FORMAT.interval.id,
              },
            ]}
            value={analysisFormat}
            onChange={(e) => setAnalysisFormat(e.target.value)}
          />
        </InputControl>
      ) : (
        <Readonly
          text={
            Object.values(ANALYSIS_FORMAT).find(
              (format) => format.id === analysisFormat,
            ).label
          }
        />
      )}
    </AnalysisSection>
  );
};

export default AnalysisFormat;
