import React, { useRef } from 'react';
import { useOutsideAlerter } from "../hooks/useOutsideAlerter";

/**
 * Component that alerts if you click outside of it
 * 
 * Taken from: https://codesandbox.io/s/outside-alerter-hooks-lmr2y
 */
const OutsideAlerter = ({ callback, children }) => {
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, callback);

  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideAlerter;