import React from 'react';
import CaptionItem from './CaptionItem/CaptionItem';

import classes from './CaptionList.module.css';

const CaptionList = ({ items, breakLine }) => (
  <div className={classes.CaptionList}>
    {items.map((item, i) => (
      <CaptionItem breakLine={breakLine} item={item} key={i} />
    ))}
  </div>
);

export default CaptionList;