import i18next from '../../i18n';
import { getAnalysisFormatFromAnalysis } from '../../helpers';
import api, { endpoints } from '../../services/api';
import { extractFiltersAsParameters, extractSortAsParameters } from '../utils';
import {
  GET_PATHOLOGIES_START,
  GET_PATHOLOGIES_SUCCESS,
  GET_PATHOLOGIES_ERROR,
  GET_PATHOLOGY_DETAIL_START,
  GET_PATHOLOGY_DETAIL_SUCCESS,
  GET_PATHOLOGY_DETAIL_ERROR,
  POST_PATHOLOGY_DETAIL_START,
  POST_PATHOLOGY_DETAIL_SUCCESS,
  POST_PATHOLOGY_DETAIL_ERROR,
  POST_PATHOLOGY_VERSION_DETAIL_START,
  POST_PATHOLOGY_VERSION_DETAIL_SUCCESS,
  PATCH_PATHOLOGY_STATUS_START,
  PATCH_PATHOLOGY_STATUS_SUCCESS,
  PATCH_PATHOLOGY_STATUS_ERROR,
  PATCH_PATHOLOGY_VERSION_START,
  PATCH_PATHOLOGY_VERSION_SUCCESS,
  PATCH_PATHOLOGY_VERSION_ERROR,
  RESET_PATHOLOGY_SUCCESS,
} from './types';
import { getAuthToken } from '../../services/auth/getToken';

/**
 * Lists pathologies within the parameters
 * of the desired pagination, sort and filters.
 *
 * @param {*} size
 * @param {*} page
 * @param {*} sort
 * @param {*} filter
 * @returns
 */
export const getPathologies =
  (size = null, page = null, sort = null, filter = []) =>
  async (dispatch) => {
    dispatch({ type: GET_PATHOLOGIES_START });

    try {
      const tokens = await getAuthToken();

      const config = {
        headers: {
          LABAI_AUTH: tokens.bearerToken,
          Azure_Access_Token: tokens.accessToken,
        },
      };

      const response = await api.get(endpoints.PATHOLOGY_GET_ALL, {
        ...config,
        params: {
          size,
          page,
          ...extractSortAsParameters(sort),
          ...extractFiltersAsParameters(filter),
        },
      });

      dispatch({
        type: GET_PATHOLOGIES_SUCCESS,
        pathologies: response?.data?.data,
        page,
        isLastPageLoaded: response?.data?.isLastPage,
      });
    } catch (error) {
      dispatch({
        type: GET_PATHOLOGIES_ERROR,
        error: i18next.t('pathology-list-fetch-error'),
      });
    }
  };

/**
 * Gets a certain pathology with all its old versions as well.
 */
export const getPathologyDetail = (pathologyId) => async (dispatch) => {
  dispatch({ type: GET_PATHOLOGY_DETAIL_START });

  try {
    const tokens = await getAuthToken();

    const config = {
      headers: {
        LABAI_AUTH: tokens.bearerToken,
        Azure_Access_Token: tokens.accessToken,
      },
    };

    const result = await api.get(
      endpoints.PATHOLOGY_GET.replace(':id', pathologyId),
      config,
    );

    const pathologySelected = result.data.find(
      (pathology) => pathology.isActiveVersion,
    );

    pathologySelected.parameters = pathologySelected.parameters.map(
      (parameter) => ({
        ...parameter,
        format: getAnalysisFormatFromAnalysis(parameter),
      }),
    );

    const history = result.data
      .filter(
        (pathology) =>
          pathology.pathologyId !== pathologyId && !pathology.isActiveVersion,
      )
      .sort((a, b) => b.version - a.version);

    dispatch({
      type: GET_PATHOLOGY_DETAIL_SUCCESS,
      pathologySelected: {
        ...pathologySelected,
        history,
      },
    });
  } catch (error) {
    dispatch({
      type: GET_PATHOLOGY_DETAIL_ERROR,
      error: i18next.t('pathology-detail-fetch-error'),
    });
  }
};

/**
 * Creates a new pathology, in its first version without parameters.
 *
 * @param {*} pathology
 * @returns
 */
export const savePathology = (pathology) => async (dispatch) => {
  dispatch({ type: POST_PATHOLOGY_DETAIL_START });

  try {
    const tokens = await getAuthToken();

    const config = {
      headers: {
        LABAI_AUTH: tokens.bearerToken,
        Azure_Access_Token: tokens.accessToken,
      },
    };

    const result = await api.post(
      endpoints.PATHOLOGY_CREATE_PATHOLOGY,
      {
        name: pathology.name,
        description: pathology.description,
      },
      config,
    );

    dispatch({
      type: POST_PATHOLOGY_DETAIL_SUCCESS,
      pathology: {
        ...result.data,
        parameters: [],
        history: [],
      },
      success: 'Pathology saved successfully.',
    });
  } catch (error) {
    dispatch({
      type: POST_PATHOLOGY_DETAIL_ERROR,
      error: i18next.t('pathology-detail-fetch-save-error'),
      errorObject: error,
    });
  }
};

/**
 * Saves a new version of a given pathology.
 *
 * @param {*} pathology
 * @returns
 */
export const savePathologyVersion = (pathology) => async (dispatch) => {
  dispatch({ type: POST_PATHOLOGY_VERSION_DETAIL_START });

  try {
    const tokens = await getAuthToken();

    const config = {
      headers: {
        LABAI_AUTH: tokens.bearerToken,
        Azure_Access_Token: tokens.accessToken,
      },
    };

    const result = await api.post(
      endpoints.PATHOLOGY_NEW_PATHOLOGY_VERSION,
      {
        ...pathology,
      },
      config,
    );

    const newPathologyVersion = result.data;
    newPathologyVersion.parameters = result.data.createdParameters;
    delete newPathologyVersion.createdParameters;

    const history = pathology.history;
    delete pathology.history;
    history.unshift(pathology);

    newPathologyVersion.parameters = newPathologyVersion.parameters.map(
      (parameter) => ({
        ...parameter,
        format: getAnalysisFormatFromAnalysis(parameter),
      }),
    );

    dispatch({
      type: POST_PATHOLOGY_VERSION_DETAIL_SUCCESS,
      pathology: {
        ...newPathologyVersion,
        history,
      },
      success: i18next.t('pathology-detail-fetch-save-success'),
    });
  } catch (error) {
    if (error.response.status === 400) {
      dispatch({
        type: POST_PATHOLOGY_DETAIL_ERROR,
        error: i18next.t(error.response.data.message),
        errorObject: error,
      });
    } else {
      dispatch({
        type: POST_PATHOLOGY_DETAIL_ERROR,
        error: i18next.t('pathology-detail-fetch-save-error'),
        errorObject: error,
      });
    }
  }
};

/**
 * Toggles the current status of a given pathology
 * to active or not active.
 *
 * @param {*} pathology
 * @returns
 */
export const togglePathologyStatus = (pathology) => async (dispatch) => {
  dispatch({ type: PATCH_PATHOLOGY_STATUS_START });

  try {
    const tokens = await getAuthToken();

    const config = {
      headers: {
        LABAI_AUTH: tokens.bearerToken,
        Azure_Access_Token: tokens.accessToken,
      },
    };

    const response = await api.patch(
      endpoints.PATHOLOGY_SWITCH_PATHOLOGY_STATUS.replace(
        ':id',
        pathology.pathologyId,
      ),
      {},
      config,
    );

    dispatch({
      type: PATCH_PATHOLOGY_STATUS_SUCCESS,
      pathology: {
        ...pathology,
        isActive: response.data.newStatus,
      },
      success: i18next.t('pathology-detail-fetch-toggle-success'),
    });
  } catch (error) {
    dispatch({
      type: PATCH_PATHOLOGY_STATUS_ERROR,
      error: i18next.t('pathology-detail-fetch-toggle-error'),
    });
  }
};

export const activatePathologyVersion =
  (version) => async (dispatch, getState) => {
    dispatch({ type: PATCH_PATHOLOGY_VERSION_START });

    try {
      const tokens = await getAuthToken();

      const config = {
        headers: {
          LABAI_AUTH: tokens.bearerToken,
          Azure_Access_Token: tokens.accessToken,
        },
      };

      const response = await api.patch(
        endpoints.PATHOLOGY_ACTIVATE_VERSION.replace(':id', version.id),
        {},
        config,
      );

      /**
       * Previous version that has been activated.
       */
      let newActiveVersion = response.data;

      /**
       * Current pathology data.
       */
      const pathologySelected = getState().pathologies.pathologySelected;

      /**
       * Get all data from the current pathology data belonging to the
       * newly activated version.
       */
      newActiveVersion = pathologySelected.history.find(
        (version) => version.id === newActiveVersion.id,
      );

      /**
       * Old active version data, ie, the version marked as active before
       * this new change.
       */
      const oldActiveVersion = { ...pathologySelected };
      delete oldActiveVersion.history;

      /**
       * Build new history: get all the versions except the one
       * that as been set as active, and then push the previous active version
       * into the history.
       */
      let history = pathologySelected.history.filter(
        (version) => version.id !== newActiveVersion.id,
      );

      history.push(oldActiveVersion);
      history = history.sort((a, b) => b.version - a.version);

      dispatch({
        type: PATCH_PATHOLOGY_VERSION_SUCCESS,
        pathologySelected: {
          ...newActiveVersion,
          history,
        },
        success: i18next.t('pathology-detail-fetch-enable-success'),
      });
    } catch (error) {
      dispatch({
        type: PATCH_PATHOLOGY_VERSION_ERROR,
        error: i18next.t('pathology-detail-fetch-enable-error'),
        errorObject: error,
      });
    }
  };

export const resetPathologiesSuccess = () => (dispatch) => {
  dispatch({ type: RESET_PATHOLOGY_SUCCESS });
};

export const fetchPathologyParameters = (pathologyId) => async () => {
  try {
    const tokens = await getAuthToken();

    const config = {
      headers: {
        LABAI_AUTH: tokens.bearerToken,
        Azure_Access_Token: tokens.accessToken,
      },
    };

    const response = api.get(
      endpoints.PATHOLOGY_PARAMETERS.replace(':id', pathologyId),
      config,
    );

    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchPathologyAnalysisTypesList = () => async () => {
  try {
    const tokens = await getAuthToken();

    const config = {
      headers: {
        LABAI_AUTH: tokens.bearerToken,
        Azure_Access_Token: tokens.accessToken,
      },
    };

    const response = api.get(endpoints.PATHOLOGY_ANALYSIS_TYPES_LIST, config);

    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchPathologyParameterTypesList = () => async () => {
  try {
    const tokens = await getAuthToken();

    const config = {
      headers: {
        LABAI_AUTH: tokens.bearerToken,
        Azure_Access_Token: tokens.accessToken,
      },
    };

    const response = api.get(endpoints.PATHOLOGY_PARAMETER_TYPES_LIST, config);

    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchAllPathologies = () => async () => {
  try {
    const tokens = await getAuthToken();

    const config = {
      headers: {
        LABAI_AUTH: tokens.bearerToken,
        Azure_Access_Token: tokens.accessToken,
      },
    };

    const response = api.get(endpoints.PATHOLOGY_GET_ALL, config);

    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
