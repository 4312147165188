import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { login } from '../../store/actions/auth';
import Button from '../../components/atoms/Button/Button';
import Logo from '../../assets/images/main-logo.svg';
import classes from './LoginScreen.module.css';
import { loginRequest } from '../../services/auth/config';

const LoginScreen = ({ routes }) => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(routes.DASHBOARD.path);
    }
  }, [isAuthenticated]);

  /**
   * Opens the Azure AD popup.
   */
  const loginHandler = () => {
    instance
      .loginPopup(loginRequest)
      .then((data) => {
        dispatch(login(data));
      })
      .catch(() => {
        alert(t('unauthorized-guest'));
      });
  };

  return (
    <div className={classes.LoginScreen}>
      <div className={classes.Content}>
        <img src={Logo} alt="main-logo" />
        <p>{t('login-main-text')}</p>
        <Button onClick={loginHandler}>{t('login-button')}</Button>
      </div>
      <ToastContainer pauseOnHover />
    </div>
  );
};

export default LoginScreen;
