import PropTypes from 'prop-types';

const AccordionItem = ({ children }) => children;

AccordionItem.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

AccordionItem.defaultProps = {
  title: null,
  subtitle: null,
};

export default AccordionItem;
