import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMsal } from '@azure/msal-react';
import { logout } from '../../../store/actions/auth';
import MenuIcon from '../../../assets/images/menu.svg';
import Dropdown from '../../atoms/Dropdown/Dropdown';
import OutsideAlerter from '../../../helpers/components/OutsideAlerter';
import IconButton from '../../atoms/IconButton/IconButton';
import QuestionMark from '../../../assets/images/circle-question.svg';
import classes from './NavBar.module.css';

const LOGOUT_OPTION = 'logout';

const NavBar = ({ routes }) => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isNavOpen, setIsNavOpen] = useState(false);

  const user = useSelector((state) => state.auth.user);

  const options = [{ label: t('logout'), value: LOGOUT_OPTION }];

  const logoutHandler = async () => {
    const currentAccount = instance.getAllAccounts()[0];

    await instance.logoutPopup({
      account: currentAccount,
      mainWindowRedirectUri: '/',
      postLogoutRedirectUri: '/',
    });

    dispatch(logout());
  };

  const onDropdownChange = (option) => {
    switch (option?.value) {
      case LOGOUT_OPTION:
        logoutHandler();

        break;
      default:
        break;
    }
  };

  const handleSupportRoute = useCallback(() => {
    navigate(routes.SUPPORT.path);
  }, [navigate, routes]);

  const handleHomepageRoute = useCallback(() => {
    navigate(routes.DASHBOARD.path);
  }, [navigate, routes]);

  /**
   * Toggles the navigation menu by clicking the
   * hamburger button.
   */
  const onClickNavigation = () => {
    setIsNavOpen(!isNavOpen);
  };

  /**
   * Closes the navigation menu.
   */
  const closeNavigation = () => {
    setIsNavOpen(false);
  };

  /**
   * Navigates to the route that was selected,
   * and closes the navigation menu.
   *
   * @param {*} route
   */
  const onNavigationHandle = (route) => {
    navigate(route);
    setIsNavOpen(false);
  };

  return (
    <header className={classes.NavBar}>
      <div className={classes.NavBar__container}>
        <div>
          {/* Navigation Menu */}
          <div className={classes.NavMenu}>
            <OutsideAlerter callback={closeNavigation}>
              <div className={classes.NavMenu__trigger}>
                <IconButton
                  icon={MenuIcon}
                  type="button"
                  onClick={onClickNavigation}
                  alt="nav-menu-icon"
                />

                {/* Navigation Menu Items */}
                <div
                  className={`${classes.NavMenu__dropdown} ${
                    !isNavOpen && classes.NavMenu__dropdown__closed
                  }`}
                >
                  <ul className={classes.NavDropdown}>
                    <li>
                      <button
                        type="button"
                        onClick={() =>
                          onNavigationHandle(routes.DASHBOARD.path)
                        }
                      >
                        {t('navbar-nav-episodes')}
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        onClick={() =>
                          onNavigationHandle(routes.PATHOLOGY_LIST.path)
                        }
                      >
                        {t('navbar-nav-pathologies')}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </OutsideAlerter>

            {/* Navigation title */}
            <button
              type="button"
              onClick={handleHomepageRoute}
              className={classes.NavBar__title}
            >
              {t('navbar-title')}
            </button>
          </div>
        </div>
        {/* Navigation dropdown to Logout */}
        <nav className={classes.NavBar__navigation}>
          {user && (
            <>
              <button
                type="button"
                className={classes.SupportIcon}
                onClick={() => handleSupportRoute()}
              >
                <img src={QuestionMark} alt="support" width={20} />
              </button>
              <Dropdown
                options={options}
                placeholder={user.role}
                onChange={onDropdownChange}
              />
            </>
          )}
        </nav>
      </div>
    </header>
  );
};

NavBar.propTypes = {
  routes: PropTypes.shape({
    path: PropTypes.string,
    property: PropTypes.string,
    render: PropTypes.func,
  }).isRequired,
};

export default NavBar;
