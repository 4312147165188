import React, { useState, useEffect } from 'react';
import moment from 'moment/moment';
import Color from 'color';
import {
  ResponsiveContainer,
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  Area,
  Text,
  Tooltip,
  Bar,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import ChartTooltip from './ChartTooltip/ChartTooltip';
import ChartLegend from './ChartLegend/ChartLegend';
import classes from './ChartPanel.module.css';
import { GRAPH_TYPES } from '../../../../../constants';

/**
 * Sub-component for the label displayed on hovering the chart.
 * The purpose of this sub-component is to add some padding at the bottom
 * of the label, to match the proposed design.
 */
const CustomLabel = (props) => {
  const { value, y, ...rest } = props;
  return (
    <Text {...rest} y={y - 10} textAnchor="middle">
      {value !== 0 ? value : ''}
    </Text>
  );
};

const ChartPanel = ({
  episodes,
  parametersSelected,
  selectedReferences,
  setSelectedReferences,
}) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState(null);
  const [parameters, setParameters] = useState(null);

  /**
   * This section builds the data structure ("chartData") that will
   * feed the chart.
   */
  useEffect(() => {
    if (episodes && episodes?.length > 0) {
      /**
       * List of all parameters that will be rendered.
       */
      let parameters = [];

      /**
       * Data to be used to render the chart.
       */
      const data = [];

      /**
       * This object will store the minimum and maximum values
       * for every displayed parameter on the chart.
       *
       * Its purpose is to be used as Line charts to display
       * said reference values.
       *
       * It will then be added to the data that will feed the chart,
       * both at the start and at the end of that data list, so it makes
       * full horizontal lines across the chart.
       */
      const minMaxRef = {};

      episodes?.forEach((episode) => {
        /**
         * Episode's entry point on the chart.
         */
        const episodeValues = {
          /**
           * The "dateString will be used as the X-axis."
           */
          dateString: moment(episode.date).format('DD-MM-YYYY'),
          status: episode?.status,
        };

        episode?.episodeParameters?.forEach((analysisItem) => {
          /**
           * Adds the parameter value to the episode's entry point on the chart.
           */
          episodeValues[analysisItem.parameterCode] =
            +analysisItem.parameterResult[0].resultA;

          /**
           * Adds a property telling wether the parameter is at risk
           * in this episode.
           */
          episodeValues[`${analysisItem.parameterCode}_isAtRisk`] =
            analysisItem.isAtRisk;

          /**
           * Checks if the parameter exists.
           */
          const existingParameter = parameters.find(
            (parameter) => parameter.code === analysisItem.parameterCode,
          );

          /**
           * If the parameter does exist, check if the
           * result of the currently looping clinical episode is higher
           * than the one already set as maximum value for that parameter,
           * and if so, sets it as the new maximum value.
           */
          if (existingParameter) {
            if (
              existingParameter.maxResult <
              analysisItem.parameterResult[0].resultA
            ) {
              parameters = parameters.map((parameter) => {
                if (parameter.code === existingParameter.parameterCode) {
                  return {
                    ...parameter,
                    maxResult: analysisItem.parameterResult[0].resultA,
                  };
                }

                return parameter;
              });
            }

            return;
          }

          /**
           * Adds the minimum and maximum reference values of each parameter
           * with the title of "_minRef-[parameter-id]" and
           * "_maxRef-[parameter-id]", so that it can be used to display
           * the reference lines in the chart.
           */
          minMaxRef[`_maxRef-${analysisItem.parameterCode}`] =
            +analysisItem.maxRefValue;
          minMaxRef[`_minRef-${analysisItem.parameterCode}`] =
            +analysisItem.minRefValue;

          /**
           * Adds the entry parameter information to the displayed parameters
           * list.
           */
          parameters.push({
            code: analysisItem.parameterCode,
            label: analysisItem.parameterName,
            maxResult: analysisItem.parameterResult[0].resultA,
            maxRefVal: analysisItem.maxRefValue,
            minRefVal: analysisItem.minRefValue,
            units: analysisItem.unit,
          });
        });

        /**
         * Finally adds the episode's data to the data structure
         * that will feed the chart.
         */
        data.push(episodeValues);
      });

      /**
       * Sorts the parameters so that it renders the one
       * with the lowest maximum result in the front of the rest parameters.
       */
      parameters = parameters.sort((a, b) =>
        a.maxResult < b.maxResult ? 1 : -1,
      );

      setParameters(parameters);
      setChartData([minMaxRef, ...data, minMaxRef]);
    } else {
      setParameters([]);
      setChartData([]);
    }
  }, [episodes, setParameters, setChartData]);

  return (
    !!chartData &&
    !!parameters && (
      <div className={classes.ChartPanel}>
        {chartData?.length > 0 && parameters?.length > 0 ? (
          <ResponsiveContainer height={380} width="100%">
            <ComposedChart data={chartData}>
              <XAxis dataKey="dateString" fontSize={12} />
              <YAxis
                fontSize={12}
                domain={[0, 'dataMax + 20']}
                allowDataOverflow={false}
              />
              <CartesianGrid stroke="#ddd" vertical={false} />
              <Tooltip content={<ChartTooltip parameters={parameters} />} />

              {/* Loops all parameters and shows them as charts with the
              values of the results on that clinical episode. */}
              {parameters.map((param) => (
                <React.Fragment key={param.code}>
                  {parametersSelected.find((paramSelected) =>
                    paramSelected.codes.includes(param.code),
                  )?.graph === GRAPH_TYPES.bar && (
                    <Bar
                      hide={
                        !parametersSelected.find((paramSelected) =>
                          paramSelected.codes.includes(param.code),
                        )
                      }
                      key={param.code}
                      dataKey={param.code}
                      barSize={20}
                      fill={
                        parametersSelected.find((paramSelected) =>
                          paramSelected.codes.includes(param.code),
                        )?.color
                      }
                    />
                  )}

                  {/* Area Chart */}
                  {parametersSelected.find((paramSelected) =>
                    paramSelected.codes.includes(param.code),
                  )?.graph === GRAPH_TYPES.area && (
                    <Area
                      hide={
                        !parametersSelected.find((paramSelected) =>
                          paramSelected.codes.includes(param.code),
                        )
                      }
                      key={param.code}
                      activeDot={false}
                      connectNulls
                      baseLine={8}
                      points={[{ x: 12, y: 12, value: 240 }]}
                      strokeOpacity={0}
                      type="linear"
                      dataKey={param.code}
                      fill={
                        parametersSelected.find((paramSelected) =>
                          paramSelected.codes.includes(param.code),
                        )?.color
                      }
                      label={<CustomLabel />}
                    />
                  )}
                </React.Fragment>
              ))}

              {/* Loops all the parameters, checks if they are selected, and
              if so, shows its maximum and/or minimum reference value as
              Line charts.

              TODO: Case for when it's only a static reference value. */}
              {parameters.map((param) => (
                <React.Fragment key={`refs_${param.code}`}>
                  {Object.prototype.hasOwnProperty.call(
                    chartData[0],
                    `_maxRef-${param.code}`,
                  ) && (
                    /**
                     * Maximum reference value Line chart.
                     */
                    <Line
                      hide={!selectedReferences.includes(param.code)}
                      key={`${param.code}_max-ref`}
                      connectNulls
                      type="linear"
                      dataKey={`_maxRef-${param.code}`}
                      stroke={new Color(
                        parametersSelected.find((paramSelected) =>
                          paramSelected.codes.includes(param.code),
                        )?.color,
                      ).darken(0.33)}
                      strokeWidth={2}
                      dot={false}
                    />
                  )}
                  {Object.prototype.hasOwnProperty.call(
                    chartData[0],
                    `_minRef-${param.code}`,
                  ) && (
                    /**
                     * Minimum reference value Line chart.
                     */
                    <Line
                      hide={!selectedReferences.includes(param.code)}
                      key={`${param.code}_min-ref`}
                      connectNulls
                      type="linear"
                      dataKey={`_minRef-${param.code}`}
                      stroke={new Color(
                        parametersSelected.find((paramSelected) =>
                          paramSelected.codes.includes(param.code),
                        )?.color,
                      ).darken(0.33)}
                      strokeWidth={2}
                      dot={false}
                    />
                  )}
                </React.Fragment>
              ))}
            </ComposedChart>
          </ResponsiveContainer>
        ) : (
          <div className={classes.ChartNoData}>
            <span className={classes.ChartNoDataTitle}>
              {t('no-data-found-title')}
            </span>
            <span>{t('no-data-found-subtitle')}</span>
          </div>
        )}

        {/* Section below the chart with the legend of each shown parameter
      and also with the checkbox to show/hide the reference values for
      any of those parameters */}
        {parameters && parameters.length > 0 && (
          <ChartLegend
            parameters={parameters}
            parametersSelected={parametersSelected}
            selectedReferences={selectedReferences}
            setSelectedReferences={setSelectedReferences}
          />
        )}
      </div>
    )
  );
};

export default ChartPanel;
