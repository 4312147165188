import React, { useEffect } from 'react';
import CaretDownGray from '../../../assets/images/caret-down-gray.svg';
import CaretUpActive from '../../../assets/images/caret-up-active.svg';
import classes from './Accordion.module.css';

const Accordion = ({
  placeholderTitle,
  forcedActive,
  children,
  active,
  setActive,
}) => {
  let toRender = children;
  if (!Array.isArray(toRender)) {
    toRender = [toRender];
  }

  const onChangeActive = (i) => {
    if (i === active) {
      setActive(null);
      return;
    }

    setActive(i);
  };

  useEffect(() => {
    setActive(
      forcedActive !== null || forcedActive !== undefined ? forcedActive : null,
    );
  }, [forcedActive]);

  return (
    <div className={classes.Accordion}>
      {toRender.map((element, i) => (
        <div
          className={`${classes.AccordionItem} ${
            i === active && classes.Active
          }`}
          key={i}
        >
          <button type="button" onClick={() => onChangeActive(i)}>
            <div className={classes.Titles}>
              {element?.props?.title ? (
                <>
                  {typeof element.props.title === 'string' && (
                    <span>{element.props.title}</span>
                  )}
                  {typeof element.props.title === 'object' &&
                    element.props.title}
                </>
              ) : (
                <span>{placeholderTitle}</span>
              )}

              {element?.props?.subtitle &&
                typeof element?.props?.subtitle === 'string' && (
                  <span>{element?.props?.subtitle}</span>
                )}
              {element?.props?.subtitle &&
                typeof element?.props?.subtitle === 'object' &&
                element?.props?.subtitle}
            </div>
            <img
              src={i === active ? CaretUpActive : CaretDownGray}
              alt="caret-down-gray"
            />
          </button>
          <div
            className={classes.AccordionItemContent}
            style={i === active ? {} : { display: 'none' }}
          >
            {element}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
