import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import Button from '../../atoms/Button/Button';
import Table from '../../molecules/Table/Table';
import ClinicalEpisodeStatus from './ClinicalEpisodeStatus/ClinicalEpisodeStatus';
import { getClinicalEpisodes } from '../../../store/actions/clinicalEpisodes';
import captions from './captions';
import classes from './ClinicalEpisodeTable.module.css';
import 'react-toastify/dist/ReactToastify.css';

const ClinicalEpisodeTable = ({ routes }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [sort, setSort] = useState({ direction: 'DESC', property: 'date' });
  const [filter, setFilter] = useState([]);

  /**
   * List of clinical episodes that are already loaded.
   */
  const episodes = useSelector(
    (state) => state.clinicalEpisodes.clinicalEpisodes,
  );

  /**
   * Current "page" loaded from the database.
   */
  const page = useSelector((state) => state.clinicalEpisodes.page);

  /**
   * Flag for when the request is being processed.
   */
  const isFetching = useSelector((state) => state.clinicalEpisodes.isFetching);

  /**
   * Any errors regarding the API.
   */
  const error = useSelector((state) => state.clinicalEpisodes.error);

  /**
   * Flag that is true if the last batch of data is already loaded.
   */
  const isLastPageLoaded = useSelector(
    (state) => state.clinicalEpisodes.isLastPageLoaded,
  );

  const fetchInitialData = useCallback(() => {
    dispatch(
      getClinicalEpisodes(
        process.env.REACT_APP_TABLE_PER_PAGE,
        1,
        sort,
        filter,
      ),
    );
  }, [sort, filter]);

  /**
   * Fetching the data to fill the clinical episode's table.
   */
  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  /**
   * Sends a notification if an error related
   * to the API occurs.
   */
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  /**
   * Handler for when a filter is changed.
   */
  const onChangeFilter = (property, value) => {
    let rawValue = value;

    if (property === 'date') {
      /**
       * Handling the date filter.
       */
      rawValue = value;
    } else if (
      typeof value === 'object' &&
      Object.prototype.hasOwnProperty.call(value, 'value')
    ) {
      /**
       * Handling the dropdown filter.
       */
      rawValue = value?.value;
    } else {
      /**
       * Handling the general input filter.
       */
      rawValue = value?.target?.value;
    }

    let newFilter = [...filter];

    /**
     * Gets the index of the current property (column) filter being changed.
     */
    const index = filter?.findIndex((f) => f.property === property);

    if (index === null || index === undefined) {
      return;
    }

    if (index === -1) {
      /**
       * Filter was not set yet, so this section will add it.
       */
      newFilter.push({ property, value: rawValue });
      setFilter(newFilter);
      return;
    }

    if (rawValue === '' || rawValue === null || rawValue === undefined) {
      /**
       * Filter is being unset, so this section will remove that filter
       * from the array.
       */
      newFilter = newFilter.filter((f) => f.property !== property);
      setFilter(newFilter);
      return;
    }

    /**
     * Changes the filter's value.
     */
    newFilter[index] = { property, value: rawValue };
    setFilter(newFilter);
  };

  /**
   * Column definition for the Clinical Episode table
   */
  const columns = [
    {
      label: t('dashboard-column-patient'),
      property: 'patientCode',
      render: (_, row) => (
        <button
          className={classes.NavigateButton}
          type="button"
          onClick={() =>
            navigate(routes.PATIENT_ANALYSIS.path.replace(':id', row.patientId))
          }
        >
          {row.patientCode}
        </button>
      ),
      filter: (value) => onChangeFilter('patientCode', value),
      filterType: 'debounce',
    },
    {
      label: t('dashboard-column-episode'),
      property: 'appoloId',
      render: (_, row) => (
        <>
          {row.appoloId}
          {row.appoloRefId && ` (Appolo Ref ID: ${row.appoloRefId})`}
        </>
      ),
      filter: (value) => onChangeFilter('appoloId', value),
      filterType: 'text',
    },
    {
      label: t('dashboard-column-date'),
      property: 'date',
      render: (entry) => moment(entry).format('DD-MM-YYYY'),
      filter: (value) => onChangeFilter('date', value),
      filterType: 'date',
    },
    {
      label: t('dashboard-column-status'),
      property: 'status',
      render: (entry) => <ClinicalEpisodeStatus statusId={entry} />,
      filter: (value) => onChangeFilter('status', value),
      filterType: 'dropdown',
      filterDropdownOptions: [
        { label: t('dashboard-filter-status-none'), value: null },
        { label: t('status-manually-validated'), value: 'manually-validated' },
        {
          label: t('status-automatically-validated'),
          value: 'automatically-validated',
        },
        { label: t('status-risk-detected'), value: 'risk-detected' },
        { label: t('status-no-info'), value: 'no-info' },
      ],
    },
  ];

  return (
    <div className={classes.ClinicalEpisodeTable}>
      <Table
        columns={columns}
        content={episodes}
        sortable
        sort={sort}
        setSort={setSort}
        filterable
        filter={filter}
        setFilter={setFilter}
        captions={captions}
        captionListTop
        captionsTextBreakLine
        loading={isFetching}
        onChangeFilter={onChangeFilter}
        canRefresh
        onRefresh={fetchInitialData}
      />

      <div className={classes.Footer}>
        <Button
          blank
          disabled={isFetching || isLastPageLoaded}
          onClick={() =>
            dispatch(
              getClinicalEpisodes(
                process.env.REACT_APP_TABLE_PER_PAGE,
                page + 1,
                sort,
                filter,
              ),
            )
          }
        >
          <ClipLoader
            cssOverride={{ marginRight: '8px' }}
            loading={isFetching}
            size={16}
            color="#ffffff"
          />
          {t('button-load-more')}
        </Button>
      </div>

      <ToastContainer pauseOnHover />
    </div>
  );
};

export default ClinicalEpisodeTable;
