import React from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../../molecules/Table/Table';
import { CLINICAL_EPISODES_STATUSES } from '../../../../../constants';
import classes from './AnalysisTable.module.css';

/**
 * Table column's render function to format
 * the analysis reference values.
 */
const formatAnalysisReference = (_, row, t) => {
  if (row.refValue) {
    return row.refValue;
  }

  let referenceValue = '';

  if (row.minRefValue && row.maxRefValue) {
    referenceValue = `${row.minRefValue} ${t('to')} ${row.maxRefValue}`;
  }

  if (row.minRefValue && !row.maxRefValue) {
    referenceValue = `≥ ${row.minRefValue}`;
  }

  if (!row.minRefValue && row.maxRefValue) {
    referenceValue = `≤ ${row.maxRefValue}`;
  }

  return row.unit ? `${referenceValue} ${row.unit}` : referenceValue;
};

/**
 * Table column's render function to format
 * the analysis' result.
 */
const formatAnalysisResult = (_, row) => {
  if (!row.parameterResult || row.parameterResult.length < 0) {
    return '-';
  }

  const parameterResult = row.parameterResult[0];

  // absolute value
  let resultFormatted = parameterResult.resultA
    ? parameterResult.unitA
      ? `${parameterResult.resultA} ${parameterResult.unitA}`
      : `${parameterResult.resultA}`
    : '-';

  // relative value
  if (parameterResult.resultR) {
    resultFormatted = `${parameterResult.resultR}`;
  }

  if (row.isAtRisk) {
    return <span className={classes.DangerResult}>{resultFormatted}</span>;
  }

  return resultFormatted;
};

const AnalysisTable = ({ analysis }) => {
  const { t } = useTranslation();

  const riskDetectedInfo = CLINICAL_EPISODES_STATUSES['risk-detected'];

  const columns = [
    {
      label: t('patient-analysis-table-parameter'),
      property: 'name',
      render: (_, row) =>
        row.isAtRisk ? (
          /**
           * Renders the parameter's name with an alert circle
           */
          <div className={classes.DangerTitle}>
            <div className={classes.DangerTitleCircle}>
              <img src={riskDetectedInfo.icon} alt="risk-detected" />
            </div>
            <span>{row.parameterName}</span>
          </div>
        ) : (
          row.parameterName
        ),
    },
    { label: t('patient-analysis-table-code'), property: 'parameterCode' },
    {
      label: t('patient-analysis-table-ref-values'),
      render: (_, row) => formatAnalysisReference(_, row, t),
    },
    {
      label: t('patient-analysis-table-result'),
      render: formatAnalysisResult,
    },
  ];

  return (
    <div className={classes.AnalysisTable}>
      <Table columns={columns} content={analysis} />
    </div>
  );
};

export default AnalysisTable;
