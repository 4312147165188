import React from 'react';
import PropTypes from 'prop-types';

import classes from './Textarea.module.css';

const Textarea = ({ children, ...args }) => (
  <div className={classes.Textarea}>
    <textarea {...args}>{children}</textarea>
  </div>
);

Textarea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
};

Textarea.defaultProps = {
  id: null,
  name: null,
  placeholder: null,
  disabled: false,
  onChange: null,
  onKeyUp: null,
};

export default Textarea;