import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import NavBar from '../../components/molecules/NavBar/NavBar';
import Container from '../../components/atoms/Container/Container';
import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import PathologyDetails from '../../components/organisms/PathologyDetails/PathologyDetails';
import { getPathologyDetail } from '../../store/actions/pathologies';
import 'react-toastify/dist/ReactToastify.css';

const PathologyDetailScreen = ({ routes }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();

  /**
   * Fetches the pathology that corresponds to the id in the route
   * parameters, ie, in the URL.
   */
  useEffect(() => {
    if (id) {
      dispatch(getPathologyDetail(id));
    }
  }, [id]);

  /**
   * Pathology currently selected.
   */
  const pathology = useSelector((state) => state.pathologies.pathologySelected);

  /**
   * Success messages regarding the API.
   */
  const success = useSelector((state) => state.pathologies.success);

  /**
   * Errors regarding the API.
   */
  const error = useSelector((state) => state.pathologies.error);

  /**
   * Sends a notification if a success message related
   * to the API occurs.
   */
  useEffect(() => {
    if (success) {
      toast.success(success);
    }
  }, [success]);

  /**
   * Sends a notification if an error related
   * to the API occurs.
   */
  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <>
      <NavBar routes={routes} />
      <TitleHeader
        title={`${t('pathology-detail-title')} ${pathology ? '>' : ''} ${
          pathology?.name || ''
        }`.trim()}
      />
      <Container>
        <PathologyDetails pathology={pathology} routes={routes} />
        <ToastContainer pauseOnHover />
      </Container>
    </>
  );
};

export default PathologyDetailScreen;
