import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import Tabs from '../../atoms/Tabs/Tabs';
import TabItem from '../../atoms/Tabs/TabItem/TabItem';
import PathologyForm from './PathologyForm/PathologyForm';
import PathologyHistory from './PathologyHistory/PathologyHistory';
import PathologyInfoIcon from '../../../assets/images/pathology-info.svg';
import PathologyInfoIconInactive from '../../../assets/images/pathology-info-inactive.svg';
import PathologyHistoryIcon from '../../../assets/images/pathology-history.svg';
import PathologyHistoryIconInactive from '../../../assets/images/pathology-history-inactive.svg';
import { getPathologyLastUpdatedAndUser } from '../../../helpers';
import { GET_PATHOLOGY_DETAIL_START } from '../../../store/actions/types';
import classes from './PathologyDetails.module.css';

const PathologyDetails = ({ pathology, routes }) => {
  const { t } = useTranslation();

  /**
   * Flag for when the request is being processed.
   */
  const isFetching = useSelector((state) => state.pathologies.isFetching);

  /**
   * Which request is actually pending.
   */
  const fetchingAction = useSelector(
    (state) => state.pathologies.fetchingAction,
  );

  return (
    <Tabs subtitle={getPathologyLastUpdatedAndUser(t, pathology)}>
      <TabItem
        title={t('pathology-detail-tab-info')}
        icon={PathologyInfoIcon}
        inactiveIcon={PathologyInfoIconInactive}
      >
        {isFetching && fetchingAction === GET_PATHOLOGY_DETAIL_START ? (
          <div className={classes.FetchingPanel}>
            <BeatLoader
              size={12}
              color="#8A2432"
              loading={
                isFetching && fetchingAction === GET_PATHOLOGY_DETAIL_START
              }
            />
          </div>
        ) : (
          <PathologyForm routes={routes} pathology={pathology} />
        )}
      </TabItem>

      {/* Only show the history tab if the pathology already exists */}
      {pathology?.pathologyId && (
        <TabItem
          title={t('pathology-detail-tab-history')}
          icon={PathologyHistoryIcon}
          inactiveIcon={PathologyHistoryIconInactive}
        >
          <PathologyHistory pathology={pathology} />
        </TabItem>
      )}
    </Tabs>
  );
};

export default PathologyDetails;
