import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classes from './Tabs.module.css';

const Tabs = ({ subtitle, fill, children }) => {
  const [active, setActive] = useState(0);

  let toRender = children;
  if (!Array.isArray(toRender)) {
    toRender = [toRender];
  }

  const items = toRender
    .filter((element) => element !== undefined)
    .map((element) => ({
      title: element?.props?.title,
      icon: element?.props?.icon,
      inactiveIcon: element?.props?.inactiveIcon
    }));

  return (
    <div className={`${classes.Tabs} ${fill && classes.Fill}`}>
      <div className={classes.TabsHeader}>
        <div className={classes.TabsHeaderList}>
          {items.map((item, i) => (
            <button
              className={`${classes.TabsHeaderItem} ${
                i === active && classes.Active
              }`}
              type="button"
              key={i}
              onClick={() => setActive(i)}
            >
              {item?.icon && (
                <img
                  src={i === active ? item?.icon : item?.inactiveIcon}
                  alt={item?.title}
                />
              )}
              {item?.title || 'No Title'}
            </button>
          ))}
        </div>
        {typeof subtitle === 'string' ? (
          <span className={classes.TabsSubtitle}>{subtitle}</span>
        ) : (
          subtitle
        )}
      </div>
      <div className={classes.TabsBody}>
        {typeof toRender[active] !== 'undefined' && toRender[active]}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  subtitle: PropTypes.oneOfType([
    PropTypes.string, 
    PropTypes.element
  ]),
  fill: PropTypes.bool,
};

Tabs.defaultProps = {
  subtitle: null,
  fill: false
};

export default Tabs;
