import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import IconButton from '../../../../../atoms/IconButton/IconButton';
import CustomTooltip from '../../../../../atoms/CustomTooltip/CustomTooltip';
import ActionSaveIcon from '../../../../../../assets/images/action-save.svg';
import ActionCopyIcon from '../../../../../../assets/images/action-copy.svg';
import ActionDeleteIcon from '../../../../../../assets/images/action-delete.svg';
import ActionCancelIcon from '../../../../../../assets/images/action-cancel.svg';
import { ANALYSIS_FORMAT } from '../../../../../../constants';
import AnalysisBasic from './AnalysisBasic/AnalysisBasic';
import AnalysisPatient from './AnalysisPatient/AnalysisPatient';
import AnalysisFormat from './AnalysisFormat/AnalysisFormat';
import SingleReference from './AnalysisReference/SingleReference/SingleReference';
import IntervalReference from './AnalysisReference/IntervalReference/IntervalReference';
import AnalysisRiskThreshold from './AnalysisRiskThreshold/AnalysisRiskThreshold';
import { POST_PATHOLOGY_VERSION_DETAIL_START } from '../../../../../../store/actions/types';
import { getAnalysisFormatFromAnalysis } from '../../../../../../helpers';
import AnalysisVariations from './AnalysisVariations/AnalysisVariations';
import classes from './PathologyAnalysis.module.css';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';

const PathologyAnalysis = (props) => {
  const {
    parameterTypes,
    analysisTypes,
    analysis,
    readonly,
    onCloneAnalysisOfList,
    onRemoveAnalysisFromList,
    onSaveAnalysis,
    index,
  } = props;
  const { t } = useTranslation();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [missingMandatoryFields, setMissingFields] = useState(null);

  /**
   * Flag for when the request is being processed.
   */
  const isFetching = useSelector((state) => state.pathologies.isFetching);

  /**
   * Which request is actually pending.
   */
  const fetchingAction = useSelector(
    (state) => state.pathologies.fetchingAction,
  );

  const [analysisFormat, setAnalysisFormat] = useState(
    analysis?.format || getAnalysisFormatFromAnalysis(analysis),
  );

  const [analysisInfo, setAnalysisInfo] = useState({
    parameterTypeId: analysis?.parameterTypeId,
    analysisTypeId: analysis?.analysisTypeId,
    message: analysis?.message,
    isForPregnants: analysis?.isForPregnants || false,
    minAge: analysis?.minAge,
    maxAge: analysis?.maxAge,
    gender: analysis?.gender,
    refValue: analysis?.refValue,
    minRefValue: analysis?.minRefValue,
    maxRefValue: analysis?.maxRefValue,
    unit: analysis?.unit || '',
    checkParameterHistory: analysis?.checkParameterHistory || false,
    timeDelta: analysis?.timeDelta,
    refValueDelta: analysis?.refValueDelta,
    lowRiskPct: analysis?.lowRiskPct,
    medRiskPct: analysis?.medRiskPct,
    highRiskPct: analysis?.highRiskPct,
  });

  const [ageValidationError, setAgeValidationError] = useState(null);
  const [riskValidationError, setRiskValidationError] = useState(null);
  const [refValuesValidationError, setRefValuesValidationError] =
    useState(null);
  const [validationError, setValidationError] = useState(null);

  const changeAnalysisFormat = (format) => {
    setAnalysisFormat(format);

    if (format === ANALYSIS_FORMAT.single.id) {
      setAnalysisInfo({
        ...analysisInfo,
        minRefValue: null,
        maxRefValue: null,
        unit: null,
      });
    } else if (format === ANALYSIS_FORMAT.interval.id) {
      setAnalysisInfo({
        ...analysisInfo,
        refValue: null,
      });
    }
  };

  useEffect(() => {
    if (ageValidationError || refValuesValidationError || riskValidationError) {
      setValidationError(true);
    } else {
      setValidationError(false);
    }
  }, [ageValidationError, refValuesValidationError, riskValidationError]);

  const onOpenConfirmationModal = () => {
    setIsConfirmationModalOpen(true);
  };

  const onCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  return (
    <>
      <ConfirmationModal
        toggle={isConfirmationModalOpen}
        onClose={onCloseConfirmationModal}
        onConfirm={() => {
          onRemoveAnalysisFromList(index, true);
          onCloseConfirmationModal();
        }}
        header={t('remove-analysis-header')}
        message={t('remove-analysis-message')}
        confirmText={t('confirm-remove')}
        cancelText={t('cancel-remove')}
      />
      <div className={classes.PathologyAnalysis}>
        <div className={classes.DataPanel}>
          {/* Basic Info */}
          <AnalysisBasic
            parameterTypes={parameterTypes}
            analysisTypes={analysisTypes}
            readonly={readonly}
            analysisInfo={analysisInfo}
            setAnalysisInfo={setAnalysisInfo}
            missingMandatoryFields={missingMandatoryFields}
          />

          {/* Analysis Patient Info */}
          <AnalysisPatient
            readonly={readonly}
            analysisInfo={analysisInfo}
            setAnalysisInfo={setAnalysisInfo}
            setAgeValidationError={setAgeValidationError}
          />

          {/* Analysis Format Selection */}
          <AnalysisFormat
            readonly={readonly}
            analysisFormat={analysisFormat}
            setAnalysisFormat={changeAnalysisFormat}
          />

          {/* Single Reference Values */}
          {analysisFormat === ANALYSIS_FORMAT.single.id && (
            <SingleReference
              readonly={readonly}
              analysisInfo={analysisInfo}
              setAnalysisInfo={setAnalysisInfo}
            />
          )}

          {/* Interval Reference Values */}
          {analysisFormat === ANALYSIS_FORMAT.interval.id && (
            <IntervalReference
              readonly={readonly}
              analysisInfo={analysisInfo}
              setAnalysisInfo={setAnalysisInfo}
              setRefValuesValidationError={setRefValuesValidationError}
            />
          )}

          {/* Analysis Variations */}
          <AnalysisVariations
            readonly={readonly}
            analysisInfo={analysisInfo}
            setAnalysisInfo={setAnalysisInfo}
          />

          {/* Interval Reference Values Threshold */}
          <AnalysisRiskThreshold
            readonly={readonly}
            analysisInfo={analysisInfo}
            setAnalysisInfo={setAnalysisInfo}
            setRiskValidationError={setRiskValidationError}
          />
        </div>

        {/* Actions section */}
        {!readonly && (
          <div className={classes.ActionPanel}>
            {isFetching &&
            fetchingAction === POST_PATHOLOGY_VERSION_DETAIL_START ? (
              <div className={classes.ActionLoaderContainer}>
                <ClipLoader
                  cssOverride={{ marginRight: '8px' }}
                  loading={isFetching}
                  size={20}
                  color="var(--color-primary)"
                />
              </div>
            ) : (
              <CustomTooltip
                text={
                  validationError
                    ? t('save-associated-analysis-error')
                    : t('save-associated-analysis')
                }
              >
                <IconButton
                  icon={ActionSaveIcon}
                  blank
                  iconHeight={20}
                  alt="action-save"
                  disabled={validationError}
                  onClick={() =>
                    onSaveAnalysis(index, analysisInfo, setMissingFields)
                  }
                />
              </CustomTooltip>
            )}
            {analysis?.id ? (
              <>
                <CustomTooltip text={t('copy-associated-analysis')}>
                  <IconButton
                    icon={ActionCopyIcon}
                    blank
                    iconHeight={20}
                    alt="action-copy"
                    disabled={
                      isFetching &&
                      fetchingAction === POST_PATHOLOGY_VERSION_DETAIL_START
                    }
                    onClick={() => onCloneAnalysisOfList(analysis, index)}
                  />
                </CustomTooltip>
                <CustomTooltip text={t('delete-associated-analysis')}>
                  <IconButton
                    icon={ActionDeleteIcon}
                    blank
                    iconHeight={20}
                    alt="action-delete"
                    disabled={
                      isFetching &&
                      fetchingAction === POST_PATHOLOGY_VERSION_DETAIL_START
                    }
                    onClick={() => onOpenConfirmationModal()}
                  />
                </CustomTooltip>
              </>
            ) : (
              <CustomTooltip text={t('cancel-add-analysis')}>
                <IconButton
                  icon={ActionCancelIcon}
                  blank
                  iconHeight={20}
                  alt="action-cancel"
                  onClick={() => onRemoveAnalysisFromList(index)}
                />
              </CustomTooltip>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default PathologyAnalysis;
