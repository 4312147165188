import React from 'react';
import classes from './ParameterSelectionList.module.css';

const ParameterSelectionList = (props) => {
  const {
    parameters,
    parametersSelected,
    setParametersSelected,
    selectedReferences,
    setSelectedReferences,
  } = props;

  /**
   * Selects (or deselects if already selected) a parameter
   * to be marked as "selected", and such it will be displayed
   * in the episode's chart (via the prop method "setParametersSelected").
   */
  const onParameterToggled = (parameter) => {
    if (
      parametersSelected.find(
        (paramSelected) => paramSelected.id === parameter.id,
      )
    ) {
      const result = parametersSelected.filter(
        (paramSelected) => paramSelected.id !== parameter.id,
      );

      setParametersSelected(result);

      const references = selectedReferences.filter((reference) =>
        result.flat().includes(reference),
      );

      setSelectedReferences(references);
      return;
    }

    const result = [...parametersSelected];

    result.push({
      codes: parameter.parameterCodes,
      color: `#${parameter.hex}`,
      id: parameter.id,
      graph: parameter.graphType,
    });

    setParametersSelected(result);
  };

  const selectedIds = parametersSelected?.map(
    (paramSelected) => paramSelected.id,
  );

  return (
    <div className={classes.ParameterSelectionList}>
      {parameters &&
        parameters.length > 0 &&
        parameters.map((parameter, i) => (
          /**
           * Parameter List Item (with checkbox)
           */
          <div className={classes.ParameterItem} key={i}>
            <input
              type="checkbox"
              checked={selectedIds.includes(parameter.id)}
              onChange={() => onParameterToggled(parameter)}
            />
            <span>{parameter.parameterName}</span>
          </div>
        ))}
    </div>
  );
};

export default ParameterSelectionList;
