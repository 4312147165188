import React from 'react';
import Color from 'color';
import { CLINICAL_EPISODES_STATUSES } from '../../../../../../constants';
import classes from './ChartTooltip.module.css';

const ChartTooltip = (props) => {
  const { parameters, payload, label } = props;

  /**
   * Makes a reference values data structure for all
   * the parameters.
   */
  const referenceValues = {};
  for (let i = 0; i < parameters.length; i++) {
    referenceValues[parameters[i].code] = {
      minRefVal: parameters[i].minRefVal,
      maxRefVal: parameters[i].maxRefVal,
      units: parameters[i].units,
    };
  }

  /**
   * Formats the reference values in a string, based on reference values
   * structure.
   */
  const formatReferenceValues = (parameterId) => {
    const values = referenceValues[parameterId];

    if (!values?.minRefVal && !values?.maxRefVal) {
      return undefined;
    }

    if (values?.minRefVal && !values?.maxRefVal) {
      return `(≥ ${values?.minRefVal} ${values?.units})`;
    }

    if (!values?.minRefVal && values?.maxRefVal) {
      return `(≤ ${values?.maxRefVal} ${values?.units})`;
    }

    return `(${values?.minRefVal} - ${values?.maxRefVal} ${values?.units})`;
  };

  const status = payload?.length > 0 ? payload[0]?.payload?.status : undefined;

  return (
    <div className={classes.ChartTooltip}>
      {/* Date of the chart point. */}
      <div className={classes.TitleWrapper}>
        <span>{label}</span>
      </div>

      {payload && (
        /**
         * List of parameters in question for this chart point.
         */
        <div className={classes.ParameterList}>
          {payload.map((param) => (
            <div key={param.dataKey} className={classes.Parameter}>
              {/* Name and color of the parameter */}
              <div className={classes.ParameterTitle}>
                <div
                  className={classes.ParameterColorSquare}
                  style={{
                    backgroundColor: new Color(param.fill).lighten(0.33),
                  }}
                >
                  <div
                    className={classes.ParameterColorLine}
                    style={{
                      backgroundColor: new Color(param.fill).darken(0.33),
                    }}
                  />
                </div>
                <span
                  className={
                    param.payload[`${param.dataKey}_isAtRisk`]
                      ? classes.Danger
                      : undefined
                  }
                >
                  {parameters.find((p) => p.code === param.dataKey)?.label}
                </span>
              </div>

              {/* Result value for this parameters, along with
              the correspondent reference values */}
              <div className={classes.ParameterData}>
                <span
                  className={`${classes.ParameterValue} ${
                    param.payload[`${param.dataKey}_isAtRisk`] && classes.Danger
                  }`.trim()}
                >
                  {param.value}
                </span>
                <span className={classes.ParameterReferences}>
                  {formatReferenceValues(param.dataKey)}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Status of the episode of the chart point in question */}
      {status && (
        <div className={classes.Status}>
          <span style={{ color: CLINICAL_EPISODES_STATUSES[status].color }}>
            {CLINICAL_EPISODES_STATUSES[status].label}
          </span>
        </div>
      )}
    </div>
  );
};

export default ChartTooltip;
