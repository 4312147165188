import { AUTH_SUCCESS, LOGOUT_SUCCESS } from '../actions/types';

/**
 * @property user   Stores the user information from the logged in user.
 * @property error   Will be set to any error that may be incoming.
 */
const initialState = {
  user: null,
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    /**
     * Case for when the user successfully logs in.
     */
    case AUTH_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    /**
     * Case for when a logout is being performed.
     */
    case LOGOUT_SUCCESS:
      return {
        ...initialState,
        user: null,
      };
    default:
      return state;
  }
}

export default authReducer;
