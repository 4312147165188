import i18next from '../i18n';
import StatusManuallyValidatedIcon from '../assets/images/status-validated.svg';
import StatusAutomaticallyValidatedIcon from '../assets/images/status-ai.svg';
import StatusRiskDetectedIcon from '../assets/images/status-risk-detected.svg';
import StatusNoInformationIcon from '../assets/images/status-no-info.svg';
import StatusPediatricSampleIcon from '../assets/images/status-pediatric-sample.svg';

/**
 * Common Status Labels
 */
export const SUCCESS = 'success';
export const ERROR = 'error';

/**
 * HTTP Response Codes
 */
export const HTTP_STATUSES = {
  SUCCESS: 200,
  SUCCESS_CREATED: 201,
  SUCCESS_ACCEPTED: 202,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  SERVER_ERROR: 500,
};

/**
 * Roles definitions.
 */
export const ROLES = {
  ADMIN: 'Admin',
  DOCTOR: 'Medical',
  DIRECTOR: 'Manager',
};

/**
 * Clinical Episodes Statuses information.
 */
export const CLINICAL_EPISODES_STATUSES = {
  'manually-validated': {
    id: 'manually-validated',
    label: i18next.t('status-manually-validated'),
    color: '#3ADF6C',
    icon: StatusManuallyValidatedIcon,
  },
  'automatically-validated': {
    id: 'automatically-validated',
    label: i18next.t('status-automatically-validated'),
    color: '#3393F3',
    icon: StatusAutomaticallyValidatedIcon,
  },
  'risk-detected': {
    id: 'risk-detected',
    label: i18next.t('status-risk-detected'),
    color: '#E62D46',
    icon: StatusRiskDetectedIcon,
  },
  'no-info': {
    id: 'no-info',
    label: i18next.t('status-no-info'),
    color: '#F9824D',
    icon: StatusNoInformationIcon,
  },
  'pediatric-sample': {
    id: 'pediatric-sample',
    label: i18next.t('status-pediatric-sample'),
    color: '#DE93FE',
    icon: StatusPediatricSampleIcon,
  },
};

/**
 * Genders definitions.
 */
export const GENDERS = {
  m: {
    id: 'male',
    label: i18next.t('gender-male'),
  },
  f: {
    id: 'female',
    label: i18next.t('gender-female'),
  },
  nd: {
    id: 'not disclosed',
    label: i18next.t('not-disclosed'),
  },
};

export const ANALYSIS_FORMAT = {
  single: {
    id: 'single-ref',
    label: i18next.t('analysis-format-single'),
  },
  interval: {
    id: 'interval-ref',
    label: i18next.t('analysis-format-interval'),
  },
};

export const GRAPH_TYPES = {
  area: 'AREA',
  bar: 'BAR',
};

export const SINGLE_REFERENCE_VALUES = {
  positive: {
    id: 'positive',
    label: i18next.t('analysis-single-reference-positive'),
  },
  negative: {
    id: 'negative',
    label: i18next.t('analysis-single-reference-negative'),
  },
};

export const ISSUE_TYPES = [
  {
    label: 'Technical Issue',
    value: 'Technical Issue',
  },
  {
    label: 'Data Issue',
    value: 'Data Issue',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];
