import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enGb from './enGb';
import ptPT from './ptPT';

const resources = {
  'en-GB': {
    translation: enGb,
  },
  'pt-PT': {
    translation: ptPT,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en-GB',
    debug: false,
  });

i18n.changeLanguage('en-GB');

export default i18n;
