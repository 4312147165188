import { msalInstance } from './index';
import { loginRequest, msalConfig } from './config';
import { ROLES } from '../../constants';

export async function getAuthToken() {
  const currentAccount = msalInstance.getAllAccounts()[0];
  const accessTokenRequest = {
    scopes: loginRequest.scopes,
    account: currentAccount,
  };

  if (currentAccount) {
    if (currentAccount.tenantId === msalConfig.auth.tenantId) {
      const roles = currentAccount.idTokenClaims.roles;

      if (roles) {
        const intersection = Object.values(ROLES).filter((role) =>
          roles.includes(role),
        );

        if (intersection.length > 0) {
          const accessTokenResponse = await msalInstance.acquireTokenSilent(
            accessTokenRequest,
          );

          return {
            bearerToken: `Bearer ${accessTokenResponse.idToken}`,
            accessToken: accessTokenResponse.accessToken,
          };
        }
      }
    }
    return null;
  }
}
