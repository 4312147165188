import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AnalysisSection from '../AnalysisSection/AnalysisSection';
import InputControl from '../../../../../../molecules/InputControl/InputControl';
import Dropdown from '../../../../../../atoms/Dropdown/Dropdown';
import Input from '../../../../../../atoms/Input/Input';
import Checkbox from '../../../../../../atoms/Checkbox/Checkbox';
import Readonly from '../../../../../../atoms/Readonly/Readonly';
import classes from './AnalysisPatient.module.css';

const AnalysisPatient = (props) => {
  const { readonly, analysisInfo, setAnalysisInfo, setAgeValidationError } =
    props;
  const { t } = useTranslation();
  const [ageOutOfOrder, setAgeOutOfOrder] = useState(null);
  const [negativeMinAge, setNegativeMinAge] = useState(null);
  const [negativeMaxAge, setNegativeMaxAge] = useState(null);

  useEffect(() => {
    if (analysisInfo?.gender === 'M') {
      setAnalysisInfo({
        ...analysisInfo,
        isForPregnants: false,
      });
    }
  }, [analysisInfo?.gender]);

  useEffect(() => {
    setAgeOutOfOrder(
      analysisInfo?.minAge &&
        analysisInfo?.maxAge &&
        +analysisInfo.minAge > +analysisInfo.maxAge,
    );
    setNegativeMinAge(analysisInfo?.minAge && +analysisInfo.minAge < 0);
    setNegativeMaxAge(analysisInfo?.maxAge && +analysisInfo.maxAge < 0);
  }, [analysisInfo?.minAge, analysisInfo?.maxAge]);

  useEffect(() => {
    if (!!ageOutOfOrder || !!negativeMinAge || !!negativeMaxAge) {
      setAgeValidationError(true);
    } else {
      setAgeValidationError(false);
    }
  }, [ageOutOfOrder, negativeMinAge, negativeMaxAge]);

  return (
    <AnalysisSection title={t('pathology-detail-analysis-patient-info')}>
      {/* Patient Gender */}
      <InputControl label={t('pathology-detail-analysis-patient-gender')}>
        {!readonly ? (
          <Dropdown
            border
            whiteText={false}
            placeholder={t('pathology-detail-analysis-patient-gender')}
            options={[
              { label: t('gender-male'), value: 'M' },
              { label: t('gender-female'), value: 'F' },
              { label: t('not-disclosed'), value: 'ND' },
            ]}
            value={analysisInfo?.gender}
            onChange={(val) =>
              setAnalysisInfo({
                ...analysisInfo,
                gender: val.value,
              })
            }
          />
        ) : (
          <Readonly
            text={
              analysisInfo?.gender ? t(`gender-${analysisInfo?.gender}`) : '-'
            }
          />
        )}
      </InputControl>

      {/* Patient Age */}
      <InputControl label={t('pathology-detail-analysis-patient-min-age')}>
        {!readonly ? (
          <Input
            type="number"
            placeholder={t('pathology-detail-analysis-patient-min-age')}
            value={analysisInfo?.minAge || ''}
            onChange={(e) =>
              setAnalysisInfo({
                ...analysisInfo,
                minAge: +e.target.value,
              })
            }
          />
        ) : (
          <Readonly text={analysisInfo?.minAge || '-'} />
        )}
        {!!negativeMinAge && (
          <span className={classes.validationError}>
            {t('pathology-detail-analysis-patient-min-age-negative')}
          </span>
        )}
        {!!ageOutOfOrder && !negativeMinAge && (
          <span className={classes.validationError}>
            {t('pathology-detail-analysis-patient-greater-min-age')}
          </span>
        )}
      </InputControl>

      {/* Patient Maximum Age */}
      <InputControl label={t('pathology-detail-analysis-patient-max-age')}>
        {!readonly ? (
          <Input
            type="number"
            placeholder={t('pathology-detail-analysis-patient-max-age')}
            value={analysisInfo?.maxAge || ''}
            onChange={(e) =>
              setAnalysisInfo({
                ...analysisInfo,
                maxAge: +e.target.value,
              })
            }
          />
        ) : (
          <Readonly text={analysisInfo?.maxAge || '-'} />
        )}
        {!!negativeMaxAge && (
          <span className={classes.validationError}>
            {t('pathology-detail-analysis-patient-min-age-negative')}
          </span>
        )}
      </InputControl>

      {/* Patient Is Pregnant? */}
      <InputControl
        label={t('pathology-detail-analysis-patient-pregnancy')}
        extraStyles={classes.CheckboxWrapper}
      >
        {!readonly ? (
          <Checkbox
            disabled={analysisInfo?.gender === 'M'}
            checked={analysisInfo?.isForPregnants}
            onChange={(e) =>
              setAnalysisInfo({
                ...analysisInfo,
                isForPregnants: e.target.checked,
              })
            }
          />
        ) : (
          <Readonly text={analysisInfo?.isForPregnants ? t('yes') : t('no')} />
        )}
      </InputControl>
    </AnalysisSection>
  );
};

export default AnalysisPatient;
