import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AnalysisSection from '../../AnalysisSection/AnalysisSection';
import InputControl from '../../../../../../../molecules/InputControl/InputControl';
import Dropdown from '../../../../../../../atoms/Dropdown/Dropdown';
import Input from '../../../../../../../atoms/Input/Input';
import Readonly from '../../../../../../../atoms/Readonly/Readonly';
import classes from './IntervalReference.module.css';

const IntervalReference = (props) => {
  const {
    readonly,
    analysisInfo,
    setAnalysisInfo,
    setRefValuesValidationError,
  } = props;

  const { t } = useTranslation();
  const [refValuesOutOfOrder, setRefValuesOutOfOrder] = useState(null);

  useEffect(() => {
    setRefValuesOutOfOrder(
      analysisInfo?.minRefValue &&
        analysisInfo?.maxRefValue &&
        +analysisInfo.minRefValue > +analysisInfo.maxRefValue,
    );
  }, [analysisInfo?.minRefValue, analysisInfo?.maxRefValue]);

  useEffect(() => {
    setRefValuesValidationError(refValuesOutOfOrder);
  }, [refValuesOutOfOrder]);

  return (
    <AnalysisSection title={t('pathology-detail-analysis-ref-values')}>
      {/* Minimum reference value input */}
      <InputControl label={t('pathology-detail-analysis-ref-min')}>
        {!readonly ? (
          <Input
            type="number"
            placeholder={t('pathology-detail-analysis-ref-min')}
            value={analysisInfo?.minRefValue || ''}
            onChange={(e) =>
              setAnalysisInfo({
                ...analysisInfo,
                minRefValue: e.target.value,
              })
            }
          />
        ) : (
          <Readonly text={analysisInfo?.minRefValue} />
        )}
        {!!refValuesOutOfOrder && (
          <span className={classes.validationError}>
            {t('pathology-detail-analysis-ref-min-greater')}
          </span>
        )}
      </InputControl>

      {/* Maximum reference value input */}
      <InputControl label={t('pathology-detail-analysis-ref-max')}>
        {!readonly ? (
          <Input
            type="number"
            placeholder={t('pathology-detail-analysis-ref-max')}
            value={analysisInfo?.maxRefValue || ''}
            onChange={(e) =>
              setAnalysisInfo({
                ...analysisInfo,
                maxRefValue: e.target.value,
              })
            }
          />
        ) : (
          <Readonly text={analysisInfo?.maxRefValue} />
        )}
      </InputControl>

      {/* Reference values' unit input */}
      <InputControl label={t('pathology-detail-analysis-ref-unit')}>
        {!readonly ? (
          <Dropdown
            border
            whiteText={false}
            placeholder={t('pathology-detail-analysis-ref-unit')}
            options={[
              { label: 'ml/dL', value: 'ml/dL' },
              { label: 'cl/dL', value: 'cl/dL' },
            ]}
            value={analysisInfo?.unit}
            onChange={(val) =>
              setAnalysisInfo({
                ...analysisInfo,
                unit: val.value,
              })
            }
          />
        ) : (
          <Readonly text={analysisInfo?.unit} />
        )}
      </InputControl>
    </AnalysisSection>
  );
};

export default IntervalReference;
