/**
 * Returns the filters object from the table
 * as an object prepared to be used in the request for the API.
 *
 * @param {*} filters
 * @returns {*}
 */
export const extractFiltersAsParameters = (filters) => {
  const filtersObject = {};

  filters.forEach((filter) => {
    filtersObject[filter.property] = filter.value;
  });

  return filtersObject;
};

/**
 * Returns the sort object from the table
 * as an object prepared to be used in the request for the API.
 *
 * @param {*} sort
 * @returns {*}
 */
export const extractSortAsParameters = (sort) =>
  sort
    ? {
        sort_by: sort.property,
        sort_direction: sort.direction,
      }
    : {};
