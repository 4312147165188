import React, { useCallback, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import Input from '../../../atoms/Input/Input';
import DatePicker from '../../../atoms/DatePicker/DatePicker';
import Dropdown from '../../../atoms/Dropdown/Dropdown';
import SortAsc from '../../../../assets/images/caret-up.svg';
import SortAscActive from '../../../../assets/images/caret-up-active.svg';
import SortDsc from '../../../../assets/images/caret-down.svg';
import SortDscActive from '../../../../assets/images/caret-down-active.svg';
import Search from '../../../../assets/images/search-white-icon.svg';
import Times from '../../../../assets/images/times-icon.svg';
import classes from './ColumnHeader.module.css';

const ColumnHeader = (props) => {
  const {
    column,
    sortable,
    sort,
    setSort,
    filterable,
    filter,
    onChangeFilter,
  } = props;

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const onChangeSort = (direction, property) => {
    if (sort?.property === property && sort?.direction === direction) {
      setSort(null);
      return;
    }

    setSort({
      direction,
      property,
    });
  };

  const handleFilter = useCallback(
    (filter) => {
      setIsFilterOpen(!isFilterOpen);
      if (isFilterOpen) onChangeFilter(filter, undefined);
    },
    [isFilterOpen],
  );

  return (
    <td className={classes['Table__header-cell']}>
      <div className={classes['Table__header-cell-wrapper']}>
        {!isFilterOpen && <span>{column.label}</span>}
        {isFilterOpen && column.filter && (
          <div className={classes.FilterDisplay}>
            <span className={classes.FilterLabel}>{column.label}</span>
            <div style={{ width: '100%' }}>
              {column.filterType === 'debounce' && (
                <DebounceInput
                  type="text"
                  small
                  value={filter?.value}
                  element={Input}
                  debounceTimeout={300}
                  onChange={column.filter}
                />
              )}
              {column.filterType === 'text' && (
                <Input
                  type="text"
                  small
                  onChange={column.filter}
                  value={filter?.value}
                />
              )}
              {column.filterType === 'date' && (
                <DatePicker
                  value={filter?.value}
                  onChange={column.filter}
                  small
                  selectsRange
                />
              )}
              {column.filterType === 'dropdown' &&
                column.filterDropdownOptions && (
                  <Dropdown
                    blank={false}
                    whiteText={false}
                    small
                    options={column.filterDropdownOptions}
                    onChange={column.filter}
                  />
                )}
            </div>
          </div>
        )}
        {isFilterOpen && !column.filter && <div />}

        <div className={classes['Table__header-actions-wrapper']}>
          {sortable && (
            <div className={classes['Table__header-sort-wrapper']}>
              <button
                type="button"
                onClick={() => onChangeSort('ASC', column.property)}
              >
                <img
                  src={
                    sort?.direction === 'ASC' &&
                    sort?.property === column.property
                      ? SortAscActive
                      : SortAsc
                  }
                  alt="sort-asc"
                />
              </button>
              <button
                type="button"
                onClick={() => onChangeSort('DESC', column.property)}
              >
                <img
                  src={
                    sort?.direction === 'DESC' &&
                    sort?.property === column.property
                      ? SortDscActive
                      : SortDsc
                  }
                  alt="sort-dsc"
                />
              </button>
            </div>
          )}
          {filterable && column.filter && (
            <div className={classes['Table__header-filter-wrapper']}>
              <button
                type="button"
                onClick={() => handleFilter(column?.property)}
              >
                <img
                  className={isFilterOpen ? classes.Times : undefined}
                  src={isFilterOpen ? Times : Search}
                  alt="filter"
                />
              </button>
            </div>
          )}
        </div>
      </div>
    </td>
  );
};

export default ColumnHeader;
