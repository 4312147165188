import React from 'react';

import classes from './CaptionItem.module.css';

const CaptionItem = ({ item, breakLine }) => (
  <div className={classes.CaptionItem}>
    <div className={classes.Color} style={{ backgroundColor: item.color }} />
    <p className={`${classes.Text} ${classes.BreakLine}`}>
      <span className={classes.Title}>{item.title}</span>
      {breakLine && (
        <span>{item.subtitle && item.subtitle}</span>
      )}
      {!breakLine && (
        <span>{item.subtitle && ` - ${item.subtitle}`}</span>
      )}
    </p>
  </div>
);

export default CaptionItem;