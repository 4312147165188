// AUTH
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export const AUTH_CLEAR_ERROR = 'AUTH_CLEAR_ERROR';

/**
 * Clinical Episodes
 */
export const GET_CLINICAL_EPISODES_START = 'GET_CLINICAL_EPISODES_START';
export const GET_CLINICAL_EPISODES_SUCCESS = 'GET_CLINICAL_EPISODES_SUCCESS';
export const GET_CLINICAL_EPISODES_ERROR = 'GET_CLINICAL_EPISODES_ERROR';
export const FETCH_EPISODE_PARAMETERS_START = 'FETCH_EPISODE_PARAMETERS_START';
export const FETCH_EPISODE_PARAMETERS_SUCCESS =
  'FETCH_EPISODE_PARAMETERS_SUCCESS';
export const FETCH_EPISODE_PARAMETERS_ERROR = 'FETCH_EPISODE_PARAMETERS_ERROR';

/**
 * Pathologies
 */
export const GET_PATHOLOGIES_START = 'GET_PATHOLOGIES_START';
export const GET_PATHOLOGIES_SUCCESS = 'GET_PATHOLOGIES_SUCCESS';
export const GET_PATHOLOGIES_ERROR = 'GET_PATHOLOGIES_ERROR';

export const GET_PATHOLOGY_DETAIL_START = 'GET_PATHOLOGY_DETAIL_START';
export const GET_PATHOLOGY_DETAIL_SUCCESS = 'GET_PATHOLOGY_DETAIL_SUCCESS';
export const GET_PATHOLOGY_DETAIL_ERROR = 'GET_PATHOLOGY_DETAIL_ERROR';

export const POST_PATHOLOGY_DETAIL_START = 'POST_PATHOLOGY_DETAIL_START';
export const POST_PATHOLOGY_DETAIL_SUCCESS = 'POST_PATHOLOGY_DETAIL_SUCCESS';
export const POST_PATHOLOGY_DETAIL_ERROR = 'POST_PATHOLOGY_DETAIL_ERROR';

export const POST_PATHOLOGY_VERSION_DETAIL_START =
  'POST_PATHOLOGY_VERSION_DETAIL_START';
export const POST_PATHOLOGY_VERSION_DETAIL_SUCCESS =
  'POST_PATHOLOGY_VERSION_DETAIL_SUCCESS';
export const POST_PATHOLOGY_VERSION_DETAIL_ERROR =
  'POST_PATHOLOGY_VERSION_DETAIL_ERROR';

export const PATCH_PATHOLOGY_DETAIL_START = 'PATCH_PATHOLOGY_DETAIL_START';
export const PATCH_PATHOLOGY_DETAIL_SUCCESS = 'PATCH_PATHOLOGY_DETAIL_SUCCESS';
export const PATCH_PATHOLOGY_DETAIL_ERROR = 'PATCH_PATHOLOGY_DETAIL_ERROR';

export const PATCH_PATHOLOGY_STATUS_START = 'PATCH_PATHOLOGY_STATUS_START';
export const PATCH_PATHOLOGY_STATUS_SUCCESS = 'PATCH_PATHOLOGY_STATUS_SUCCESS';
export const PATCH_PATHOLOGY_STATUS_ERROR = 'PATCH_PATHOLOGY_STATUS_ERROR';

export const PATCH_PATHOLOGY_VERSION_START = 'PATCH_PATHOLOGY_VERSION_START';
export const PATCH_PATHOLOGY_VERSION_SUCCESS =
  'PATCH_PATHOLOGY_VERSION_SUCCESS';
export const PATCH_PATHOLOGY_VERSION_ERROR = 'PATCH_PATHOLOGY_VERSION_ERROR';

export const DELETE_PATHOLOGY_DETAIL_START = 'DELETE_PATHOLOGY_DETAIL_START';
export const DELETE_PATHOLOGY_DETAIL_SUCCESS =
  'DELETE_PATHOLOGY_DETAIL_SUCCESS';
export const DELETE_PATHOLOGY_DETAIL_ERROR = 'DELETE_PATHOLOGY_DETAIL_ERROR';
export const RESET_PATHOLOGY_SUCCESS = 'RESET_PATHOLOGY_SUCCESS';

/**
 * Patients
 */
export const GET_PATIENT_DETAIL_START = 'GET_PATIENT_DETAIL_START';
export const GET_PATIENT_DETAIL_SUCCESS = 'GET_PATIENT_DETAIL_SUCCESS';
export const GET_PATIENT_DETAIL_ERROR = 'GET_PATIENT_DETAIL_ERROR';

export const UPDATE_PATIENT_EPISODE_ON_LIST_START =
  'UPDATE_PATIENT_EPISODE_ON_LIST_START';
export const UPDATE_PATIENT_EPISODE_ON_LIST_SUCCESS =
  'UPDATE_PATIENT_EPISODE_ON_LIST_SUCCESS';
export const UPDATE_PATIENT_EPISODE_ON_LIST_ERROR =
  'UPDATE_PATIENT_EPISODE_ON_LIST_ERROR';
export const RESET_PATIENT_DETAIL_STATE = 'RESET_PATIENT_DETAIL_STATE';

/**
 * SUPPORT
 */
export const POST_SUPPORT_START = 'POST_SUPPORT_START';
export const POST_SUPPORT_SUCCESS = 'POST_SUPPORT_SUCCESS';
export const POST_SUPPORT_ERROR = 'POST_SUPPORT_ERROR';
