import React from 'react';
import classes from './SeverityLabel.module.css';

const SeverityLabel = ({ severity }) => {
  const getColor = (severity) => {
    let color = 'var(--color-gray)';

    switch (severity) {
      case 'high':
        color = 'var(--color-danger)';
        break;
      case 'medium':
        color = 'var(--color-warning)';
        break;
      case 'low':
        color = 'var(--color-warning-low)';
        break;
      default:
        color = 'var(--color-gray)';
        break;
    }

    return color;
  }

  const getLabel = (severity) => {
    let color = 'Undefined';

    switch (severity) {
      case 'high':
        color = 'High';
        break;
      case 'medium':
        color = 'Medium';
        break;
      case 'low':
        color = 'Low';
        break;
      default:
        color = 'Undefined';
        break;
    }

    return color;
  }

  return (
    <div className={classes.LabelContainer}>
      <div className={classes.SeverityLabel}>
        <div className={classes.Circle} style={{ backgroundColor: getColor(severity) }} />
        <span>{getLabel(severity)}</span>
      </div>
    </div>
  )
};

export default SeverityLabel;