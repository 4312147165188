import i18next from '../../i18n';
import api, { endpoints } from '../../services/api';
import {
  FETCH_EPISODE_PARAMETERS_ERROR,
  FETCH_EPISODE_PARAMETERS_START,
  FETCH_EPISODE_PARAMETERS_SUCCESS,
  GET_PATIENT_DETAIL_ERROR,
  GET_PATIENT_DETAIL_START,
  GET_PATIENT_DETAIL_SUCCESS,
  UPDATE_PATIENT_EPISODE_ON_LIST_ERROR,
  UPDATE_PATIENT_EPISODE_ON_LIST_START,
  UPDATE_PATIENT_EPISODE_ON_LIST_SUCCESS,
  RESET_PATIENT_DETAIL_STATE,
} from './types';
import { getAuthToken } from '../../services/auth/getToken';

export const getPatientDetail =
  (patientId, startDate, endDate) => async (dispatch) => {
    dispatch({ type: GET_PATIENT_DETAIL_START });

    try {
      const tokens = await getAuthToken();

      const config = {
        headers: {
          LABAI_AUTH: tokens.bearerToken,
          Azure_Access_Token: tokens.accessToken,
        },
      };

      const response = await api.get(
        endpoints.PATIENT_GET.replace(':id', patientId),
        {
          ...config,
          ...((startDate || endDate) && { params: { startDate, endDate } }),
        },
      );

      const retrievedEpisodes = response.data.patientClinicalEpisodes;

      const episodes = retrievedEpisodes.map((episode) => {
        let episodeParameters = [];
        episode.analyses.forEach((analysis) => {
          episodeParameters = analysis.parameters.concat(episodeParameters);
        });
        return { ...episode, episodeParameters };
      });

      const patient = {
        ...response.data,
        patientClinicalEpisodes: episodes,
      };

      dispatch({
        type: GET_PATIENT_DETAIL_SUCCESS,
        patientSelected: patient,
      });
    } catch (error) {
      dispatch({
        type: GET_PATIENT_DETAIL_ERROR,
        error: i18next.t('patient-analysis-fetch-error'),
      });
    }
  };

export const updatePatientEpisodeOnEpisodeList =
  (patientId, episodeAppoloId) => async (dispatch) => {
    dispatch({ type: UPDATE_PATIENT_EPISODE_ON_LIST_START });

    try {
      const tokens = await getAuthToken();

      const config = {
        headers: {
          LABAI_AUTH: tokens.bearerToken,
          Azure_Access_Token: tokens.accessToken,
        },
      };

      const response = await api.get(
        endpoints.CLINICAL_EPISODES_GET_PATIENT.replace(':id', patientId),
        {
          ...config,
          params: { episodeId: episodeAppoloId },
        },
      );

      if (response.data.length < 1) {
        dispatch({
          type: UPDATE_PATIENT_EPISODE_ON_LIST_ERROR,
          error: i18next.t(
            'patient-analysis-alert-update-episode-on-list-error',
          ),
        });
      }

      const retrievedEpisode = response.data[0];
      let episodeParameters = [];
      retrievedEpisode.analyses.forEach((analysis) => {
        episodeParameters = analysis.parameters.concat(episodeParameters);
      });
      const episode = { ...retrievedEpisode, episodeParameters };

      dispatch({
        type: UPDATE_PATIENT_EPISODE_ON_LIST_SUCCESS,
        updatedEpisode: episode,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_PATIENT_EPISODE_ON_LIST_ERROR,
        error: i18next.t('patient-analysis-alert-update-episode-on-list-error'),
      });
    }
  };

export const fetchEpisodeParameters = (episodeId) => async (dispatch) => {
  dispatch({ type: FETCH_EPISODE_PARAMETERS_START });

  try {
    const tokens = await getAuthToken();

    const config = {
      headers: {
        LABAI_AUTH: tokens.bearerToken,
        Azure_Access_Token: tokens.accessToken,
      },
    };

    const response = await api.get(
      endpoints.CLINICAL_EPISODES_PARAMETERS.replace(':id', episodeId),
      {
        ...config,
      },
    );

    if (response.data.length < 1) {
      dispatch({
        type: FETCH_EPISODE_PARAMETERS_ERROR,
        error: `${i18next.t(
          'patient-analysis-fetch-parameters-of-error',
        )} ${episodeId}`,
      });
    }

    const retrievedParameters = response.data;

    dispatch({
      type: FETCH_EPISODE_PARAMETERS_SUCCESS,
      episodeParameters: retrievedParameters,
    });
  } catch (error) {
    dispatch({
      type: FETCH_EPISODE_PARAMETERS_ERROR,
      error: `${i18next.t(
        'patient-analysis-fetch-parameters-of-error',
      )} ${episodeId}`,
    });
  }
};

export const resetPatientDetailState = () => (dispatch) => {
  dispatch({ type: RESET_PATIENT_DETAIL_STATE });
};
