import React from 'react';
import classes from './AnalysisSection.module.css';

const AnalysisSection = ({ title, children }) => (
  <div className={classes.PanelSection}>
    <p className={classes.PanelSectionTitle}>{title}</p>

    {children}
  </div>
);

export default AnalysisSection;
