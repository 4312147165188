import React from 'react';
import classes from './Container.module.css';

const Container = ({ fill, fillWithTitleHeader, children, ...args }) => (
  <div
    className={`${classes.Container} ${fill && classes.Fill} ${
      fillWithTitleHeader && classes.FillWithTitleHeader
    }`.trim()}
    {...args}
  >
    <div className={classes.Content}>{children}</div>
  </div>
);

export default Container;
