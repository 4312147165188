import i18next from '../../i18n';
import api, { endpoints } from '../../services/api';
import { extractFiltersAsParameters, extractSortAsParameters } from '../utils';
import {
  GET_CLINICAL_EPISODES_START,
  GET_CLINICAL_EPISODES_ERROR,
  GET_CLINICAL_EPISODES_SUCCESS,
} from './types';
import { getAuthToken } from '../../services/auth/getToken';

export const getClinicalEpisodes =
  (
    size = 20,
    page = 1,
    sort = { direction: 'DESC', property: 'date' },
    filter = [],
  ) =>
  async (dispatch) => {
    dispatch({ type: GET_CLINICAL_EPISODES_START });
    try {
      const tokens = await getAuthToken();

      const config = {
        headers: {
          LABAI_AUTH: tokens.bearerToken,
          Azure_Access_Token: tokens.accessToken,
        },
      };

      const response = await api.get(endpoints.CLINICAL_EPISODES_GET, {
        ...config,
        params: {
          size,
          page,
          ...extractSortAsParameters(sort),
          ...extractFiltersAsParameters(filter),
        },
      });

      dispatch({
        type: GET_CLINICAL_EPISODES_SUCCESS,
        clinicalEpisodes: response?.data?.data,
        page,
        isLastPageLoaded: response?.data?.isLastPage,
      });
    } catch (error) {
      dispatch({
        type: GET_CLINICAL_EPISODES_ERROR,
        error: i18next.t('dashboard-episodes-fetch-error'),
        errorObject: error,
      });
    }
  };

export const fetchDiagnosisByEpisode = (episodeId) => async () => {
  try {
    const tokens = await getAuthToken();

    const config = {
      headers: {
        LABAI_AUTH: tokens.bearerToken,
        Azure_Access_Token: tokens.accessToken,
      },
    };

    const response = api.get(
      endpoints.CLINICAL_EPISODES_DIAGNOSES_GET.replace(':id', episodeId),
      config,
    );

    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
