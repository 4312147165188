/**
 * Based on https://reactdatepicker.com/
 */

import React, { useState, forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import PropTypes from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';
import classes from './AdornedDatePicker.module.css';

const DatePickerInput = forwardRef(
  (
    { value, onClick, disabled, placeholder, leftAdornment, rightAdornment, error },
    ref,
  ) => (
    <>
      <div
        className={`${classes.AdornedDatePicker} ${
          disabled && classes.AdornedDatePicker__disabled
        }`}
      >
        {leftAdornment && (
          <div className={classes.LeftAdornment}>
            <img src={leftAdornment} alt="lft-adornment" />
          </div>
        )}
        <div className={`${classes.Input} ${error && classes.Input__error}`}>
          <input
            onClick={onClick}
            value={value}
            ref={ref}
            placeholder={placeholder}
            readOnly
            disabled={disabled}
          />
        </div>
        {rightAdornment && (
          <div className={classes.RightAdornment}>{rightAdornment}</div>
        )}
      </div>
      {error && <p className={classes.AdornedDatePicker__error}>{error}</p>}
    </>
  ),
);

const AdornedDatePicker = (props) => {
  const {
    id,
    name,
    placeholder,
    value,
    format = 'dd/MM/yyyy HH:mm:ss',
    disabled = false,
    leftAdornment,
    rightAdornment,
    error,
    onChange,
  } = props;

  const [selected, setSelected] = useState(value);
  const update = (selected) => {
    if (onChange) {
      onChange(selected);
    }

    setSelected(selected);
  };

  return (
    <ReactDatePicker
      dateFormat={format}
      customInput={
        <DatePickerInput
          disabled={disabled}
          placeholder={placeholder}
          leftAdornment={leftAdornment}
          rightAdornment={rightAdornment}
          error={error}
        />
      }
      showMonthDropdown
      showYearDropdown
      showTimeInput
      timeFormat="HH:mm:ss"
      selected={selected}
      disabled={disabled}
      name={name}
      id={id}
      placeholderText={placeholder}
      onChange={(selected) => {
        update(selected);
      }}
    />
  );
};

AdornedDatePicker.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.instanceOf(Date),
  format: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  leftAdornment: PropTypes.string,
  rightAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  error: PropTypes.string
};

AdornedDatePicker.defaultProps = {
  id: null,
  name: null,
  placeholder: null,
  value: null,
  format: "dd/MM/yyyy HH:mm:ss",
  disabled: false,
  onChange: null,
  leftAdornment: null,
  rightAdornment: null,
  error: null
};


export default AdornedDatePicker;
