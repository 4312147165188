import React from 'react';
import PropTypes from 'prop-types';

import classes from './AdornedInput.module.css';

const AdornedInput = (props) => {
  const {
    type = 'text',
    leftAdornment,
    rightAdornment,
    error,
    ...args
  } = props;

  return (
    <>
      <div className={classes.AdornedInput}>
        <div className={classes.LeftAdornment}>
          <img src={leftAdornment} alt="lft-adornment" />
        </div>
        <div className={`${classes.Input} ${error && classes.Input__error}`}>
          {type === 'search' && <div className={classes.search} />}
          <input type={type} {...args} />
        </div>
        {rightAdornment && (
          <div className={classes.RightAdornment}>{rightAdornment}</div>
        )}
      </div>
      {error && <p className={classes.AdornedInput__error}>{error}</p>}
    </>
  );
};

AdornedInput.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  leftAdornment: PropTypes.string,
  rightAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  error: PropTypes.string
};

AdornedInput.defaultProps = {
  type: 'text',
  id: null,
  name: null,
  placeholder: null,
  value: undefined,
  disabled: false,
  onChange: null,
  onKeyUp: null,
  leftAdornment: null,
  rightAdornment: null,
  error: null
};

export default AdornedInput;