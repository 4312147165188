/**
 * Based on https://reactdatepicker.com/
 */

import React, { useState, forwardRef, useCallback } from 'react';
import ReactDatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import classes from './DatePicker.module.css';

const DatePickerInput = forwardRef(
  ({ value, onClick, disabled, placeholder, small }, ref) => (
    <div
      className={`${classes.DatePicker} ${
        disabled && classes.DatePicker__disabled
      } ${small && classes.Small}`}
    >
      <input
        onClick={onClick}
        value={value}
        ref={ref}
        placeholder={placeholder}
        readOnly
        disabled={disabled}
      />
      {!small && <div className={classes.Icon} />}
    </div>
  ),
);

const DatePicker = (props) => {
  const {
    id,
    name,
    placeholder,
    value,
    format = 'dd/MM/yyyy',
    disabled = false,
    onChange,
    small = false,
    selectsRange,
  } = props;

  const [selected, setSelected] = useState(value);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const update = (selected) => {
    if (onChange) {
      onChange(selected);
    }

    setSelected(selected);
  };

  const onChangeRange = useCallback(
    (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      if (start && end)
        onChange({
          startDate: moment(start).utcOffset(0, true).startOf('day').format(),
          endDate: moment(end).utcOffset(0, true).endOf('day').format(),
        });
    },
    [onChange, setStartDate, setEndDate],
  );

  const inputValue = `${moment(`${startDate}`).format('MM/DD/YYYY')} - ${moment(
    `${endDate}`,
  ).format('MM/DD/YYYY')}`;

  return selectsRange ? (
    <ReactDatePicker
      selected={null}
      customInput={
        <DatePickerInput
          disabled={disabled}
          placeholder={placeholder}
          small={small}
        />
      }
      onChange={(selected) => {
        onChangeRange(selected);
      }}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      openToDate={new Date()}
      value={!endDate ? new Date() : inputValue}
    />
  ) : (
    <ReactDatePicker
      dateFormat={format}
      customInput={
        <DatePickerInput
          disabled={disabled}
          placeholder={placeholder}
          small={small}
        />
      }
      selected={selected}
      disabled={disabled}
      name={name}
      id={id}
      placeholderText={placeholder}
      onChange={(selected) => {
        update(selected);
      }}
    />
  );
};

DatePicker.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.instanceOf(Date),
  format: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  small: PropTypes.bool,
  selectsRange: PropTypes.bool,
};

DatePicker.defaultProps = {
  id: null,
  name: null,
  placeholder: null,
  value: null,
  format: 'dd/MM/yyyy',
  disabled: false,
  onChange: null,
  small: false,
  selectsRange: false,
};

export default DatePicker;
