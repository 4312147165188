import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../components/atoms/Container/Container';
import NavBar from '../../components/molecules/NavBar/NavBar';
import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import PathologyTable from '../../components/organisms/PathologyTable/PathologyTable';

const PathologyListScreen = ({ routes }) => {
  const { t } = useTranslation();

  return (
    <>
      <NavBar routes={routes} />
      <TitleHeader routes={routes} title={t('pathology-list-title')} />
      <Container style={{ paddingTop: '32px' }}>
        <PathologyTable
          routes={routes}
        />
      </Container>
    </>
  );
};

export default PathologyListScreen;
