import {
  GET_PATIENT_DETAIL_START,
  GET_PATIENT_DETAIL_ERROR,
  GET_PATIENT_DETAIL_SUCCESS,
  UPDATE_PATIENT_EPISODE_ON_LIST_START,
  UPDATE_PATIENT_EPISODE_ON_LIST_SUCCESS,
  UPDATE_PATIENT_EPISODE_ON_LIST_ERROR,
  RESET_PATIENT_DETAIL_STATE,
} from '../actions/types';

/**
 * @property patientSelected   Current patient that is being set to be
 * displayed in the patient analysis screen.
 * @property loading   Flag for when the request is being processed.
 * @property error   Will be set to any error that may be incoming.
 */
const initialState = {
  patientSelected: null,
  isFetching: false,
  fetchingAction: null,
  error: null,
  success: null,
};

function patientsReducer(state = initialState, action) {
  switch (action.type) {
    /**
     * Case for when the process of getting the details of a given
     * patient from the API starts.
     */
    case GET_PATIENT_DETAIL_START:
      return {
        ...state,
        isFetching: true,
        fetchingAction: action.type,
        error: null,
        success: null,
      };

    /**
     * Case for when the process of getting the details of a given
     * patient from the API is completed successfully.
     */
    case GET_PATIENT_DETAIL_SUCCESS:
      return {
        ...state,
        patientSelected: action.patientSelected,
        isFetching: false,
        fetchingAction: null,
        success: action.success,
      };

    /**
     * Case for when the process of getting the details of a given
     * patient from the API fails.
     */
    case GET_PATIENT_DETAIL_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchingAction: null,
        error: action.error,
      };

    /**
     * Case for when starting the process of fetching an updated version
     * of an episode for the patient's episode list.
     */
    case UPDATE_PATIENT_EPISODE_ON_LIST_START:
      return {
        ...state,
        isFetching: true,
        fetchingAction: action.type,
        error: null,
        success: null,
      };

    /**
     * Case for when fetching an updated patient's clinical episode for his/hers
     * episode list has been successful.
     */
    case UPDATE_PATIENT_EPISODE_ON_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fetchingAction: null,
        patientSelected: {
          ...state.patientSelected,
          patientClinicalEpisodes:
            state.patientSelected.patientClinicalEpisodes.map((episode) =>
              episode.appoloId === action.updatedEpisode.appoloId
                ? action.updatedEpisode
                : episode,
            ),
        },
        success: action.success,
      };

    /**
     * Updating a certain episode on the patient's episode list
     * has failed.
     */
    case UPDATE_PATIENT_EPISODE_ON_LIST_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchingAction: null,
        error: action.error,
      };
    case RESET_PATIENT_DETAIL_STATE:
      return initialState;
    default:
      return state;
  }
}

export default patientsReducer;
