import React from 'react';
import PropTypes from 'prop-types';

import classes from './IconButton.module.css';

const IconButton = (props) => {
  const {
    icon,
    alt,
    blank = false,
    disabled = false,
    iconHeight = 14,
    ...args
  } = props;

  const buttonClassName = `
    ${classes.IconButton}
    ${blank ? classes.IconButton__blank : ''}
    ${disabled ? classes.IconButton__disabled : ''}
  `.trim();

  return (
    <button className={buttonClassName} type="button" {...args} >
      <img src={icon} alt={alt} style={ { height: `${iconHeight}px` } } />
    </button>
  )
};

IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  blank: PropTypes.bool,
  disabled: PropTypes.bool,
  iconHeight: PropTypes.number,
  onClick: PropTypes.func
};

IconButton.defaultProps = {
  blank: false,
  disabled: false,
  iconHeight: 14,
  onClick: null
};

export default IconButton;