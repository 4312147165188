import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Button from '../../../atoms/Button/Button';
import Modal from '../../../molecules/Modal/Modal';
import TrashIcon from '../../../../assets/images/trash-icon.svg';
import classes from './EnableVersionModal.module.css';

const EnableVersionModal = (props) => {
  const { toggle, onClose, onConfirm, version } = props;

  const { t } = useTranslation();

  return (
    <Modal
      title={t('pathology-detail-version-modal-title')}
      toggle={toggle}
      onClose={onClose}
    >
      <div className={classes.ModalBody}>
        <div className={classes.ModalBodyWrapper}>
          <div className={classes.ModalIcon}>
            <img src={TrashIcon} alt="trash-icon" />
          </div>
          <div className={classes.ModalText}>
            <p>{t('pathology-detail-version-modal-text')}</p>
            <div className={classes.DangerText}>
              <p>{`${t('pathology-detail-version-modal-version')} ${
                version?.version
              }`}</p>
              <p>{`${t('pathology-detail-version-modal-last-update')}: ${moment(
                version?.updatedAt,
              ).format('DD/MM/YYYY')}`}</p>
              <p>{`${t('pathology-detail-version-modal-updated-by')}: ${
                version?.lastUpdateBy
              }`}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.ModalFooter}>
        <Button type="button" onClick={onConfirm}>
          {t('yes')}
        </Button>
        <Button type="button" blank onClick={onClose}>
          {t('no')}
        </Button>
      </div>
    </Modal>
  );
};

export default EnableVersionModal;
