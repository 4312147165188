import React from 'react';
import Router from './routes/routes';
/**
 * Includes all the necessary fonts for the application,
 * also setting the main font for the whole "body" selector.
 * It also loads all the style variables (colors, sizes, etc...).
 */
import './App.css';

/**
 * Main component for the application.
 */
function App() {
  return (
    /**
     * The entry point of the application will always be
     * the result of what the Router component will resolve to,
     * ie, which route matches the current URL.
     */
    <Router />
  );
}

export default App;
