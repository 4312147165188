import {
  GET_CLINICAL_EPISODES_START,
  GET_CLINICAL_EPISODES_SUCCESS,
  GET_CLINICAL_EPISODES_ERROR,
  FETCH_EPISODE_PARAMETERS_START,
  FETCH_EPISODE_PARAMETERS_SUCCESS,
  FETCH_EPISODE_PARAMETERS_ERROR,
} from '../actions/types';

/**
 * @property clinicalEpisodes   Stores the list of loaded clinical episodes.
 * @property page   Current page (or batch) of the loaded
 * clinical episodes list.
 * @property isLastPageLoaded   Flag to be set as true if the incoming
 * clinical episodes batch is the last one.
 * @property isFetching   Flag for when the request is being processed.
 * @property error   Will be set to any error that may be incoming.
 */
const initialState = {
  clinicalEpisodes: [],
  episodeParameters: [],
  page: 1,
  isLastPageLoaded: false,
  isFetching: false,
  isFetchingParameters: false,
  error: null,
};

function clinicalEpisodeReducer(state = initialState, action) {
  switch (action.type) {
    /**
     * Case for when the process of getting the list of
     * clinical episodes from the API starts.
     */
    case GET_CLINICAL_EPISODES_START:
      return {
        ...state,
        isFetching: true,
        error: null,
      };

    /**
     * Case for when the process of getting the list of
     * clinical episodes from the API is completed successfully.
     *
     * Either it loads the resulting episodes to the "clinicalEpisodes"
     * state property, or it concatenates to the ones already there, if
     * the "page" property is not "1".
     */
    case GET_CLINICAL_EPISODES_SUCCESS:
      return {
        ...state,
        clinicalEpisodes:
          action.page === 1
            ? action.clinicalEpisodes
            : state.clinicalEpisodes.concat(action.clinicalEpisodes),
        page: action.page === 1 ? 1 : action.page,
        isLastPageLoaded: action.isLastPageLoaded,
        isFetching: false,
      };

    /**
     * Case for when the process of getting the list of
     * clinical episodes from the API fails.
     */
    case GET_CLINICAL_EPISODES_ERROR:
      return {
        ...state,
        error: `${action.error}: ${action.errorObject
          .toString()
          .replace('Error: ', '')}`,
        isFetching: false,
      };
    case FETCH_EPISODE_PARAMETERS_START:
      return {
        ...state,
        isFetchingParameters: true,
        error: null,
      };
    case FETCH_EPISODE_PARAMETERS_SUCCESS:
      return {
        ...state,
        episodeParameters: action.episodeParameters,
        isFetchingParameters: false,
      };
    case FETCH_EPISODE_PARAMETERS_ERROR:
      return {
        ...state,
        error: `${action.error}: ${action?.errorObject
          .toString()
          .replace('Error: ', '')}`,
        isFetchingParameters: false,
      };
    default:
      return state;
  }
}

export default clinicalEpisodeReducer;
