import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from '../../atoms/Select/Select';
import Button from '../../atoms/Button/Button';
import Input from '../../atoms/Input/Input';
import Table from '../../molecules/Table/Table';
import classes from './AlertTable.module.css';
import SeverityLabel from './SeverityLabel/SeverityLabel';

const columns = [
  { label: 'Patient ID', property: 'id' },
  {
    label: 'Severity',
    property: 'severity',
    render: (value) => <SeverityLabel severity={value} />,
  },
  {
    label: 'Date',
    property: 'date',
    render: (value) => value.toLocaleDateString(),
  },
  {
    label: 'Actions',
    render: () => (
      <div className={classes.Actions}>
        <Button stretched>Validate</Button>
        <Button stretched>View Patient</Button>
      </div>
    ),
  },
];

const AlertTable = () => {
  const { t } = useTranslation();

  const showOptions = [
    { label: 'All', value: '*' },
    { label: 'High', value: 'high' },
    { label: 'Medium', value: 'medium' },
    { label: 'Low', value: 'low' },
  ];

  return (
    <div className={classes.AlertTable}>
      <div className={classes.Header}>
        <Select
          options={showOptions}
          placeholder={t('alert-table-show-filter')}
        />
        <Input type="search" placeholder={t('alert-table-search')} />
      </div>
      <div className={classes.Body}>
        <Table
          columns={columns}
          content={[
            {
              id: 'P1205',
              severity: 'high',
              date: new Date(),
            },
            {
              id: 'P1205',
              severity: 'medium',
              date: new Date(),
            },
            {
              id: 'P1205',
              severity: 'low',
              date: new Date(),
            },
          ]}
          captionsTitle="Alerts Risks"
          captions={[
            {
              color: 'var(--color-danger)',
              title: 'High',
            },
            {
              color: 'var(--color-warning)',
              title: 'Medium',
            },
            {
              color: 'var(--color-warning-low)',
              title: 'Low',
            },
          ]}
          captionsInfo="Based on threshold defined on settings per pathology."
        />
      </div>
      <div className={classes.Footer}>
        <Button blank>{t('button-load-more')}</Button>
      </div>
    </div>
  );
};

export default AlertTable;
