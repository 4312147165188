import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Color from 'color';
import classes from './ChartLegend.module.css';

const ChartLegend = ({
  parameters,
  parametersSelected,
  selectedReferences,
  setSelectedReferences,
}) => {
  const { t } = useTranslation();

  /**
   * Only the parameters that are selected in the module
   * selector will appear in the chart legend.
   */
  const [parametersToDisplay, setParametersToDisplay] = useState([]);

  useEffect(() => {
    const codes = parametersSelected
      .map((parameterSelected) => parameterSelected.codes)
      .flat();

    const toDisplay = parameters.filter((parameter) =>
      codes.includes(parameter.code),
    );

    setParametersToDisplay(toDisplay);
  }, [parameters, parametersSelected]);

  /**
   * Adds the parameter to list of parameters from which
   * to display its reference values, or takes it out of that same list,
   * in case it's already selected.
   */
  const toggleSelectedReference = (code) => {
    if (selectedReferences.includes(code)) {
      const result = selectedReferences.filter((ref) => ref !== code);
      setSelectedReferences(result);
      return;
    }

    const result = [...selectedReferences];
    result.push(code);
    setSelectedReferences(result);
  };

  return (
    <div className={classes.ChartLegend}>
      {parametersToDisplay &&
        parametersToDisplay.length > 0 &&
        parametersToDisplay.map((parameter) => (
          <div key={parameter.code} className={classes.ParameterItem}>
            {/* Name and color of the parameter */}
            <div className={classes.ParameterItemInfo}>
              <div
                className={classes.ParameterColorSquare}
                style={{
                  backgroundColor: new Color(
                    parametersSelected.find((paramSelected) =>
                      paramSelected.codes.includes(parameter.code),
                    )?.color,
                  ).lighten(0.33),
                }}
              >
                <div
                  className={classes.ParameterColorLine}
                  style={{
                    backgroundColor: new Color(
                      parametersSelected.find((paramSelected) =>
                        paramSelected.codes.includes(parameter.code),
                      )?.color,
                    ).darken(0.33),
                  }}
                />
              </div>
              <span>{parameter.label}</span>
            </div>

            {/* Checkbox to show or hide the parameter's reference values */}
            <div className={classes.ParameterItemAction}>
              <div>
                <input
                  type="checkbox"
                  onChange={() => toggleSelectedReference(parameter.code)}
                  checked={selectedReferences.includes(parameter.code)}
                />
              </div>
              <span>{t('patient-analysis-chart-reference-values')}</span>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ChartLegend;
