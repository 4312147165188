import React from 'react';
import Container from '../../components/atoms/Container/Container';
import NavBar from '../../components/molecules/NavBar/NavBar';
import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import AlertTable from '../../components/organisms/AlertTable/AlertTable';

const AlertsScreen = (props) => {
  const { routes } = props;

  return (
    <>
      <NavBar routes={routes} />
      <TitleHeader title="Alerts" routes={routes} />
      <Container>
        <AlertTable />
      </Container>
    </>
  );
};

export default AlertsScreen;
