import React from 'react';
import PropTypes from 'prop-types';

import classes from './RadioGroup.module.css';

const RadioGroup = (props) => {
  const { options, name, onChange, value } = props;

  return (
    <div className={classes.RadioGroup}>
      {options.map((option, i) => (
        <div
          key={`${option.value}__${i}`}
          onChange={onChange}
          className={classes.RadioItem}
        >
          <input
            type="radio"
            id={`${option.value}__${i}`}
            name={name}
            value={option.value}
            defaultChecked={option.value === value}
          />
          <label htmlFor={`${option.value}__${i}`}>{option.label}</label>
        </div>
      ))}
    </div>
  );
};

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ).isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

RadioGroup.defaultProps = {
  value: null,
  onChange: null,
};

export default RadioGroup;
