import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../atoms/Button/Button';
import Modal from '../../../molecules/Modal/Modal';
import CheckIcon from '../../../../assets/images/check-icon.svg';
import TimesIcon from '../../../../assets/images/times-icon.svg';
import classes from './TogglePathologyModal.module.css';

const TogglePathologyModal = (props) => {
  const { toggle, onClose, onConfirm, pathology } = props;

  const { t } = useTranslation();

  return (
    <Modal
      title={`${
        pathology?.isActive
          ? t('pathology-list-modal-title-disable')
          : t('pathology-list-modal-title-enable')
      }`}
      forceFill
      toggle={toggle}
      onClose={onClose}
    >
      <div className={classes.ModalBody}>
        <div className={classes.ModalBodyIcon}>
          <img
            src={pathology?.isActive ? TimesIcon : CheckIcon}
            alt="toggle-icon"
          />
        </div>
        <p>
          {pathology?.isActive
            ? t('pathology-list-modal-subtitle-disable')
            : t('pathology-list-modal-subtitle-enable')}
        </p>
      </div>
      <div className={classes.ModalFooter}>
        <Button type="button" onClick={onConfirm}>
          {t('yes')}
        </Button>
        <Button type="button" blank onClick={onClose}>
          {t('no')}
        </Button>
      </div>
    </Modal>
  );
};

export default TogglePathologyModal;
