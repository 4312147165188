import React from 'react';
import classes from './InputControl.module.css';

const InputControl = ({ label, children, extraStyles }) => (
  <div className={extraStyles || classes.InputControl}>
    <span>{label}</span>
    {children}
  </div>
);

export default InputControl;
