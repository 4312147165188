import { applyMiddleware, combineReducers, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import authReducer from './reducers/auth';
import clinicalEpisodesReducer from './reducers/clinicalEpisodes';
import pathologiesReducer from './reducers/pathologies';
import patientsReducer from './reducers/patients';
import supportReducer from './reducers/support';

export const rootReducer = combineReducers({
  auth: authReducer,
  clinicalEpisodes: clinicalEpisodesReducer,
  pathologies: pathologiesReducer,
  patients: patientsReducer,
  support: supportReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(ReduxThunk));
const persistor = persistStore(store);
export { store, persistor };
