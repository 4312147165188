import i18next from '../../../i18n';

/**
 * Captions to be presented on top of the table.
 */
export default [
  {
    color: 'var(--color-danger)',
    title: i18next.t('status-risk-detected'),
    subtitle: i18next.t('dashboard-caption-risk-detected'),
  },
  {
    color: 'var(--color-info)',
    title: i18next.t('status-automatically-validated'),
    subtitle: i18next.t('dashboard-caption-automatically-validated'),
  },
  {
    color: 'var(--color-success)',
    title: i18next.t('status-manually-validated'),
    subtitle: i18next.t('dashboard-caption-manually-validated'),
  },
  {
    color: 'var(--color-orange)',
    title: i18next.t('status-no-info'),
    subtitle: i18next.t('dashboard-caption-no-info'),
  },
  {
    color: 'var(--color-purple)',
    title: i18next.t('status-pediatric-sample'),
    // subtitle: i18next.t('dashboard-caption-pediatric-sample'),
  },
];
