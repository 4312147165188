import LoginScreen from '../screens/LoginScreen/LoginScreen';
import DashboardScreen from '../screens/DashboardScreen/DashboardScreen';
import AlertsScreen from '../screens/AlertsScreen/AlertsScreen';
import PathologyListScreen from '../screens/PathologyListScreen/PathologyListScreen';
import PathologyDetailScreen from '../screens/PathologyDetailScreen/PathologyDetailScreen';
import PatientAnalysisScreen from '../screens/PatientAnalysisScreen/PatientAnalysisScreen';
import PageNotFoundScreen from '../screens/PageNotFoundScreen/PageNotFoundScreen';
import SupportScreen from '../screens/SupportScreen/SupportScreen';
import { ROLES } from '../constants';

export const ROUTES = {
  /**
   * 404 page.
   */
  NOT_FOUND: {
    path: '*',
    element: PageNotFoundScreen,
    private: true,
  },
  /**
   * Login Screen.
   */
  LOGIN: {
    path: '/',
    element: LoginScreen,
    private: false,
  },
  SUPPORT: {
    path: '/support',
    element: SupportScreen,
    private: true,
  },
  /**
   * Dashboard main screen, which will display the
   * table it the most recent clinical episodes.
   */
  DASHBOARD: {
    path: '/dashboard',
    element: DashboardScreen,
    private: true,
    allowed: [ROLES.ADMIN, ROLES.DIRECTOR, ROLES.DOCTOR],
  },
  ALERTS: {
    path: '/alerts',
    element: AlertsScreen,
    private: true,
    allowed: [ROLES.ADMIN, ROLES.DIRECTOR, ROLES.DOCTOR],
  },
  /**
   * Screen which will display a table with the list
   * of the currently defined pathologies.
   */
  PATHOLOGY_LIST: {
    path: '/pathologies',
    element: PathologyListScreen,
    private: true,
    allowed: [ROLES.ADMIN, ROLES.DOCTOR],
  },
  /**
   * Screen with the form to create a new pathology.
   */
  PATHOLOGY_DETAIL: {
    path: '/pathologies/detail',
    element: PathologyDetailScreen,
    private: true,
    allowed: [ROLES.ADMIN, ROLES.DIRECTOR, ROLES.DOCTOR],
  },
  /**
   * Screen with the form for editing/detailing the
   * information of a given pathology (based on the id)
   * on the URL. It also will show its version history.
   */
  PATHOLOGY_DETAIL_EDIT: {
    path: '/pathologies/detail/:id',
    element: PathologyDetailScreen,
    private: true,
    allowed: [ROLES.ADMIN, ROLES.DIRECTOR, ROLES.DOCTOR],
  },
  /**
   * Shows a screen with three tabs, regarding the patient:
   * History, Clinical Episodes and Personal Data.
   */
  PATIENT_ANALYSIS: {
    path: '/patient/:id',
    element: PatientAnalysisScreen,
    private: true,
    allowed: [ROLES.ADMIN, ROLES.DIRECTOR, ROLES.DOCTOR],
  },
};
