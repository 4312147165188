/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { RingLoader, ClipLoader } from 'react-spinners';
import classes from './Table.module.css';
import Captions from './Captions/Captions';
import ColumnHeader from './ColumnHeader/ColumnHeader';
import Button from '../../atoms/Button/Button';

const Table = (props) => {
  const {
    columns,
    content,
    captions,
    captionListTop,
    captionsTextBreakLine,
    captionsTitle,
    captionsInfo,
    centered,
    sortable,
    sort,
    setSort,
    filterable,
    filter,
    onChangeFilter,
    loading,
    canRefresh,
    onRefresh,
  } = props;

  const { t } = useTranslation();

  const renderRowColumn = (row, column) => {
    const rowColumnProperty = column.property && row[column.property];

    return column.render
      ? column.render(rowColumnProperty, row)
      : rowColumnProperty;
  };

  return (
    <div>
      <div className={classes.Header}>
        {captions && captionListTop && (
          <Captions
            captions={captions}
            captionsTextBreakLine={captionsTextBreakLine}
            captionsTitle={captionsTitle}
            captionsInfo={captionsInfo}
          />
        )}

        {canRefresh && (
          <Button disabled={loading} onClick={onRefresh}>
            <ClipLoader
              cssOverride={{ marginRight: '8px' }}
              loading={loading}
              size={16}
              color="#ffffff"
            />
            {t('refresh')}
          </Button>
        )}
      </div>

      <table
        className={`${classes.Table} ${centered && classes.Centered} ${
          loading && classes.IsLoading
        }`}
      >
        <thead className={classes.Table__header}>
          <tr>
            {columns.map((column, i) => (
              <ColumnHeader
                key={i}
                column={column}
                sortable={sortable}
                sort={sort}
                setSort={setSort}
                filterable={filterable}
                filter={filter?.find((f) => f.property === column.property)}
                onChangeFilter={onChangeFilter}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {(!content || content.length < 1) && (
            <tr className={classes['Table__body-row']}>
              <td
                className={`${classes['Table__body-cell']} ${classes.Empty}`}
                colSpan={columns.length}
              >
                {t('table-no-data')}
              </td>
            </tr>
          )}
          {content.map((row, i) => (
            <tr className={classes['Table__body-row']} key={i}>
              {columns.map((column, j) => (
                <td className={classes['Table__body-cell']} key={j}>
                  {renderRowColumn(row, column)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {loading && (
        <div className={classes.Overlay}>
          <RingLoader
            color="var(--color-primary)"
            size={128}
            loading={loading}
          />
        </div>
      )}
      {captions && !captionListTop && (
        <Captions
          captions={captions}
          captionsTextBreakLine={captionsTextBreakLine}
          captionsTitle={captionsTitle}
          captionsInfo={captionsInfo}
        />
      )}
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      property: PropTypes.string,
      render: PropTypes.func,
    }),
  ).isRequired,
  centered: PropTypes.bool,
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
  captions: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
    }),
  ),
  captionListTop: PropTypes.bool,
  captionsTextBreakLine: PropTypes.bool,
};

Table.defaultProps = {
  captions: null,
  captionListTop: false,
  captionsTextBreakLine: false,
  centered: false,
};

export default Table;
