/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from './Backdrop/Backdrop';

import classes from './Modal.module.css';

const Modal = (props) => {
  const { title, toggle, forceFill = false, onClose, children } = props;

  return (
    <Backdrop toggle={toggle} onClick={onClose} forceFill={forceFill}>
      <div className={classes.Modal__wrapper}>
        <div
          className={classes.Modal}
          role="dialog"
          onClick={(e) => e.stopPropagation()}
        >
          <div className={classes.Modal__title}>
            <span className={classes.Title}>{title}</span>
            <button type="button" className={classes.Close} onClick={onClose}>
              &#10005;
            </button>
          </div>
          <div className={classes.Modal__body}>{children}</div>
        </div>
      </div>
    </Backdrop>
  );
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  toggle: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Modal;
