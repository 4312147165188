import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AnalysisSection from '../AnalysisSection/AnalysisSection';
import InputControl from '../../../../../../molecules/InputControl/InputControl';
import Input from '../../../../../../atoms/Input/Input';
import Readonly from '../../../../../../atoms/Readonly/Readonly';
import classes from './AnalysisRiskThreshold.module.css';

const AnalysisRiskThreshold = (props) => {
  const { readonly, analysisInfo, setAnalysisInfo, setRiskValidationError } =
    props;

  const { t } = useTranslation();
  const [negativeLowPct, setNegativeLowPct] = useState(null);
  const [negativeMedPct, setNegativeMedPct] = useState(null);
  const [negativeHighPct, setNegativeHighPct] = useState(null);
  const [pctOutOfOrder, setPctOutOfOrder] = useState(null);

  useEffect(() => {
    if (analysisInfo?.lowRiskPct && analysisInfo?.medRiskPct) {
      setPctOutOfOrder(analysisInfo?.lowRiskPct >= analysisInfo?.medRiskPct);
    } else if (analysisInfo?.medRiskPct && analysisInfo?.highRiskPct) {
      setPctOutOfOrder(analysisInfo?.medRiskPct >= analysisInfo?.highRiskPct);
    }

    setNegativeLowPct(analysisInfo?.lowRiskPct && +analysisInfo.lowRiskPct < 0);
    setNegativeMedPct(analysisInfo?.medRiskPct && +analysisInfo.medRiskPct < 0);
    setNegativeHighPct(
      analysisInfo?.highRiskPct && +analysisInfo.highRiskPct < 0,
    );
  }, [
    analysisInfo?.lowRiskPct,
    analysisInfo?.medRiskPct,
    analysisInfo?.highRiskPct,
  ]);

  useEffect(() => {
    if (
      !!negativeLowPct ||
      !!negativeMedPct ||
      !!negativeHighPct ||
      !!pctOutOfOrder
    ) {
      setRiskValidationError(true);
    } else {
      setRiskValidationError(false);
    }
  }, [negativeLowPct, negativeMedPct, negativeHighPct, pctOutOfOrder]);

  return (
    <AnalysisSection title={t('pathology-detail-analysis-risk-thresholds')}>
      {/* Low risk input */}
      <InputControl label={`${t('pathology-detail-analysis-risk-low')} (%)`}>
        {!readonly ? (
          <Input
            type="number"
            placeholder={`${t('pathology-detail-analysis-risk-low')} (%)`}
            value={analysisInfo?.lowRiskPct || ''}
            onChange={(e) =>
              setAnalysisInfo({
                ...analysisInfo,
                lowRiskPct: e.target.value,
              })
            }
          />
        ) : (
          <Readonly text={analysisInfo?.lowRiskPct || '-'} />
        )}
        {!!negativeLowPct && (
          <span className={classes.validationError}>
            {t('pathology-detail-analysis-risk-negative')}
          </span>
        )}
      </InputControl>

      {/* Medium risk input */}
      <InputControl label={`${t('pathology-detail-analysis-risk-medium')} (%)`}>
        {!readonly ? (
          <Input
            type="number"
            placeholder={`${t('pathology-detail-analysis-risk-medium')} (%)`}
            value={analysisInfo?.medRiskPct || ''}
            onChange={(e) =>
              setAnalysisInfo({
                ...analysisInfo,
                medRiskPct: e.target.value,
              })
            }
          />
        ) : (
          <Readonly text={analysisInfo?.medRiskPct || '-'} />
        )}
        {!!negativeMedPct && (
          <span className={classes.validationError}>
            {t('pathology-detail-analysis-risk-negative')}
          </span>
        )}
      </InputControl>

      {/* High risk input */}
      <InputControl label={`${t('pathology-detail-analysis-risk-high')} (%)`}>
        {!readonly ? (
          <Input
            type="number"
            placeholder={`${t('pathology-detail-analysis-risk-high')} (%)`}
            value={analysisInfo?.highRiskPct || ''}
            onChange={(e) =>
              setAnalysisInfo({
                ...analysisInfo,
                highRiskPct: e.target.value,
              })
            }
          />
        ) : (
          <Readonly text={analysisInfo?.highRiskPct || '-'} />
        )}
        {!!negativeHighPct && (
          <span className={classes.validationError}>
            {t('pathology-detail-analysis-risk-negative')}
          </span>
        )}
      </InputControl>
      {!!pctOutOfOrder && (
        <span className={classes.validationErrorPct}>
          {t('pathology-detail-analysis-risk-order')}
        </span>
      )}
    </AnalysisSection>
  );
};

export default AnalysisRiskThreshold;
