import common from './common/common.json';
import login from './login/login.json';
import dashboard from './dashboard/dashboard.json';
import pathologyList from './pathology-list/pathology-list.json';
import pathologyDetail from './pathology-detail/pathology-detail.json';
import patientAnalysis from './patient-analysis/patient-analysis.json';
import pageNotFound from './page-not-found/page-not-found.json';
import support from './support/support.json';

export default {
  ...common,
  ...login,
  ...dashboard,
  ...pathologyList,
  ...pathologyDetail,
  ...patientAnalysis,
  ...pageNotFound,
  ...support,
};
