import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { useParams } from 'react-router-dom';
import NavBar from '../../components/molecules/NavBar/NavBar';
import TitleHeader from '../../components/molecules/TitleHeader/TitleHeader';
import Container from '../../components/atoms/Container/Container';
import PatientAnalysis from '../../components/organisms/PatientAnalysis/PatientAnalysis';
import {
  getPatientDetail,
  resetPatientDetailState,
} from '../../store/actions/patients';

const PatientAnalysisScreen = ({ routes }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const patient = useSelector((state) => state.patients.patientSelected);

  useEffect(() => {
    dispatch(resetPatientDetailState());
  }, []);

  /**
   * Fetches the patient details based on the ID in the
   * route parameter.
   */
  useEffect(() => {
    if (id) {
      dispatch(getPatientDetail(id, from, to));
    }
  }, [id, from, to]);

  return (
    <>
      <NavBar routes={routes} />
      <TitleHeader routes={routes} title={t('patient-analysis-title')} />
      <Container fillWithTitleHeader>
        <PatientAnalysis
          patient={patient}
          from={from}
          to={to}
          setFrom={setFrom}
          setTo={setTo}
        />
        <ToastContainer pauseOnHover />
      </Container>
    </>
  );
};

export default PatientAnalysisScreen;
