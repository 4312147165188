import React from 'react';
import PropTypes from 'prop-types';

import classes from './Input.module.css';

const Input = (props) => {
  const { type = 'text', small, ...args } = props;

  return (
    <div className={`${classes.Input} ${small && classes.Small}`}>
      {type === 'search' && <div className={classes.search} />}
      <input type={type} {...args} />
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  small: PropTypes.bool,
};

Input.defaultProps = {
  type: 'text',
  id: null,
  name: null,
  placeholder: null,
  value: '',
  disabled: false,
  onChange: null,
  onKeyUp: null,
  small: false,
};

export default Input;
